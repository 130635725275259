import { COLORS, PADDINGS, SOCIAL_LINKS, TYPOGRAPHY } from '@/constants';
import { UiLink } from '@/ui/components';
import { TelegramApp, WhatsappApp } from '@/ui/iconComponents';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import { SectionTitle } from '../SectionTitle';
import { mainData } from './data';

const ContactUs: FC = () => {
  return (
    <Root>
      <StyledContainer>
        <Info>
          <StyledSectionTitle>{mainData.title}</StyledSectionTitle>
          <Description>{mainData.description}</Description>
          <LinksWrapper>
            <SocialLink link={SOCIAL_LINKS.whatsapp}>
              <WhatsappApp fill="white" width={32} height={32} />
              <span>{mainData.whatsappText}</span>
            </SocialLink>
            <SocialLink link={SOCIAL_LINKS.telegram}>
              <TelegramApp fill="white" width={32} height={32} />
              <span>{mainData.telegramText}</span>
            </SocialLink>
          </LinksWrapper>
        </Info>
        <ImageWrapper>
          <StaticImage
            src="../../assets/images/contact-us-phone.png"
            alt="Whatsapp поддержка"
          />
        </ImageWrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.primaryLight};
`;

const StyledContainer = styled(Container)`
  padding-top: 41px;
  padding-bottom: 41px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 15.5%;
  @media (min-width: 1440px) {
    padding-right: 222px;
  }
  @media (max-width: 1350px) {
    padding-right: ${PADDINGS.commonInPixelsLarge};
  }
  @media (max-width: 1024px) {
    padding-top: 57px;
    padding-bottom: 0;
    padding-right: ${PADDINGS.commonInPixelsMedium};
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 768px) {
    padding-right: ${PADDINGS.commonInPixelsSmall};
  }
`;

const Info = styled.div`
  max-width: 460px;
  align-self: center;
  margin-right: 32px;
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 32px;
    align-self: flex-start;
    max-width: none;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  letter-spacing: 0.01em;
  margin-bottom: 24px;
  @media (max-width: 1024px) {
    letter-spacing: normal;
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title4Medium28};
    margin-bottom: 10px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black};
  margin-bottom: 56px;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const SocialLink = styled(UiLink)`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 12px;
  }
  :last-child {
    margin-right: 0;
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: -41px;
  max-width: 495px;
  @media (max-width: 1024px) {
    margin-bottom: 0;
    align-self: center;
  }
`;

export default ContactUs;
