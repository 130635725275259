import { COLORS, TYPOGRAPHY } from '@/constants';
import { Eye, EyeSlash } from '@/ui/iconComponents';
import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { Input } from '../Input';
import { InputWithLabel } from '../InputWithLabel';

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  errorMessage?: string;
  isSuccess?: boolean;
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, label, errorMessage, isSuccess, ...rest }, ref) => {
    const [isPasswordType, setIsPasswordType] = useState(true);

    return (
      <Root className={className}>
        {label ? (
          <InputWithLabel
            label={label}
            type={isPasswordType ? 'password' : 'text'}
            errorMessage={errorMessage}
            isSuccess={isSuccess}
            isWithoutIcons={true}
            ref={ref}
            {...rest}
          />
        ) : (
          <StyledInput
            type={isPasswordType ? 'password' : 'text'}
            ref={ref}
            {...rest}
          />
        )}

        <Button
          type="button"
          onClick={() => setIsPasswordType(!isPasswordType)}
        >
          {isPasswordType ? (
            <Eye color={COLORS.black2} />
          ) : (
            <EyeSlash color={COLORS.black2} />
          )}
        </Button>
      </Root>
    );
  }
);

const Root = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)``;

const Button = styled.button`
  position: absolute;
  bottom: 12px;
  right: 16px;

  svg {
    display: block;
  }
`;

export default PasswordInput;
