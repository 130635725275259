import {
  MastercardPayment,
  MirPayment,
  VisaPayment,
} from '@/ui/iconComponents';
import React, { FC } from 'react';
import styled from 'styled-components';

interface PaymentSystemsProps {
  className?: string;
}

const PaymentSystems: FC<PaymentSystemsProps> = ({ className }) => {
  const paymentsData = [
    {
      name: 'Visa',
      component: <VisaPayment />,
    },
    {
      name: 'Mir',
      component: <MirPayment />,
    },
    {
      name: 'Mastercard',
      component: <MastercardPayment />,
    },
  ];

  return (
    <Root className={className}>
      {paymentsData.map((item, index) => (
        <Item key={index} aria-label={item.name}>
          {item.component}
        </Item>
      ))}
    </Root>
  );
};

const Root = styled.ul`
  display: flex;
  align-items: center;
`;

const Item = styled.li`
  display: flex;
  margin-right: 8px;
  :last-child {
    margin-right: 0;
  }
`;

export default PaymentSystems;
