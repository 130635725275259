import { SOCIAL_LINKS } from '@/constants';
import { Whatsapp, WhatsappApp } from '@/ui/iconComponents';
import React, { FC } from 'react';
import styled from 'styled-components';
import { CustomLink } from '../CustomLink';

const WhatsAppLink: FC = () => {
  return (
    <Root>
      <StyledCustomLink to={SOCIAL_LINKS.whatsapp}>
        <StyledWhatsapp width={60} height={60} />
      </StyledCustomLink>
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  z-index: 3;
  right: 32px;
  bottom: 32px;
  @media (max-width: 768px) {
    right: 16px;
  }
`;

const StyledCustomLink = styled(CustomLink)``;

const StyledWhatsapp = styled(Whatsapp)`
  @media (max-width: 768px) {
    width: 45px;
    height: 45px;
  }
`;

export default WhatsAppLink;
