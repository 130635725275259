import { COLORS, TYPOGRAPHY } from '@/constants';
import { ServiceData } from '@/types';
import { UiLink } from '@/ui/components';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ServiceCardProps {
  data: ServiceData;
  className?: string;
}

const ServiceCard: FC<ServiceCardProps> = ({ data, className }) => {
  return (
    <Root className={className}>
      <ImageWrapper>{<data.Image />}</ImageWrapper>
      <Info>
        <TypeService>{data.type}</TypeService>
        <ServiceName>{data.name}</ServiceName>
        <Description>{data.description}</Description>
        <StyledUiLink link={data.link(data.name)} text={data.linkText} />
      </Info>
    </Root>
  );
};

const Root = styled.article`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding: 24px 24px 0;
  background-color: ${COLORS.primaryLight};
  @media (max-width: 700px) {
    padding: 16px 16px 0;
    svg {
      width: auto;
      max-height: 185px;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${COLORS.white};
  padding: 16px 24px 32px;
`;

const TypeService = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.grayDarker};
  margin-bottom: 8px;
`;

const ServiceName = styled.h3`
  ${TYPOGRAPHY.headline3SemiBold20};
  color: ${COLORS.black};
  margin-bottom: 8px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.black};
  margin-bottom: 24px;
`;

const StyledUiLink = styled(UiLink)`
  margin-top: auto;
  max-width: none;
`;

export default ServiceCard;
