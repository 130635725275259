import { COLORS } from '@/constants';
import * as React from 'react';
import { SVGProps } from 'react';

interface CloseSquareProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CloseSquare = ({ color = COLORS.black2, ...props }: CloseSquareProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m9.17 14.83 5.66-5.66M14.83 14.83 9.17 9.17M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseSquare;
