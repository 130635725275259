import { GatsbyLinkProps, Link } from 'gatsby';
import React, { FC } from 'react';

interface CustomLinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {
  className?: string;
  external?: boolean;
  disabled?: boolean;
  withoutLocale?: boolean;
}

const isExternalLink = (path: string) =>
  path?.startsWith(`http://`) ||
  path?.startsWith(`https://`) ||
  path?.startsWith(`//`);

const CustomLink: FC<CustomLinkProps> = ({
  className,
  external = false,
  disabled = false,
  children,
  withoutLocale = false,
  to,
  ...props
}) => {
  if (isExternalLink(to)) {
    return (
      <a className={className} href={to} target="_blank" {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link className={className} {...props} to={to}>
      {children}
    </Link>
  );
};

export default CustomLink;
