import { COLORS, TYPOGRAPHY } from '@/constants';
import currency from 'currency.js';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Input } from '../Input';
import { StyledSlider } from '../StyledSlider';

interface SliderWithInputProps {
  label: string;
  minBorder: number;
  maxBorder: number;
  minBorderText: string;
  maxBorderText: string;
  value: number;
  onChangeValue: (value: number) => void;
  step?: number;
  className?: string;
}

const SliderWithInput: FC<SliderWithInputProps> = ({
  label,
  value,
  onChangeValue,
  minBorder,
  maxBorder,
  minBorderText,
  maxBorderText,
  step = 1,
  className,
}) => {
  const formatedValue = currency(value, {
    separator: ' ',
    precision: 0,
    symbol: '',
  }).format();
  return (
    <div className={className}>
      <InputWrapper>
        <Label>{label}</Label>
        <StyledInput
          value={formatedValue}
          readOnly
          onChange={(e) => onChangeValue(Number(e.target.value))}
        />
        <Slider
          value={value}
          onChange={onChangeValue}
          min={minBorder}
          max={maxBorder}
          step={step}
        />
      </InputWrapper>
      <Borders>
        <Border>{minBorderText}</Border>
        <Border>{maxBorderText}</Border>
      </Borders>
    </div>
  );
};

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 7px;
`;

const StyledInput = styled(Input)`
  text-align: right;
  pointer-events: none;
`;

const Label = styled.span`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.grayDarker};
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Borders = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Border = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.gray};
`;

const Slider = styled(StyledSlider)`
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 0;
`;

export default SliderWithInput;
