import { COLORS, LINKS, TYPOGRAPHY } from '@/constants';
import { ArrowLeft } from '@/ui/iconComponents';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

interface TitleWithBackButtonProps {
  text?: string;
  className?: string;
}

const TitleWithBackButton: FC<TitleWithBackButtonProps> = ({
  text,
  className,
}) => {
  return (
    <Root className={className}>
      <Button onClick={() => navigate(LINKS.index)}>
        <ArrowLeft />
      </Button>
      {text && <Title>{text}</Title>}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const Button = styled.button`
  margin-right: 16px;
  display: flex;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title4Medium28};
  color: ${COLORS.black};
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headline1Medium24}
  }
`;

export default TitleWithBackButton;
