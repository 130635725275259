import React, { FC } from 'react';
import Slider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import { COLORS } from '@/constants';

interface StyledSliderProps extends SliderProps {
  className?: string;
}

const StyledSlider: FC<StyledSliderProps> = ({ className, ...props }) => {
  return (
    <Root className={className}>
      <Slider {...props} />
    </Root>
  );
};

const Root = styled.div`
  .rc-slider {
    height: 2px;
    padding: 0%;
  }
  .rc-slider-rail {
    background-color: ${COLORS.gray1};
  }
  .rc-slider-track {
    background-color: ${COLORS.primaryDefault};
  }
  .rc-slider-handle {
    width: 24px;
    height: 24px;
    background-color: ${COLORS.primaryDefault};
    border-color: ${COLORS.white};
    border-width: 4px;
    opacity: 1;
    margin-top: -10px;
    :hover,
    :active,
    :focus {
      border-color: ${COLORS.white};
      box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.6);
    }
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.6);
  }
`;

export default StyledSlider;
