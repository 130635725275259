import { COLORS } from '@/constants';
import * as React from 'react';
import { SVGProps } from 'react';

interface ManProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Man = ({ color = COLORS.black2, ...props }: ManProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.25 21.5a7.75 7.75 0 1 0 0-15.5 7.75 7.75 0 0 0 0 15.5ZM21.5 2.5 16 8M15 2.5h6.5V9"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Man;
