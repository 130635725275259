import { COLORS } from '@/constants';
import * as React from 'react';
import { SVGProps } from 'react';

interface CheckProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Check = ({ color = COLORS.black2, ...props }: CheckProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6 12 3.995 4L18 8"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Check;
