export interface ReviewData {
  name: string;
  years: string;
  money: string;
  text: string;
  service: string;
}

export const mainData = {
  tag: 'Кейсы',
  title: 'Больше 142 клиентов уже достигли своей цели',
};

export const reviewsData: ReviewData[] = [
  {
    name: 'Татьяна Владимировна',
    years: '53 года',
    money: '365 000',
    service: 'Магнитогорск',
    text: 'Воспользовалась социальным проектом «Выплаты». Это действительно успешная команда профессиональных консультантов. Получила онлайн-поддержку от консультантов Татьяны и Александра. Это грамотные специалисты, которые в доброжелательный форме, с огромным терпением и позитивом помогли мне оформить все документы на единовременную выплату в размере 365 тысяч рублей из АО «НПФ ЭВОЛЮЦИЯ». Спасибо, что создали такой проект. Он очень необходим таким пенсионерам, как я! Буду рекомендовать его всем своим знакомым, которые нуждаются в вашей помощи!',
    
  },
  {
    name: 'Наталья Стефановна',
    years: '52 года',
    money: '313 000',
    service: 'Провидения, Чукотка',
    text: 'Когда мне понадобилось оформить документы на единовременную выплату из АО «НПФ ГАЗФОНД пенсионные накопления» в размере 313 тысяч рублей, я получила квалифицированную подробную онлайн-консультацию от настоящих профессионалов своего дела Александра и Татьяны. Мне была очень приятна огромная моральная поддержка грамотной, доброжелательной, отзывчивой, терпеливой команды, которая уверенно продвигала к выполнению поставленной задачи. В непринужденной беседе я получила все необходимые ответы на свои вопросы. Созданный вами проект действительно необходим всем, кто нуждается в вашей помощи. Буду рекомендовать его своим знакомым и друзьям. Благодарю за позитив профессионально действующей команды!',
  },
  {
    name: 'Татьяна Дмитриевна',
    years: '53 года',
    money: '275 675',
    service: 'Эгвекинот, Чукотка',
    text: 'Здравствуйте. Хотелось бы поблагодарить за оказанную помощь работников Татьяну Викторовну и Александра Александровича, которые курировали в оформлении необходимых документов для получения единовременной выплаты в «Газфонде». Были очень корректны и терпеливы. В феврале оформили заявление через нотариуса, а в апреле получила выплату в сумме 275 тыс. руб. с лишним. Спасибо огромное Татьяне Викторовне и Александру Александровичу. Эгвекинот. Татьяна Дмитриевна.',
  },
  {
    name: 'Ирина Михайловна',
    years: '52 года',
    money: '252 477',
    service: 'Камчатский край, п. Озерновский',
    text: 'В 2017 году заключила договор с АО «НПФ ГАЗФОНД пенсионные накопления». С моим выходом на пенсию сотрудники  проекта предложили помощь. Это грамотные специалисты, профессионалы: Татьяна и Александр. Мы вместе, онлайн, через сайт НПФ с Татьяной и Александром, с огромным терпением и в доброжелательной форме оформили все документы на единовременную выплату. Это очень грамотные специалисты. Спасибо большое за такой проект, буду рекомендовать друзьям и знакомым.',
  },
 {
    name: 'Наталья Ивановна',
    years: '51 год',
    money: '239 363',
    service: 'Камчатский край, с. Устьевое',
    text: 'Очень признательна за помощь в получении единовременной накопительной пенсии. АО «НПФ ГАЗФОНД пенсионные накопления».', 
  },
  {
    name: 'Виктор Терузинович',
    years: '53 года',
    money: '309 503',
    service: 'Чукотка',
    text: 'Спасибо Вам большое за вашу работу. Очень оперативно и очень профессионально! АО «НПФ ГАЗФОНД пенсионные накопления».', 
  },
  {
    name: 'Екатерина Трикопенко',
    years: '55 лет',
    money: '410 028',
    service: 'Якутск',
    text: 'Хочу выразить искреннюю благодарность работникам Проекта за качественную, профессиональную работу. В частности, Татьяне Викторовне, которая за всё время нашего  сотрудничества была на постоянной связи со мной.  При обращении по любым интересующим меня вопросам  в части пенсионных накоплений,  выплат я получала оперативную, подробную информацию,  искреннее желание помочь! С удовольствием буду рекомендовать Вас своим друзьям и знакомым, как надёжного партнёра,  на которого можно всецело  положиться! С уважением,  Трикопенко Е.Б..', 
  },      
  ];
