import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import styled from 'styled-components';

const Tooltip: FC<TooltipProps> = ({
  children,
  place = 'top',
  ...outerProps
}) => {
  return (
    <StyledReactTooltip
      place={place}
      offset={{ top: 20, left: 20, right: 20, bottom: 20 }}
      arrowColor={COLORS.grayDarker}
      backgroundColor={COLORS.grayDarker}
      event="click"
      globalEventOff="click"
      overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
        const { documentElement } = document;
        left = Math.min(documentElement.clientWidth - node.clientWidth, left);
        top = Math.min(documentElement.clientHeight - node.clientHeight, top);
        left = Math.max(0, left);
        top = Math.max(0, top);
        return { top, left };
      }}
      {...outerProps}
    >
      {children}
    </StyledReactTooltip>
  );
};

const StyledReactTooltip = styled(ReactTooltip)`
  ${TYPOGRAPHY.caption1Regular14};
  font-size: 16px !important;
  color: ${COLORS.white} !important;
  background-color: ${COLORS.grayDarker} !important;
  border-radius: 16px !important;
  padding: 16px 24px !important;
  max-width: 324px;
  &.__react_component_tooltip.show {
    opacity: 1;
  }
`;

export default Tooltip;
