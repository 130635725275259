import {
  AuthResponseDto,
  ChangePasswordDto,
  ContractResponseDto,
  CreateContractDto,
  EmailSignInDto,
  NumberSignInDto,
  SignUpDto,
  UpdateContractDto,
  UpdateUserDto,
  UserResponseDto,
} from '@/api/generated';
import { http } from '@/services/http';
import { handleAsyncError } from '@/utils/handleAsyncError';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import {
  addContract,
  logOut,
  setContracts,
  setUserData,
  updateContract,
  updateUserData,
} from './actions';
import { ActionType } from './types';
import { toast } from 'react-toastify';

export const signUpThunk = createAsyncThunk<AuthResponseDto, SignUpDto>(
  ActionType.POST_REGISTRATION,
  async (values, { dispatch }) => {
    try {
      const { data }: AxiosResponse<AuthResponseDto> = await http.post(
        '/users/sign-up',
        values
      );
      dispatch(setUserData(data));

      return data;
    } catch (e) {
      const error = e as AxiosError;
      handleAsyncError(error);
    }
  }
);

export const signInByPhoneThunk = createAsyncThunk<
  AuthResponseDto,
  NumberSignInDto
>(ActionType.POST_LOGIN_BY_PHONE, async (values, { dispatch }) => {
  try {
    const { data }: AxiosResponse<AuthResponseDto> = await http.post(
      '/users/sign-in-mobile-number',
      values
    );

    dispatch(setUserData(data));

    return data;
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});

export const signInByEmailThunk = createAsyncThunk<
  AuthResponseDto,
  EmailSignInDto
>(ActionType.POST_LOGIN_BY_EMAIL, async (values, { dispatch }) => {
  try {
    const { data }: AxiosResponse<AuthResponseDto> = await http.post(
      '/users/sign-in-email',
      values
    );

    dispatch(setUserData(data));

    return data;
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});

export const resetPasswordThunk = createAsyncThunk<
  AuthResponseDto,
  ChangePasswordDto
>(ActionType.POST_RESET_PASSWORD, async (values, { dispatch }) => {
  try {
    const { data }: AxiosResponse<AuthResponseDto> = await http.post(
      '/users/reset-password',
      values
    );

    dispatch(logOut());

    return data;
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});

export const updateUserDataThunk = createAsyncThunk<
  UserResponseDto,
  UpdateUserDto
>(ActionType.POST_UPDATE_USER, async (values, { dispatch }) => {
  try {
    const { data }: AxiosResponse<UserResponseDto> = await http.patch(
      '/users/me',
      values
    );
    toast.success('Данные обновлены');
    dispatch(updateUserData(data));

    return data;
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});

export const fetchMyContractsThunk = createAsyncThunk(
  ActionType.FETCH_MY_CONTRACTS,
  async (_, { dispatch }) => {
    try {
      const { data }: AxiosResponse<ContractResponseDto[]> = await http.get(
        '/contracts'
      );
      dispatch(setContracts(data));
    } catch (e) {
      const error = e as AxiosError;
      handleAsyncError(error);
    }
  }
);

export const addContractThunk = createAsyncThunk<
  ContractResponseDto,
  CreateContractDto
>(ActionType.POST_ADD_CONTRACT, async (values, { dispatch }) => {
  try {
    const { data }: AxiosResponse<ContractResponseDto> = await http.post(
      '/contracts',
      values
    );
    dispatch(addContract(data));
    return data;
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});

interface UpdateContract extends UpdateContractDto {
  id: string;
}

export const updateContractThunk = createAsyncThunk<
  ContractResponseDto,
  UpdateContract
>(ActionType.POST_ADD_CONTRACT, async (values, { dispatch }) => {
  try {
    const { id, ...restValue } = values;
    const { data }: AxiosResponse<ContractResponseDto> = await http.patch(
      `/contracts/${id}`,
      restValue
    );
    dispatch(updateContract(data));
    return data;
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});
