import { CustomLink } from '@/components/CustomLink';
import { COLORS, LINKS, TYPOGRAPHY } from '@/constants';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { actions, selectors } from '@/store/ducks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';

interface UserLinkProps {
  className?: string;
}

const dropdownLink = [
  {
    text: 'Мой профиль',
    to: `${LINKS.profile}?tab=data`,
  },
  {
    text: 'Мои накопления',
    to: `${LINKS.profile}?tab=accumulation`,
  },
  {
    text: 'Пароль',
    to: `${LINKS.profile}?tab=password`,
  },
];

const UserLink: FC<UserLinkProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectors.auth.selectUserData);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const rootRef = useRef(null);

  useOnClickOutside(rootRef, () => setIsOpenDropdown(false));

  return (
    <Root ref={rootRef} className={className}>
      <UserInfoButton onClick={() => setIsOpenDropdown(!isOpenDropdown)}>
        <UserName>{userData.firstName}</UserName>
        <UserAvatarWrapper>
          {userData.avatarUrl && (
            <UserAvatar src={userData.avatarUrl} alt={userData.firstName} />
          )}
        </UserAvatarWrapper>
      </UserInfoButton>
      {isOpenDropdown && (
        <Dropdown>
          {dropdownLink.map((item, index) => {
            return (
              <StyledCustomLink
                to={item.to}
                key={index}
                onClick={() => setIsOpenDropdown(false)}
              >
                {item.text}
              </StyledCustomLink>
            );
          })}
          <LogoutButton onClick={() => dispatch(actions.auth.logOut())}>
            Выход
          </LogoutButton>
        </Dropdown>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const UserInfoButton = styled.button`
  display: flex;
  align-items: center;
`;

const UserName = styled.p`
  ${TYPOGRAPHY.body1SemiBold18};
  color: ${COLORS.black};
  margin-right: 16px;
`;

const UserAvatarWrapper = styled.div`
  width: 44px;
  height: 44px;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${COLORS.grayDarker};
`;

const UserAvatar = styled.img`
  max-width: none;
  object-fit: cover;
  width: 44px;
  height: 44px;
`;

const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 26px);
  right: 0;
  z-index: 2;
  min-width: 280px;
  background-color: ${COLORS.white};
  border-radius: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 7px 8px 32px rgba(241, 241, 241, 0.64);
  @media (max-width: 1100px) {
    display: none;
  }
`;

const StyledCustomLink = styled(CustomLink)`
  width: 100%;
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black};
  transition: all 0.3s ease;
  padding: 13px 20px;
  :hover {
    color: ${COLORS.primaryDefault};
  }
`;

const LogoutButton = styled.button`
  width: 100%;
  text-align: left;
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black};
  transition: all 0.3s ease;
  padding: 13px 20px;
  :hover {
    color: ${COLORS.primaryDefault};
  }
`;

export default UserLink;
