import React, {
  ButtonHTMLAttributes,
  FC,
  HTMLAttributes,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import { BUTTON_THEME, COLORS } from '@/constants';
import { ButtonSizes, ButtonThemes } from '@/types';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: ButtonThemes;
  size?: ButtonSizes;
  icon?: ReactNode;
  isLoading?: boolean;
  className?: string;
}

const Button: FC<ButtonProps> = ({
  children,
  theme = 'primary',
  size = 'large',
  isLoading = false,
  ...props
}) => {
  const loaderColor =
    theme === 'primary' ? COLORS.white : COLORS.primaryDefault;
  return (
    <Root $theme={theme} $size={size} $isLoading={isLoading} {...props}>
      {isLoading ? (
        <LoaderWrapper>
          <ClipLoader size={24} color={loaderColor} />
        </LoaderWrapper>
      ) : (
        children
      )}
    </Root>
  );
};

const Root = styled.button<{
  $theme: ButtonThemes;
  $size: ButtonSizes;
  $isLoading: boolean;
}>`
  ${({ $theme, $size }) => BUTTON_THEME[$theme][$size]};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  ${({ $isLoading }) => $isLoading && 'pointer-events: none;'}
`;

const LoaderWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export default Button;
