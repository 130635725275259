import { AdminHeader, Footer } from '@/components';
import React, { FC } from 'react';
import styled from 'styled-components';
import '@/libs/dayjs';

interface AdminLayoutProps {
  fullHeight?: boolean;
}

const AdminLayout: FC<AdminLayoutProps> = ({ children, fullHeight = true }) => {
  return (
    <PageContent $isFullHeight={fullHeight}>
      <AdminHeader />
      <main>{children}</main>
      <StyledFooter />
    </PageContent>
  );
};

const PageContent = styled.div<{ $isFullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $isFullHeight }) => $isFullHeight && 'height: 100vh;'};
`;

const StyledFooter = styled(Footer)`
  margin-top: auto;
`;

export default AdminLayout;
