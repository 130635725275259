import { COLORS } from '@/constants';
import * as React from 'react';
import { SVGProps } from 'react';

interface RubleProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Ruble = ({ color = COLORS.white, ...props }: RubleProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.883 13.91H4.019v-1.876h8.864c.818 0 1.494-.142 2.028-.426.534-.29.932-.682 1.193-1.176.262-.5.392-1.068.392-1.705 0-.636-.13-1.201-.392-1.696-.261-.5-.659-.892-1.193-1.176-.534-.29-1.21-.435-2.028-.435H8.894V21H6.781V3.545h6.102c1.295 0 2.363.236 3.204.708.841.471 1.466 1.1 1.875 1.883.41.784.614 1.648.614 2.591 0 .943-.205 1.807-.614 2.591-.409.784-1.034 1.412-1.875 1.884-.84.471-1.909.707-3.204.707Zm-.137 1.636v1.875H4.02v-1.875h8.727Z"
      fill={color}
    />
  </svg>
);

export default Ruble;
