import { COLORS, LINKS, PADDINGS, SOCIAL_LINKS, TYPOGRAPHY } from '@/constants';
import { Logo, Mail, Marker } from '@/ui/iconComponents';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import { CustomLink } from '../CustomLink';
import { menuTexts } from '../Header/Header';
import { PaymentSystems } from '../PaymentSystems';
import { mainData } from './data';

interface FooterProps {
  className?: string;
}

const Footer: FC<FooterProps> = ({ className }) => {
  return (
    <Root className={className}>
      <StyledTopContainer>
        <LogoLink to={LINKS.index}>
          <Logo />
        </LogoLink>
        <Menu>
          {menuTexts.map((item, index) => {
            return (
              <MenuItem key={index}>
                <MenuLink to={item.to}>{item.text}</MenuLink>
              </MenuItem>
            );
          })}
        </Menu>
        <OfficeContacts>
          <OfficeContactItem>
            <OfficeContactItemIcon>
              <Marker color={COLORS.additionalGreen} />
            </OfficeContactItemIcon>
            <OfficeContactAddressWrap>
              {mainData.address.map((address, key) => (
                <OfficeContactAddress key={key}>{address}</OfficeContactAddress>
              ))}
            </OfficeContactAddressWrap>
          </OfficeContactItem>
          <OfficeContactItem>
            <OfficeContactItemIcon>
              <Mail color={COLORS.additionalGreen} />
            </OfficeContactItemIcon>
            <a href={`mailto: ${mainData.email}`}>{mainData.email}</a>
          </OfficeContactItem>
        </OfficeContacts>
        <WhatsappSupportInfo>
          <WhatsappSupportPhone href={`tel: ${mainData.unformatedPhone}`}>
            {mainData.phone}
          </WhatsappSupportPhone>
          <PaymentSystems />
        </WhatsappSupportInfo>
      </StyledTopContainer>
      <BottomBlock>
        <StyledBottomContainer>
          <Copyright>Все права защищены © {new Date().getFullYear()}</Copyright>
        </StyledBottomContainer>
      </BottomBlock>
    </Root>
  );
};

const Root = styled.footer`
  background-color: ${COLORS.grayDarker};
  a {
    transition: color 0.3s ease-in;
    :hover,
    :focus {
      color: ${COLORS.primaryDefault};
    }
  }
`;

const StyledTopContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: ${PADDINGS.paddingBetweenSectionsSmall};
  padding-bottom: ${PADDINGS.paddingBetweenSectionsSmall};
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const LogoLink = styled(CustomLink)`
  @media (max-width: 600px) {
    margin-bottom: 24px;
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  @media (max-width: 850px) {
    width: 75%;
    flex-direction: row;
    margin-bottom: 42px;
  }
  @media (max-width: 600px) {
    width: auto;
    flex-direction: column;
    margin-bottom: 32px;
  }
`;

const MenuItem = styled.li`
  margin-bottom: 12px;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 850px) {
    margin-bottom: 0;
    margin-right: 12px;
    :last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 12px;
  }
`;

const MenuLink = styled(CustomLink)`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.white};
`;

const OfficeContacts = styled.div`
  max-width: 290px;
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.white};
  @media (max-width: 850px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: auto;
    margin-bottom: 32px;
  }
`;

const OfficeContactItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0;
  }
`;

const OfficeContactItemIcon = styled.div`
  margin-right: 12px;
`;

const OfficeContactAddressWrap = styled.div``;

const OfficeContactAddress = styled.address`
  font-style: normal;
  max-width: 250px;

  & + * {
    margin-top: 5px;
  }
`;

const WhatsappSupportInfo = styled.div`
  @media (max-width: 850px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: auto;
  }
`;

const WhatsappSupportPhone = styled.a`
  ${TYPOGRAPHY.title4Medium28};
  color: ${COLORS.white};
  margin-bottom: 11px;
`;

const BottomBlock = styled.div`
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 1px;
    background-color: ${COLORS.gray};
  }
`;

const StyledBottomContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  ${TYPOGRAPHY.caption1Regular14};
  color: ${COLORS.white};
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Copyright = styled.p`
  margin-right: 24.5%;
  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 4px;
  }
`;

const PrivacyPolicyLink = styled.a``;

export default Footer;
