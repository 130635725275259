import { ServiceData, TabData } from '@/types';
import { v4 as uuidv4 } from 'uuid';
import {
  ContractOps,
  FinancialPlan,
  FirstConsultation,
  Invvoice,
  SubmissionDocuments,
  TaxReturn,
} from '@/ui/iconComponents';
import { SOCIAL_LINKS } from '@/constants';

export const mainData = {
  tag: 'Услуги',
  title: 'Решения для вас',
};

export const auditServices: ServiceData[] = [
  {
    id: uuidv4(),
    type: 'Пенсионный аудит',
    name: 'Персональная консультация',
    description:
      'Бесплатная первичная консультация по вашей выписке с сайта Госуслуг',
    Image: FirstConsultation,
    link: (name: string) =>
      `${SOCIAL_LINKS.whatsapp}?text=${encodeURI(
        `Здравствуйте, хотел бы узнать об услуге "${name}"`
      )}`,
    linkText: 'Оформить',
  },
  {
    id: uuidv4(),
    type: 'Пенсионный аудит',
    name: 'Расчет по выплате',
    description:
      'Индивидуальный расчет сроков и вида выплаты пенсионных накоплений. Рекомендации по единовременной выплате',
    Image: Invvoice,
    link: (name: string) =>
      `${SOCIAL_LINKS.whatsapp}?text=${encodeURI(
        `Здравствуйте, хотел бы узнать об услуге "${name}"`
      )}`,
    linkText: 'Оформить',
  },
  {
    id: uuidv4(),
    type: 'Пенсионный аудит',
    name: 'Подача документов',
    description:
      'Оформление и подача документов на выплаты в НПФ (негосударственный пенсионный фонд). Юридическое сопровождение',
    Image: SubmissionDocuments,
    link: (name: string) =>
      `${SOCIAL_LINKS.whatsapp}?text=${encodeURI(
        `Здравствуйте, хотел бы узнать об услуге "${name}"`
      )}`,
    linkText: 'Оформить',
  },
];

export const investmentsServices: ServiceData[] = [
  {
    id: uuidv4(),
    type: 'Инвестирование и накопления',
    name: 'Личный финансовый план (ЛФП)',
    description:
      'Бесплатное составление ЛФП. Анализ ваших расходов и доходов, постановка целей и рекомендации по стратегии их достижения',
    Image: FinancialPlan,
    link: (name: string) =>
      `${SOCIAL_LINKS.whatsapp}?text=${encodeURI(
        `Здравствуйте, хотел бы узнать об услуге "${name}"`
      )}`,
    linkText: 'Оформить',
  },
  {
    id: uuidv4(),
    type: 'Инвестирование и накопления',
    name: 'Заключить договор ОПС/НПО',
    description:
      'Выбрать негосударственный пенсионный фонд для инвестирования, обслуживания вашей накопительной пенсии. Подобрать дополнительные пенсионные программы',
    Image: ContractOps,
    link: (name: string) =>
      `${SOCIAL_LINKS.whatsapp}?text=${encodeURI(
        `Здравствуйте, хотел бы узнать об услуге "${name}"`
      )}`,
    linkText: 'Оформить',
  },
  {
    id: uuidv4(),
    type: 'Инвестирование и накопления',
    name: 'Налоговая декларация',
    description:
      'Заполним 3-НДФЛ за вас. Поможем рассчитать налоговый вычет. Узнайте, какую сумму налога вы можете вернуть.',
    Image: TaxReturn,
    link: (name: string) =>
      `${SOCIAL_LINKS.whatsapp}?text=${encodeURI(
        `Здравствуйте, хотел бы узнать об услуге "${name}"`
      )}`,
    linkText: 'Оформить',
  },
];

export enum ServicesTabs {
  audit = 'audit',
  investment = 'investment',
}

export const tabsData: TabData[] = [
  {
    text: 'Пенсионный аудит',
    value: ServicesTabs.audit,
  },
  {
    text: 'Инвестирование и накопления',
    value: ServicesTabs.investment,
  },
];
