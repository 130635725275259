import * as React from 'react';
import { SVGProps } from 'react';

const MastercardPayment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44 26c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h40c1.1 0 2 .9 2 2v24Z"
      fill="#fff"
    />
    <path d="M25.06 8.502h-6.115v10.996h6.115V8.502Z" fill="#FF5F00" />
    <path
      d="M19.332 14.002a6.973 6.973 0 0 1 2.67-5.5A6.939 6.939 0 0 0 17.688 7c-3.871 0-7 3.129-7 7.002a7.004 7.004 0 0 0 7 6.998A6.94 6.94 0 0 0 22 19.498a6.999 6.999 0 0 1-2.669-5.496Z"
      fill="#EB001B"
    />
    <path
      d="M33.312 14.002a7.004 7.004 0 0 1-7 6.998A6.937 6.937 0 0 1 22 19.498a6.975 6.975 0 0 0 2.667-5.496A6.977 6.977 0 0 0 22 8.502 6.93 6.93 0 0 1 26.312 7c3.871 0 7 3.129 7 7.002Z"
      fill="#F79E1B"
    />
  </svg>
);

export default MastercardPayment;
