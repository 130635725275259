import { COLORS } from '@/constants';
import * as React from 'react';
import { SVGProps } from 'react';

interface MailProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Mail = ({ color = COLORS.black2, ...props }: MailProps) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m17 9-3.13 2.5c-1.03.82-2.72.82-3.75 0L7 9"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Mail;
