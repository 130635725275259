import { COLORS, TYPOGRAPHY } from '@/constants';
import { TabData } from '@/types';
import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface TabsProps {
  data: TabData[];
  onToggle: React.Dispatch<React.SetStateAction<string>>;
  activeValue: string;
  className?: string;
}

const Tabs: FC<TabsProps> = ({ data, activeValue, onToggle, className }) => {
  return (
    <Root className={className}>
      {data.map((tab, index) => (
        <Tab
          key={index}
          $isActive={activeValue === tab.value}
          onClick={() => onToggle(tab.value)}
        >
          {tab.text}
        </Tab>
      ))}
    </Root>
  );
};

const Root = styled.div`
  padding: 4px;
  display: inline-flex;
  background-color: ${COLORS.white};
  border-radius: 12px;
`;

const Tab = styled.button<{ $isActive: boolean }>`
  ${TYPOGRAPHY.caption1Medium14};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  border-radius: 10px;
  background-color: ${({ $isActive }) =>
    $isActive ? COLORS.primaryDefault : COLORS.white};
  color: ${({ $isActive }) => ($isActive ? COLORS.white : COLORS.gray)};
  text-align: center;
  margin-right: 4px;
  :last-child {
    margin-right: 0;
  }
`;

export default Tabs;
