import { COLORS, TYPOGRAPHY } from '@/constants';
import { Logo } from '@/ui/iconComponents';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import { CustomLink } from '../CustomLink';

const AdminHeader: FC = () => {
  return (
    <Root>
      <StyledContainer>
        <StyledLink to="/">
          <StyledLogo />
        </StyledLink>
        <ScrollWrapper>
          <Wrapper>
            <MenuNav>
              <Menu>
                <MenuItem>
                  <MenuLink to="/admin/dashboard">Загруженные выписки</MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink to="/admin/managers">Менеджеры</MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink to="/admin/users">Клиенты</MenuLink>
                </MenuItem>
              </Menu>
            </MenuNav>
          </Wrapper>
        </ScrollWrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.header`
  background-color: ${COLORS.white};
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 1100px) {
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

const StyledLink = styled(CustomLink)`
  margin-right: 83px;
  @media (max-width: 1300px) {
    margin-right: 40px;
  }
`;

const StyledLogo = styled(Logo)``;

const ScrollWrapper = styled.div`
  flex-grow: 1;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MenuNav = styled.nav`
  margin-right: 32px;
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;
`;

const MenuItem = styled.li`
  margin-right: 32px;
  :last-child {
    margin-right: 0;
  }
`;

const MenuLink = styled(CustomLink)`
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.black};
  cursor: pointer;
  transition: color 0.3s ease-in;
  :hover,
  :focus {
    color: ${COLORS.primaryDefault};
  }
`;

export default AdminHeader;
