import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import styled from 'styled-components';

interface TagProps {
  className?: string;
  text: string;
}

const Tag: FC<TagProps> = ({ text, className }) => {
  return <Root className={className}>{text}</Root>;
};

const Root = styled.p`
  display: inline-block;
  min-width: 180px;
  padding: 8px;
  text-align: center;
  background-color: ${COLORS.primaryLight};
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.primaryDefault};
  border-radius: 12px;
`;

export default Tag;
