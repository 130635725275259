import * as React from 'react';
import { SVGProps } from 'react';

const SubmissionDocuments = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={175}
    height={204}
    viewBox="0 0 175 204"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M141.041 99.212c-42.88-4.562-61.15-32.09-93.518-49.262-48.975-11.233-27.833 51.325-22.965 67.91 44.687 152.241 224.357-7.174 116.483-18.648Z"
      fill="#F7F9FA"
    />
    <path
      d="M21.794 191.823s2.835-20.679-2.81-18.636c-5.644 2.038-3.053 10.326 2.81 18.636Z"
      fill="#A0A3BD"
    />
    <path
      opacity={0.2}
      d="M21.775 176.63c-.29-.228-.395-.633-.525-.958-.151-.38-.324-.751-.618-1.047-.365-.358-.827-.552-1.331-.624-.483-.068-.995-.004-1.462-.156-.02-.004-.033-.017-.05-.026a5.311 5.311 0 0 1 1.197-.633c1.818-.658 2.755 1.051 3.192 3.659a1.653 1.653 0 0 1-.403-.215Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M16.385 176.774c.021 1.544.088 3.059.55 4.545.194.62.433 1.249.698 1.844.205.468.478.945.915 1.236.94.625 2.05-.017 2.722-.738.411-.443.764-1.005 1.23-1.376-.05 4.744-.705 9.538-.705 9.538-4.456-6.318-6.997-12.602-5.494-16.155.08.363.08.751.084 1.106Z"
      fill="#14142A"
    />
    <path
      d="M22.167 191.625S10.627 171.23 2.66 178.243c-7.963 7.01 19.508 13.382 19.508 13.382Z"
      fill="#A0A3BD"
    />
    <path
      opacity={0.2}
      d="M14.492 181.447c-.32.046-.655.071-.979.063h-.059c.03-.008-.28-.063-.327-.076-.323-.114-.588-.304-.84-.536-.487-.447-.89-.958-1.47-1.3-1.28-.751-2.843-.886-4.267-1.173-.63-.127-1.714-.173-1.974-.89a.76.76 0 0 1-.025-.431c3.267-1.198 6.9 1.165 10.012 4.326l-.071.017Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M2.945 183.475c1.07.713 2.26 1.11 3.544 1.131.672.013 1.361-.046 2.02.106.66.152 1.139.51 1.584 1.008.827.933 1.6 1.756 2.772 2.25 1.222.515 2.562.599 3.872.565.534-.017 1.046-.08 1.571-.135.403-.042.937-.122 1.31.084.483.266.484 1.098.4 1.566-.06.334-.19.65-.366.933-5.514-1.494-18.206-5.423-18.433-9.998.374.988.777 1.853 1.726 2.49Z"
      fill="#14142A"
    />
    <path
      d="M5.935 202.86h99.654l-1.428-5.005-3.36-11.854c-2.381-8.369-10.655-13.606-19.202-12.146l-4.313.751a5.686 5.686 0 0 1-.953.081 5.749 5.749 0 0 1-4.792-2.587c-7.404-11.158-24.145-9.778-29.643 2.447l-.735 1.642c-1.298 2.895-4.683 4.191-7.564 2.891l-4.3-1.937c-2.516-1.135-5.322-.287-6.871 1.709a5.663 5.663 0 0 0-1.143 2.583l-.638 3.747a5.761 5.761 0 0 1-1.02 2.435c-.098.122-.19.245-.286.355a5.758 5.758 0 0 1-3.348 1.916 5.623 5.623 0 0 1-4.187-.866c-2.238-1.502-5.246-1.126-7.148.785l-2.89 2.904c-3.737 3.751-1.1 10.149 4.167 10.149Z"
      fill="#DCDDE6"
    />
    <path
      opacity={0.15}
      d="M12.816 199.611c.618-1.904 2.945-3.706 5.003-3.25.247.055.457-.076.541-.308.47-1.295-.105-3.372 1.306-4.169 1.071-.604 2.621-.241 3.579.43 2.486 1.739 2.427 4.976 2.89 7.676.054.321.528.435.734.195.534-.617.89-1.427 1.298-2.123.571-.975 1.16-1.95 1.844-2.849.428-.565 1.096-1.523 1.86-1.646.983-.164.916 1.608.786 2.195-.315 1.452-1.13 2.726-1.508 4.153-.059.219.071.493.306.544.618.135 1.16-.097 1.664-.447.634-.439 1.108-.849 1.881-1.026 1.08-.245 2.835-.24 3.453-1.384.218-.405.155-.912.146-1.351-.02-.86-.105-1.717-.109-2.574-.008-1.684.37-3.287 1.168-4.773 1.285-2.388 3.351-4.452 5.493-6.077 2.125-1.612 4.54-2.865 7.127-3.528 4.927-1.262 10.567-.109 13.7 4.157 1.374 1.865 2.18 4.094 2.529 6.377.407 2.663.088 5.41-.231 8.064a52.164 52.164 0 0 1-.836 4.959H13.178c-.55-.941-.744-2.055-.362-3.245Z"
      fill="#14142A"
    />
    <path
      opacity={0.2}
      d="M55.762 168.153c-1.365.806-3.02.958-4.528 1.342-1.302.333-2.511.869-3.544 1.747-1.58 1.334-2.487 3.237-3.54 4.976-1.265 2.084-3.004 4.135-5.41 4.874-2.655.81-4.918-1.102-7.468-1.477-2.036-.304-4.12-.017-5.892 1.076-1.79 1.101-3.1 2.836-4.074 4.676-.95 1.789-1.898 3.583-3.81 4.477-2.469 1.157-5.249.857-7.676-.223-.849-.38-1.596-.84-2.336-1.351 1.454-.329 3.012-.11 4.314.764a5.622 5.622 0 0 0 3.166.962c.34 0 .693-.025 1.02-.097a5.747 5.747 0 0 0 3.348-1.916c.097-.109.19-.232.286-.354a5.768 5.768 0 0 0 1.02-2.435l.639-3.748a5.64 5.64 0 0 1 1.142-2.582c1.55-1.997 4.355-2.845 6.871-1.71l4.3 1.937a5.716 5.716 0 0 0 7.565-2.89l.735-1.642c3.183-7.077 10.134-10.512 16.858-9.993-.74 1.414-1.588 2.764-2.986 3.587Z"
      fill="#F2FAFF"
    />
    <path
      d="M14.727 202.86h90.855l-1.428-5.005c-4.49-4.047-10.697-6.03-17.013-4.937l-3.36.574-1.81.299c-.395.068-.79.097-1.172.097a6.788 6.788 0 0 1-2.449-.451 6.913 6.913 0 0 1-3.267-2.625 20.12 20.12 0 0 0-3.96-4.431 15.724 15.724 0 0 0-1.769-1.325c-3.31-2.203-7.106-3.296-10.915-3.339a19.85 19.85 0 0 0-6.573.984c-5.158 1.641-9.731 5.376-12.276 11.048l-.887 1.941c-1.566 3.486-5.636 5.031-9.092 3.473l-2.613-1.177-2.52-1.135a6.81 6.81 0 0 0-3.485-.574 6.875 6.875 0 0 0-6.124 5.718l-.142.865Z"
      fill="#DCDDE6"
    />
    <path
      d="M27.047 184.406c0 .983.794 1.781 1.772 1.781.979 0 1.773-.798 1.773-1.781 0-.983-.794-1.781-1.773-1.781-.978 0-1.772.798-1.772 1.781Z"
      fill="#A0A3BD"
    />
    <path
      d="M49.023 190.36c0 .983.794 1.781 1.773 1.781.978 0 1.772-.798 1.772-1.781 0-.983-.794-1.781-1.772-1.781a1.78 1.78 0 0 0-1.773 1.781Z"
      fill="#C3D1E2"
    />
    <path
      opacity={0.15}
      d="M52.367 173.075a3.422 3.422 0 0 0 3.415 3.43 3.422 3.422 0 0 0 3.414-3.43 3.423 3.423 0 0 0-3.414-3.431 3.423 3.423 0 0 0-3.415 3.431Z"
      fill="#14142A"
    />
    <path
      d="M52.61 172.26a3.423 3.423 0 0 0 3.414 3.431 3.423 3.423 0 0 0 3.414-3.431 3.423 3.423 0 0 0-3.414-3.431 3.423 3.423 0 0 0-3.415 3.431ZM27.107 201.924s7.988-10.12 4.343-10.821c-3.646-.696-4.864 4.541-4.343 10.821Z"
      fill="#C3D1E2"
    />
    <path
      opacity={0.2}
      d="M31.841 192.488c-.361-.122-.701-.232-1.084-.266-.764-.067-1.642.089-2.272.545-.247.177-.411.401-.57.641.717-1.688 1.868-2.616 3.536-2.3.764.148 1.012.713.936 1.507-.185 0-.403-.08-.546-.127Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M28.793 198.619c.331-.072.655-.219.957-.405-1.323 2.034-2.646 3.709-2.646 3.709a30.438 30.438 0 0 1-.088-3.777c.454.524 1.126.617 1.777.473Z"
      fill="#14142A"
    />
    <path
      d="M27.107 201.924s-5.035-13.61-10.592-10.12c-5.556 3.49 10.592 10.12 10.592 10.12Z"
      fill="#A0A3BD"
    />
    <path
      opacity={0.2}
      d="M23.167 195.429c-.416-.371-.668-.882-1.105-1.241-.47-.384-1.016-.62-1.57-.848-.53-.215-1.151-.409-1.479-.916-.197-.304-.286-.793-.126-1.148 1.953.304 3.747 2.313 5.158 4.507-.324-.042-.634-.135-.878-.354Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M20.334 197.539c.714.11 1.692.127 2.293.565.302.224.495.562.81.781.303.211.656.329 1.013.414.537.126 1.07.164 1.558.076a36.11 36.11 0 0 1 1.1 2.553s-5.443-2.237-8.92-4.794c.705.19 1.449.295 2.146.405Z"
      fill="#14142A"
    />
    <path
      d="M27.11 201.923s18.58-2.271 13.893-5.233c-4.688-2.967-13.894 5.233-13.894 5.233Z"
      fill="#A0A3BD"
    />
    <path
      opacity={0.2}
      d="M39.61 197.738c-.891.072-1.744-.27-2.617-.359-1.206-.122-2.214.228-3.343.574-.454.14-.933.258-1.416.283 2.852-1.671 6.288-2.988 8.648-1.608.021.536-.655 1.064-1.273 1.11Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M32.452 200.872c.563-.033 1.13-.071 1.684-.185.58-.119 1.138-.3 1.718-.427a5.583 5.583 0 0 1 1.083-.114c-4.267 1.102-9.828 1.781-9.828 1.781s.866-.763 2.184-1.738a.976.976 0 0 0 .185.24c.336.325.878.384 1.319.435.55.055 1.104.042 1.655.008Z"
      fill="#14142A"
    />
    <path
      d="M145.083 192.131s-2.756-20.096 2.729-18.112c5.486 1.983 2.97 10.039-2.729 18.112Z"
      fill="#A0A3BD"
    />
    <path
      opacity={0.2}
      d="M145.098 177.365c.281-.22.382-.612.508-.933.147-.371.315-.73.601-1.017.352-.35.806-.54 1.293-.608.47-.063.966-.004 1.424-.152.017-.004.029-.016.046-.025a5.1 5.1 0 0 0-1.163-.616c-1.768-.641-2.68 1.021-3.104 3.558.147-.051.286-.119.395-.207Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M150.333 177.509c-.021 1.498-.083 2.975-.533 4.418-.189.604-.42 1.216-.676 1.794-.202.456-.466.92-.891 1.198-.915.608-1.99-.012-2.645-.717-.399-.435-.744-.979-1.197-1.338.05 4.608.684 9.272.684 9.272 4.33-6.141 6.8-12.247 5.338-15.703-.071.35-.075.73-.08 1.076Z"
      fill="#14142A"
    />
    <path
      d="M144.719 191.937s11.218-19.826 18.958-13.006c7.74 6.815-18.958 13.006-18.958 13.006Z"
      fill="#A0A3BD"
    />
    <path
      opacity={0.2}
      d="M152.181 182.05c.311.047.634.068.949.063h.059c-.03-.008.273-.059.315-.075.31-.11.571-.296.814-.52.475-.434.87-.932 1.428-1.261 1.243-.73 2.764-.865 4.15-1.14.613-.122 1.663-.169 1.919-.865a.78.78 0 0 0 .025-.418c-3.179-1.164-6.707 1.136-9.731 4.204.026 0 .047.008.072.012Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M163.402 184.021c-1.042.696-2.197 1.08-3.444 1.101-.651.013-1.323-.046-1.966.101-.642.148-1.108.494-1.541.979-.806.908-1.554 1.705-2.692 2.186-1.189.498-2.491.583-3.763.549-.517-.013-1.017-.08-1.529-.135-.391-.042-.907-.118-1.273.084-.47.258-.47 1.064-.386 1.524.059.325.185.629.353.907 5.363-1.451 17.694-5.271 17.912-9.714-.357.958-.747 1.797-1.671 2.418Z"
      fill="#14142A"
    />
    <path
      d="M160.517 202.86H63.664l1.39-4.866 3.268-11.525c2.314-8.132 10.357-13.226 18.664-11.804l4.191.731c.32.054.622.08.929.08a5.59 5.59 0 0 0 4.657-2.511c7.195-10.846 23.465-9.504 28.811 2.38l.714 1.595a5.557 5.557 0 0 0 7.354 2.811l4.183-1.882c2.449-1.102 5.17-.279 6.678 1.662a5.498 5.498 0 0 1 1.113 2.511l.622 3.642a5.65 5.65 0 0 0 .991 2.368c.092.118.185.24.277.346a5.598 5.598 0 0 0 3.255 1.861c.315.067.659.093.991.093a5.46 5.46 0 0 0 3.079-.937c2.179-1.46 5.098-1.097 6.946.764l2.81 2.823c3.612 3.642 1.05 9.858-4.07 9.858Z"
      fill="#DCDDE6"
    />
    <path
      opacity={0.15}
      d="M153.836 199.704c-.596-1.849-2.86-3.604-4.863-3.157-.239.055-.445-.072-.525-.3-.458-1.257.105-3.275-1.268-4.051-1.042-.587-2.55-.232-3.478.418-2.415 1.688-2.36 4.836-2.81 7.461-.054.312-.512.422-.714.19-.516-.599-.865-1.384-1.264-2.064-.554-.949-1.125-1.895-1.789-2.768-.416-.549-1.067-1.477-1.81-1.6-.958-.156-.886 1.562-.764 2.136.306 1.413 1.1 2.65 1.465 4.038.059.216-.071.477-.298.528-.6.131-1.125-.097-1.617-.435-.617-.426-1.075-.823-1.827-.996-1.05-.24-2.755-.236-3.355-1.342-.215-.392-.152-.886-.143-1.312.017-.836.101-1.667.105-2.503.008-1.637-.361-3.199-1.138-4.638-1.252-2.321-3.255-4.325-5.338-5.904-2.067-1.565-4.414-2.785-6.926-3.426-4.788-1.224-10.269-.106-13.318 4.043-1.331 1.814-2.116 3.979-2.457 6.195-.399 2.591-.088 5.258.223 7.841.193 1.616.462 3.228.811 4.819h52.741c.542-.937.731-2.017.357-3.173Z"
      fill="#14142A"
    />
    <path
      opacity={0.2}
      d="M112.09 169.128c1.327.785 2.935.933 4.401 1.304 1.268.325 2.44.849 3.444 1.697 1.533 1.295 2.419 3.144 3.444 4.836 1.23 2.026 2.919 4.022 5.258 4.735 2.579.789 4.779-1.068 7.257-1.439 1.983-.295 4.003-.017 5.729 1.047 1.739 1.072 3.016 2.755 3.961 4.545.919 1.738 1.843 3.481 3.704 4.351 2.398 1.122 5.103.831 7.463-.22.823-.367 1.554-.814 2.272-1.312-1.411-.321-2.927-.106-4.191.742a5.491 5.491 0 0 1-3.079.937c-.332 0-.676-.025-.991-.093a5.615 5.615 0 0 1-3.532-2.207 5.589 5.589 0 0 1-.991-2.367l-.622-3.642a5.457 5.457 0 0 0-1.113-2.511c-1.508-1.941-4.233-2.764-6.678-1.663l-4.183 1.882c-2.801 1.262-6.094.005-7.354-2.81l-.714-1.595c-3.095-6.879-9.848-10.217-16.387-9.711.722 1.38 1.545 2.693 2.902 3.494Z"
      fill="#F2FAFF"
    />
    <path
      d="M151.971 202.86H63.664l1.39-4.866c4.364-3.933 10.395-5.862 16.535-4.798l3.268.557 1.76.291c.382.067.768.093 1.138.093.819 0 1.625-.148 2.38-.439a6.698 6.698 0 0 0 3.176-2.553 19.432 19.432 0 0 1 3.851-4.305 16.415 16.415 0 0 1 1.718-1.291c3.213-2.14 6.905-3.203 10.609-3.241a19.303 19.303 0 0 1 6.388.958c5.015 1.595 9.458 5.224 11.932 10.74l.861 1.886c1.52 3.389 5.476 4.891 8.836 3.376l2.541-1.143 2.449-1.102a6.623 6.623 0 0 1 3.385-.557 6.68 6.68 0 0 1 5.951 5.554l.139.84Z"
      fill="#DCDDE6"
    />
    <path
      d="M139.998 184.925c0 .953-.772 1.73-1.722 1.73a1.728 1.728 0 0 1-1.721-1.73c0-.954.772-1.731 1.721-1.731.95 0 1.722.773 1.722 1.731Z"
      fill="#A0A3BD"
    />
    <path
      d="M118.647 190.714c0 .953-.769 1.73-1.722 1.73a1.729 1.729 0 0 1-1.722-1.73c0-.954.769-1.731 1.722-1.731.949 0 1.722.773 1.722 1.731Z"
      fill="#C3D1E2"
    />
    <path
      opacity={0.15}
      d="M115.386 173.91a3.328 3.328 0 0 1-3.318 3.334 3.328 3.328 0 0 1-3.318-3.334 3.328 3.328 0 0 1 3.318-3.334 3.328 3.328 0 0 1 3.318 3.334Z"
      fill="#14142A"
    />
    <path
      d="M115.159 173.121a3.328 3.328 0 0 1-3.318 3.334 3.328 3.328 0 0 1-3.318-3.334 3.328 3.328 0 0 1 3.318-3.334 3.325 3.325 0 0 1 3.318 3.334ZM139.929 201.951s-7.766-9.837-4.221-10.517c3.545-.679 4.725 4.41 4.221 10.517Z"
      fill="#C3D1E2"
    />
    <path
      opacity={0.2}
      d="M135.323 192.776c.353-.118.681-.228 1.055-.257.743-.068 1.596.084 2.209.527.239.173.399.389.554.621-.697-1.642-1.818-2.545-3.435-2.233-.744.144-.987.692-.912 1.465.181.004.391-.076.529-.123Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M138.284 198.734a3.181 3.181 0 0 1-.932-.392 53.442 53.442 0 0 0 2.57 3.604c.105-1.283.134-2.519.088-3.672-.437.511-1.096.6-1.726.46Z"
      fill="#14142A"
    />
    <path
      d="M139.93 201.951s4.893-13.23 10.294-9.837c5.401 3.389-10.294 9.837-10.294 9.837Z"
      fill="#A0A3BD"
    />
    <path
      opacity={0.2}
      d="M143.755 195.633c.403-.363.647-.857 1.071-1.203.458-.376.987-.603 1.528-.823.517-.211 1.118-.397 1.437-.89.193-.296.277-.768.126-1.114-1.899.295-3.642 2.245-5.011 4.38.311-.042.609-.135.849-.35Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M146.503 197.684c-.693.106-1.646.123-2.23.553-.294.216-.483.545-.786.756-.294.206-.638.32-.982.401a3.815 3.815 0 0 1-1.516.076c-.677 1.43-1.067 2.481-1.067 2.481s5.287-2.173 8.668-4.659c-.684.186-1.407.287-2.087.392Z"
      fill="#14142A"
    />
    <path
      d="M139.927 201.95s-18.059-2.203-13.502-5.089c4.557-2.883 13.502 5.089 13.502 5.089Z"
      fill="#A0A3BD"
    />
    <path
      opacity={0.2}
      d="M127.778 197.879c.866.067 1.693-.262 2.541-.351 1.172-.118 2.151.22 3.251.558.441.135.907.249 1.373.274-2.772-1.621-6.11-2.904-8.403-1.562-.021.524.638 1.034 1.238 1.081Z"
      fill="#F2FAFF"
    />
    <path
      opacity={0.15}
      d="M134.734 200.925c-.546-.033-1.096-.067-1.637-.177-.563-.114-1.109-.291-1.668-.413a5.51 5.51 0 0 0-1.054-.114c4.149 1.072 9.551 1.73 9.551 1.73s-.84-.743-2.126-1.692c-.046.084-.105.16-.176.232-.328.316-.853.371-1.281.422a9.294 9.294 0 0 1-1.609.012Z"
      fill="#14142A"
    />
    <path
      d="M67.986 143.756c1.05.173 6.858 4.718 6.522 9.495-.31 4.427-1.268 5.182-1.877 4.954-.025.266-.059.528-.097.794a24.678 24.678 0 0 1-.663 3.119c-.046.164-.097.324-.143.489l.013.139v.017a.24.24 0 0 1-.118.199 25.905 25.905 0 0 1-2.163 5.059s0 .005-.004.005a.24.24 0 0 1-.336.084.243.243 0 0 1-.084-.338l.008-.012a25.708 25.708 0 0 0 2.222-4.938.211.211 0 0 1-.012-.071v-.009a8.682 8.682 0 0 0-.143-1.705 6.356 6.356 0 0 0-.517-1.637c-.247-.515-.6-.984-1.062-1.325-.05-.038-.11-.068-.164-.102a2.049 2.049 0 0 0-1.02.11c-.656.249-5.095 3.6-6.355 2.258-1.222-1.308 2.163-5.571 7.114-2.608.168.067.337.143.492.24.504.321.911.79 1.21 1.313.302.523.508 1.093.659 1.671.059.236.109.477.151.717.256-.894.466-1.806.617-2.726.042-.274.08-.548.114-.827-.282-.076-.874-.527-1.004-2.946-.172-3.186-1.37-6.988-2.89-8.959-1.524-1.971-1.52-2.629-.47-2.46ZM63.603 153.212s-4.124-2.363-4.212-4.844c-.088-2.482-.563-3.212-.563-3.212s3.142.422 4.456 2.515c1.315 2.094.37 5.33.37 5.33s-.198-2.477-1.836-4.325c0-.004.441 1.553 1.785 4.536ZM37.88 174.884s-6.774-2.613-7.484-6.339c-.71-3.726-1.591-4.722-1.591-4.722s4.842-.097 7.316 2.764c2.473 2.857 1.785 7.963 1.785 7.963s-.87-3.697-3.772-6.106c.005-.005 1.03 2.245 3.746 6.44ZM164.625 191.046s.727-4.713 2.999-5.684c2.272-.971 2.784-1.675 2.784-1.675s.731 3.097-.743 5.081c-1.479 1.983-4.822 2.249-4.822 2.249s2.235-1.072 3.364-3.267c0 0-1.289.971-3.582 3.296ZM144.401 161.99s-1.873-4.384-.458-6.419c1.415-2.034 1.478-2.903 1.478-2.903s2.256 2.237 2.05 4.705c-.206 2.469-2.902 4.478-2.902 4.478s1.327-2.097 1.13-4.562c.004 0-.58 1.511-1.298 4.701ZM170.152 203.9h-56.11a.88.88 0 0 1-.878-.882.88.88 0 0 1 .878-.882h56.11a.88.88 0 0 1 .878.882.877.877 0 0 1-.878.882ZM110.289 203.9H.878a.88.88 0 0 1-.878-.882c0-.486.39-.882.878-.882h109.411a.882.882 0 0 1 0 1.764Z"
      fill="#DCDDE6"
    />
    <path
      d="M101.386 5.213a.931.931 0 0 0 .722 1.098.932.932 0 0 0 1.096-.726l.903-4.465a.927.927 0 1 0-1.818-.371l-.903 4.464Z"
      fill="#4E4B66"
    />
    <path
      d="M89.305 76.09c.202-.364.378-1.068.185-1.79-.08-.3-.177-3.097-.882-1.793-.29 1.375-1.411.48-1.512.637-.189.282-.462.447-.47.818-.009.325.088.646.155.963.08.375.122.755.168 1.135.059.485.21 1.127.201 1.717.047.009.08.013.08.013s2.272 4.267 2.579 4.727c.483.346 2.218-1.313 2.218-1.313.168-.367-1.647-2.73-2.722-5.115Z"
      fill="#D7A28E"
    />
    <path
      d="M112.675 74.261c-5.367-.126-14.935 15.172-14.935 15.172l-7.55-12.724-3.13 2.325s-1.52 20.38 9.807 22.928c6.354 1.431 15.867-13.238 17.211-19.64.79-3.752 2.201-7.976-1.403-8.06Z"
      fill="#E5AC61"
    />
    <path
      opacity={0.1}
      d="M112.671 74.261c-1.659-.037-3.721 1.397-5.775 3.402-.739 1.228-1.894 2.24-2.969 3.24a72.21 72.21 0 0 0-2.608 3.33c-.05.127-.097.254-.134.38-.257.853-.135 1.638.008 2.499.143.856.159 1.662-.126 2.494-.471 1.359-1.525 2.819-3.02 3.072-1.474.249-2.583-.675-3.8-1.334-.56-.303-1.143-.472-1.752-.637-.479-.13-1.008-.257-1.407-.565-1.155-.886.596-2.174 1.323-2.57.722-.393 1.642-.688 2.175-1.35a3.37 3.37 0 0 0 .576-1.136l-4.973-8.381-3.129 2.325s-1.52 20.38 9.807 22.928c6.354 1.431 15.867-13.238 17.211-19.64.785-3.748 2.197-7.972-1.407-8.056Z"
      fill="#14142A"
    />
    <path
      d="M105.796 198.372s-.156-4.768.546-4.946c.701-.177 2.222.013 2.503.389.281.375-.84 5.178-1.55 5.684-.705.502-1.415.139-1.499-1.127Z"
      fill="#F4C095"
    />
    <path
      opacity={0.2}
      d="M107.291 197.638a33.43 33.43 0 0 1-.756-2.195c-.114-.359-.219-.713-.353-1.063-.05-.135-.105-.27-.155-.405-.332 1.291-.231 4.397-.231 4.397.084 1.27.793 1.633 1.503 1.127.13-.093.269-.325.416-.646a24.578 24.578 0 0 0-.424-1.215Z"
      fill="#fff"
    />
    <path
      d="M106.575 195.6c-.265-.114-.517-.253-.777-.371-.466 2.038-.046 3.177-.046 3.177.088 1.325.827 1.709 1.57 1.178.433-.308 1.013-2.153 1.361-3.71-.659.215-1.537-.034-2.108-.274Z"
      fill="#DCDDE6"
    />
    <path
      d="m97.036 199.554-.483-.026s-.294.541-.143.853c.152.308 1.962 1.376 5.784 2.101 3.822.73 5.703.473 5.766-.223.063-.697-9.639-.764-10.924-2.705Z"
      fill="#14142A"
    />
    <path
      d="M107.955 202.259c-5.388.565-12.666-2.346-11.243-2.887.315-.118.823-.168 1.437-.185 2.171-.064 5.619.253 5.934-.768.408-1.309 1.151-1.351 1.302-1.266.038.021.139.092.286.177.42.253 1.192.646 1.776.409.781-.316.895-.35 1.126.021.676 1.089.487 1.845-.618 4.499Z"
      fill="#4E4B66"
    />
    <path
      d="M105.661 197.33c-.134.295-.349.62-.575.878-.563.637-.11 1.164-1.596 2.203-1.487 1.038-4.314-.283-5.112-.756-.47-.278-.386-.409-.239-.468 2.171-.064 5.619.253 5.934-.768.408-1.309 1.151-1.351 1.302-1.266.042.021.143.088.286.177Z"
      fill="#14142A"
    />
    <path
      d="M106.23 102.61c-4.376 13.264-.844 92.083-.844 92.083s2.986 3.19 3.855.628c11.852-34.967 22.297-83.279 15.985-93.521-6.313-10.243-17.224-4.575-18.996.81Z"
      fill="#4E4B66"
    />
    <path
      opacity={0.15}
      d="M125.222 101.8c-6.313-10.243-17.224-4.575-19.001.81-1.293 3.92-1.894 13.559-2.104 25.198.345-.114.664-.287 1-.46.42-.215.861-.35 1.327-.194 1.356.456 1.924 1.962 1.974 3.271.059 1.455-.349 2.895-.273 4.35.042.756.302 1.52.685 2.165.361.604.86 1.093 1.36 1.583.563.549 1.117 1.11 1.571 1.751.554.785.932 1.688 1.184 2.613.513 1.861.559 3.806.983 5.684.323 1.439.437 2.887.24 4.355-.181 1.321-.567 2.6-1.004 3.857-.441 1.275-.928 2.532-1.285 3.832a15.407 15.407 0 0 0-.534 4.465c.08 3.714.538 7.444-.063 11.137-.298 1.827-.89 3.6-1.096 5.44-.084.759-.084 1.523-.197 2.279a11.398 11.398 0 0 1-.462 1.941c-.706 2.076-2.167 3.975-4.326 4.591.105 2.68.176 4.22.176 4.22s2.986 3.191 3.856.629c11.856-34.959 22.301-83.271 15.989-93.517Z"
      fill="#14142A"
    />
    <path
      d="M141.433 196.839s-.256-5.524-1.08-5.667c-.823-.144-2.566.215-2.86.679-.29.46 1.441 5.908 2.306 6.427.865.519 1.65.034 1.634-1.439Z"
      fill="#F4C095"
    />
    <path
      opacity={0.2}
      d="M139.636 196.126c.26-.856.474-1.73.676-2.603.097-.422.189-.849.311-1.262.046-.161.096-.321.142-.486.5 1.461.664 5.065.664 5.065.017 1.472-.768 1.958-1.633 1.439-.156-.093-.341-.351-.538-.709.113-.486.231-.967.378-1.444Z"
      fill="#fff"
    />
    <path
      d="M140.274 193.703c.294-.152.572-.338.861-.498.723 2.313.345 3.667.345 3.667.017 1.541-.802 2.051-1.71 1.507-.529-.317-1.369-2.397-1.911-4.165.786.19 1.777-.178 2.415-.511Z"
      fill="#DCDDE6"
    />
    <path
      d="M147.55 201.177s.222.755-.37 1.262c-1.478 1.249-7.261 1.654-7.656 1.202-.395-.451-.454-1.126-.454-1.126l8.48-1.338Z"
      fill="#14142A"
    />
    <path
      d="M140.783 196.923s-1.759.089-2.041-.569c-.281-.659-.29-.743-.416-1-.323.24.307 2.261.332 3.291.025 1.03.072 3.938.718 4.457.647.519 4.066-.836 4.066-.836s-.76-1.16-1.378-2.574c-.625-1.418.118-2.811-1.281-2.769Z"
      fill="#4E4B66"
    />
    <path
      d="M142.639 198.477s.47-3.393-.588-3.36c-1.058.034-1.231.144-1.714.625-.483.477.656 2.946.819 3.199.164.253 1.483-.464 1.483-.464Z"
      fill="#14142A"
    />
    <path
      d="M144.842 199.877s-1.478.477-1.852 1.418c-.374.941-2.524-1.308-2.293-2.325.231-1.017 2.264-.823 2.264-.823s.05 1.856 1.881 1.73Z"
      fill="#14142A"
    />
    <path
      opacity={0.2}
      d="M144.517 199.856s-1.244.401-1.558 1.19c-.315.789-2.126-1.098-1.928-1.954.193-.853 1.902-.692 1.902-.692s.046 1.561 1.584 1.456Z"
      fill="#fff"
    />
    <path
      d="M147.521 201.688c-.743.967-3.872 1.87-4.704 1.249-.831-.62-.743-3.629 2.285-3.659 1.247-.016 2.94 1.731 2.419 2.41Z"
      fill="#4E4B66"
    />
    <path
      opacity={0.2}
      d="M144.605 199.548c.399-.068.739-.047 1.021.038a4.768 4.768 0 0 1-.202.477 1.85 1.85 0 0 0-1.226.012c-1.075.389-1.096.435-1.096.435s.558-.802 1.503-.962ZM146.078 199.923a.235.235 0 0 1-.038.363.226.226 0 0 1-.256-.004 1.86 1.86 0 0 0-.261-.156c.076-.156.143-.312.202-.473.134.076.252.165.353.27Z"
      fill="#fff"
    />
    <path
      d="M128.169 109.214s13.364 41.922 13.272 82.554c-.005 2.207-4.137 1.831-4.137 1.831s-26.195-59.339-27.757-81.904c-.45-6.469 18.622-2.481 18.622-2.481Z"
      fill="#4E4B66"
    />
    <path
      opacity={0.25}
      d="M137.72 193.621c.138-1.524-.219-3.077-.651-4.528-.462-1.553-1.071-3.056-1.663-4.562-.698-1.773-1.399-3.545-2.067-5.33-.684-1.84-1.331-3.697-1.881-5.579-.509-1.739-1.059-3.558-1.097-5.381-.037-1.89.345-3.785.076-5.667-.269-1.899-1.188-3.668-1.89-5.432-.743-1.861-1.667-4.342-.592-6.283.68-1.228 2.087-1.718 3.393-1.921 1.685-.261 3.402-.181 5.099-.173.315 0 .651.005 1.004.005-3.91-22.692-9.282-39.551-9.282-39.551s-19.072-3.988-18.622 2.481c1.558 22.565 27.757 81.904 27.757 81.904s.164.008.416.017Z"
      fill="#14142A"
    />
    <path
      d="M108.84 75.786c4.868-6.229 15.229-4.676 20.474.464 5.246 5.136 6.103 39.302.987 39.433-5.115.131-24.149 8.845-26.967-1.481-2.814-10.331-3.217-27.258 5.506-38.416Z"
      fill="#F7C06B"
    />
    <path
      opacity={0.15}
      d="M124.905 82.446a13.872 13.872 0 0 0-.378 1.747c-.214 1.52-.18 3.064.08 4.574.269 1.553.811 2.971 1.491 4.385 1.239 2.574 2.759 5.423 1.756 8.339-.761 2.199-2.865 3.275-4.297 4.959-.407.477-.558.92-.701 1.54-.168.726-.286 1.464-.412 2.199-.138.789-.281 1.582-.483 2.363-.159.612-.344 1.262-.705 1.789-.269.393-.672.697-1.172.612-.66-.109-1.227-.768-1.575-1.291-.643-.967-.794-2.211-.932-3.338-.076-.608-.131-1.22-.231-1.828-.101-.603-.261-1.198-.387-1.797-.323-1.541-.617-3.089-.731-4.659-.121-1.688.001-3.385.034-5.077.118-5.617.021-11.23-.113-16.847a30.983 30.983 0 0 1-.5 1.764c-.533 1.739-1.071 3.452-1.386 5.25-.576 3.296-.66 6.647-.718 9.985-.055 3.127-.458 6.195-.987 9.276-.756 4.405-1.705 8.967-1.487 13.462 5.829 1.435 15.426-4.081 19.231-4.178 3.675-.093 4.267-17.746 2.365-29.587-2.881-.41-5.649-1.625-7.762-3.642Z"
      fill="#14142A"
    />
    <path
      d="M118.99 74.373c-.471-.152-1.29-1.912-1.764-2.063-.231.139-2.121 1.553-2.352 1.692.08.371.609 2.102.689 2.473-4.364 11.462-5.04 21.257-8.047 32.6.907 4.089 1.558 8.187 2.763 12.205 1.999-3.756 3.511-7.525 4.708-11.618-1.109-10.838.093-22.443 1.722-33.158.261-.215 2.348-1.903 2.281-2.13Z"
      fill="#F15A29"
    />
    <path
      d="m119.594 71.175 1.441.663s-.546 1.004-3.814.793c-3.213.494-4.448-.717-4.448-.717l1.991-.406.828-4c1.23.013 2.381.008 3.62 0l.382 3.667Z"
      fill="#E9AA8E"
    />
    <path
      opacity={0.15}
      d="m119.595 71.175-.383-3.667c-1.238.008-2.389.013-3.62 0l-.147.97c.21.355.45.693.744.98.218.215.474.43.768.531.265.089.55.123.823.173.168.034.345.076.504.14a.744.744 0 0 0-.222.206c-.181.287.285.498.466.608.286.177.722.494.5.886-.185.325-.647.22-.945.334-.244.092-.139.287-.059.472.071.173.122.334.118.507 2.356-.524 2.889-1.482 2.889-1.482l-1.436-.658Z"
      fill="#14142A"
    />
    <path
      d="M121.678 64.321a1.115 1.115 0 0 1-.198-.384l-.021.085a.54.54 0 0 1-.025.101c-.008.046-.025.089-.034.135-.012.06-.033.127-.054.199a6.144 6.144 0 0 1-.063.215 1.652 1.652 0 0 1-.042.13c-.458 1.402-1.63 4.022-3.944 3.967-3.003-.067-4.254-4.287-4.376-4.71-.004-.025-.008-.033-.008-.033s0 .004-.009.008c-.063.08-.571.671-1.222-.696-.693-1.46 1.004-1.101 1.004-1.101s-.823-5.719 2.621-5.799c2.356-.05 4.67-1.148 6.102 1.186.533.874.642 2.701.378 4.655.105-.106.718-.667.928-.13.239.581-.386 3.008-1.037 2.172Z"
      fill="#E9AA8E"
    />
    <path
      opacity={0.15}
      d="M113.9 59.228c.764.261 1.583.308 2.385.295a7.554 7.554 0 0 0 2.121-.342c.807-.248 1.596-.595 2.298-1.076.231-.16.462-.341.667-.544-1.436-2.245-3.729-1.173-6.06-1.123-1.42.034-2.113 1.022-2.436 2.174.277.27.626.477 1.025.616Z"
      fill="#14142A"
    />
    <path
      opacity={0.05}
      d="M122.183 62.417s.626-.185.467.355c-.16.544-.429 1.127-.853 1.064 0 0 .5-.207.571-.769.072-.56-.563-.32-.185-.65ZM113.203 64.056c-.012.309.349.57.811.591.462.021.848-.21.861-.519.013-.308-.348-.57-.81-.59-.462-.022-.849.21-.862.519ZM119.297 63.993c-.012.308.349.57.811.59.462.022.848-.21.861-.518.012-.308-.349-.57-.811-.591-.466-.021-.848.211-.861.52Z"
      fill="#14142A"
    />
    <path
      d="M118.331 60.824a.076.076 0 0 1-.029-.008.086.086 0 0 1-.046-.11c.004-.013.47-1.089 1.923-.646.042.013.068.06.055.106a.085.085 0 0 1-.105.054c-1.306-.396-1.709.511-1.722.553a.084.084 0 0 1-.076.05ZM117.447 62.178c-.008 0-.012.004-.017.004-.012.013-.008.034.013.06.025.029.588.772-.176.966-.026.008-.021.038.008.072.029.033.076.054.101.05.848-.215.18-1.088.176-1.097-.033-.034-.08-.059-.105-.055ZM116.413 60.748a.087.087 0 0 1-.072-.043c-.021-.038-.52-.903-1.965-.4-.042.016-.088-.009-.105-.051a.081.081 0 0 1 .05-.106c1.584-.552 2.159.469 2.168.477a.084.084 0 0 1-.034.114.156.156 0 0 1-.042.009Z"
      fill="#4E4B66"
    />
    <path
      d="M118.846 65.837s-1.361.835-3.07.283c-.286.717 1.848 2.236 3.07-.283Z"
      fill="#FCFAF7"
    />
    <path
      d="M118.859 61.871c.05.397.307.688.575.654.269-.034.45-.38.399-.776-.05-.397-.306-.688-.575-.655-.269.034-.445.384-.399.777ZM114.907 61.964c.05.396.306.688.575.654.269-.034.45-.38.399-.777-.05-.396-.306-.688-.575-.654-.269.034-.45.384-.399.777Z"
      fill="#4E4B66"
    />
    <path
      opacity={0.05}
      d="M117.203 67.872c.134-.017.218-.047.227-.05.033-.014.05-.052.038-.081-.013-.034-.051-.05-.08-.038a1.288 1.288 0 0 1-1.101-.181c-.029-.022-.067-.013-.088.016-.021.03-.012.068.017.089.386.257.76.27.987.245ZM112.32 62.595s-.613-.227-.491.325c.121.553.352 1.152.781 1.114 0 0-.487-.236-.521-.801-.029-.566.588-.287.231-.638Z"
      fill="#14142A"
    />
    <path
      d="M120.752 57.864s-11.096 4.005-10.067-.485c1.033-4.494 3.54-2.962 3.54-2.962s-1.869-.79-.886-1.63c.983-.84 2.062 1.187 6.191 1.039 4.128-.148 4.187 6.554 2.347 8.334 0-.008-2.402-.84-1.125-4.295Z"
      fill="#4E4B66"
    />
    <path
      d="M113.496 57.96s-.08 3.103-.84 4.259c0 0-1.02-2.026-.693-3.963.328-1.937 1.533-.295 1.533-.295Z"
      fill="#4E4B66"
    />
    <path
      opacity={0.25}
      d="M111.523 57.915s4.818 1.144 9.11-.958c0 0-5.069 3.173-9.11.958Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M115.055 57.112s3.103.74 5.871-.62c0 0-3.267 2.047-5.871.62ZM121.631 56.413s.172 1.557-.647 2.823c0-.004 1.181-1.472.647-2.823ZM121.997 56.484s.714 2.351-.185 4.541c0 0 1.399-2.612.185-4.54ZM111.969 55.471s.403-1.059 2.57-1.033c0 0-1.285-.912-.504-1.157.785-.245 2.436.912 3.49.79 1.054-.123 2.377-.077 2.806.134 0 0-1.823-.29-2.68.157-.857.447-2.537-.35-2.537-.35s.761.898-.184.995c-.941.097-1.76-.468-2.961.464Z"
      fill="#fff"
    />
    <path
      d="M117.19 71.892s.546 4.182-2.793 2.807c-3.339-1.376-1.642-3.419.534-3.997 0 0 .487.92-.534 1.338.29.835 2.793-.148 2.793-.148Z"
      fill="#4E4B66"
    />
    <path
      d="M117.252 71.892s-.546 4.182 2.793 2.807c3.334-1.38 1.65-3.419-.529-3.997 0 0-.496.92.529 1.338-.29.835-2.793-.148-2.793-.148Z"
      fill="#4E4B66"
    />
    <path
      d="M127.193 115.414s-.063-1.519.252-1.819c.73-.701 1.747-.819 2.037-.684-.962.751-2.289 2.503-2.289 2.503Z"
      fill="#D7A28E"
    />
    <path
      d="m131.051 115.464-2.877-.823a4.027 4.027 0 0 0-4.981 2.785l-1.525 5.373c-.201.709.21 1.447.916 1.65l.067.021 2.025-7.145c.26-.92.898-1.544 1.427-1.393l6.036 1.726c.525.152.743 1.022.483 1.942l-2.029 7.14.067.021a1.328 1.328 0 0 0 1.642-.92l1.525-5.372c.605-2.152-.634-4.393-2.776-5.005Z"
      fill="#4E4B66"
    />
    <path
      d="M132.314 116.169a.424.424 0 0 0-.277-.494c-.933-.325-3.503-1.207-4.721-1.452a.573.573 0 0 0-.664.41c-.138.472-.277.97-.147 1.422.051.164.202.278.37.325 0 0 3.624 1.363 4.742 1.359.424-.009.583-1.047.697-1.57Z"
      fill="#14142A"
    />
    <path
      opacity={0.33}
      d="M131.496 116.996c-.584.122-1.134-.199-1.63-.464-.101-.055-.197-.11-.307-.152-.193-.076-.386-.047-.592-.051-.487-.008-.89-.207-1.335-.38-.21-.08-.441-.097-.647-.198a1.687 1.687 0 0 1-.496-.376l-.012-.017c-.034.237-.038.473.029.692.046.165.202.279.37.325 0 0 3.624 1.364 4.741 1.359.366-.004.534-.764.643-1.312a1.158 1.158 0 0 1-.764.574Z"
      fill="#14142A"
    />
    <path
      d="m143.855 125.943-4.792 16.906c-.5 1.76-2.154 2.844-3.683 2.41l-26.518-7.592c-1.537-.439-2.365-2.237-1.865-3.997l4.792-16.906c.471-1.658 2.088-2.641 3.625-2.202l26.518 7.592c1.524.438 2.394 2.131 1.923 3.789Z"
      fill="#14142A"
    />
    <path
      opacity={0.33}
      d="M112.523 122.867a10.125 10.125 0 0 0 3.625 2.144c1.382.468 3.032.836 3.767 2.3.281.561.437 1.186.727 1.743.315.612.856 1.144 1.377 1.553.277.219.58.422.907.548.244.098.559.19.824.136.18-.038.34-.19.504-.275.302-.156.625-.287.945-.401.252-.088.512-.143.777-.097.306.055.575.237.814.435.513.426.781 1.144 1.348 1.498.53.333 1.076.042 1.454-.409.352-.427.646-.882 1.079-1.224.731-.583 1.638-.81 2.524-.764 1.903.097 3.755 1.27 4.817 2.895.5.764.832 1.692.782 2.65-.059 1.157-1.008 3.807-1.89 4.528-.895.731-2.029.963-3.113 1.026-1.205.072-2.41-.097-3.595.274a4.781 4.781 0 0 0-1.495.802c-.323.253-.605.557-.894.852l7.58 2.17c1.533.439 3.184-.638 3.688-2.406l3.246-11.457-29.798-8.521Z"
      fill="#14142A"
    />
    <path
      opacity={0.33}
      d="m141.932 122.15-14.002-4.009c.743.713 1.734 1.114 2.704 1.422 1.193.38 2.407.679 3.6 1.059 2.15.679 4.573 1.574 5.741 3.663.458.815.76 1.874.58 2.815-.131.667-.588 1.177-1.189 1.46-.664.312-1.424.393-2.15.397a12.337 12.337 0 0 1-2.331-.237c-.332-.063-.66-.139-.987-.219-.655-.169-1.265-.477-1.886-.739a6.195 6.195 0 0 0-1.756-.455c-.239-.026-.474-.064-.688-.178a1.183 1.183 0 0 1-.387-.35c-.168.519-.235 1.059-.285 1.604l13.2 3.777 1.764-6.225c.47-1.65-.395-3.346-1.928-3.785Z"
      fill="#14142A"
    />
    <path
      d="m124.373 126.255-.664-.19a.69.69 0 0 0-.852.477l-.588 2.072a.695.695 0 0 0 .474.857l.664.189a.69.69 0 0 0 .853-.476l.587-2.073a.694.694 0 0 0-.474-.856Zm-1.159 2.861a.587.587 0 0 1 .319-1.127.585.585 0 1 1-.319 1.127ZM129.397 127.693l-.664-.19a.692.692 0 0 0-.853.477l-.588 2.072a.695.695 0 0 0 .475.857l.663.19a.691.691 0 0 0 .853-.477l.588-2.072a.692.692 0 0 0-.474-.857Zm-1.155 2.862a.586.586 0 0 1 .319-1.127c.31.088.491.413.403.726a.588.588 0 0 1-.722.401Z"
      fill="#FCC61A"
    />
    <path
      d="m142.74 129.88-.382 1.346c-4.826-.755-10.307-1.962-16.052-3.608-5.943-1.701-11.411-3.646-16.001-5.634l.382-1.346c4.59 1.987 10.058 3.933 16.001 5.634 5.754 1.65 11.235 2.857 16.052 3.608Z"
      fill="#FCC61A"
    />
    <path
      d="M131.617 115.76a5.82 5.82 0 0 1-.475.755v.004l-.101.169a9.623 9.623 0 0 1-.167.253s-.668 1.085-1.046 1.022c-.378-.064-.412-.346-.412-.346s-.294.228-.6.029c-.307-.194-.265-.46-.265-.46s-.264.215-.613-.004c-.349-.22-.323-.599-.323-.599s-.42.299-.672-.287c-.252-.587.373-1.279.398-1.313a5.12 5.12 0 0 1 .29-.396c.03-.034.051-.072.084-.102 2.369-1.97 4.309-6.705 4.855-6.735 0 0 2.407.785 2.327 1.41-.273.519-3.078 4.785-3.078 4.785s.34.815-.202 1.815Z"
      fill="#D7A28E"
    />
    <path
      opacity={0.2}
      d="M131.139 116.514c0 .005 0 .005 0 0-.008.013-.046.081-.105.173-.046.072-.105.161-.168.254 0 0-.667 1.084-1.045 1.021-.378-.063-.412-.346-.412-.346s-.294.228-.601.029c-.306-.194-.264-.46-.264-.46s-.265.216-.613-.004c-.349-.219-.324-.599-.324-.599s-.42.3-.672-.287c-.092-.211-.067-.435 0-.637.08.228.231.451.437.574a.502.502 0 0 0 .172.063c.067.013.139.004.21.008.151.013.231.14.324.249.113.14.239.266.415.325.131.047.269.051.408.064.143.017.281.05.395.147.105.093.205.182.34.228.235.08.47 0 .659-.143.13-.097.24-.224.349-.346.143-.161.281-.321.42-.481.239-.279.479-.549.68-.853a1.778 1.778 0 0 1-.126.266 5.93 5.93 0 0 1-.479.755Z"
      fill="#fff"
    />
    <path
      d="M129.323 76.246s10.328 7.157 14.2 16.745c3.872 9.588-11.432 20.48-11.432 20.48l-2.625-1.304s1.982-8.351 6.064-14.758c-5.837-3.296-13.002-12.373-11.734-18.526.735-3.557 5.527-2.637 5.527-2.637Z"
      fill="#F7C06B"
    />
    <path
      opacity={0.1}
      d="M142.396 90.611c-.264 1.157-.205 2.38-.222 3.562-.021 1.414-.248 2.743-.777 4.06-.815 2.021-2.272 3.65-3.356 5.516-.999 1.717-1.852 3.494-3.074 5.068-1.004 1.291-2.356 2.709-4.07 2.908-.458.05-.886.008-1.297-.106-.093.355-.139.553-.139.553l2.625 1.304s15.304-10.892 11.432-20.48a27.265 27.265 0 0 0-1.122-2.385Z"
      fill="#14142A"
    />
    <path
      d="M86.031 78.833a.76.76 0 0 0 .592.9.757.757 0 0 0 .895-.596l9.378-46.299a.763.763 0 0 0-.592-.899.751.751 0 0 0-.89.596L86.03 78.833Z"
      fill="#4E4B66"
    />
    <path
      d="M151.707 54.192c-4.675-24.324-23.419-44.61-49.038-49.94a.23.23 0 0 1-.063-.017l-.155-.034-.088-.017s-.072-.013-.063-.013a63.513 63.513 0 0 0-4.868-.789c-.013 0-.025-.004-.042-.004a62.602 62.602 0 0 0-29.613 3.583l-.105.038c-12.134 4.62-22.755 13.053-30 23.873.244-.127.487-.249.73-.371a24.797 24.797 0 0 1 16.578-1.9c8.425 1.946 14.91 7.99 17.778 15.602.025-.025.046-.046.067-.071 5.741-5.98 14.3-8.968 23.003-7.191 8.727 1.785 15.463 7.942 18.403 15.74.034-.037.072-.075.11-.113 5.598-5.807 13.867-8.774 22.326-7.263a24.878 24.878 0 0 1 12.566 6.242 25.35 25.35 0 0 1 2.474 2.645Z"
      fill="#3AAF81"
    />
    <path
      opacity={0.4}
      d="M72.82 44.127c5.742-5.98 14.3-8.968 23.003-7.191 8.727 1.785 15.464 7.942 18.404 15.741.033-.038.071-.076.109-.114 2.881-17.632-1.844-34.875-11.84-48.21-14.413 8.36-25.472 22.396-29.676 39.774Z"
      fill="#36C186"
    />
    <path
      opacity={0.4}
      d="M102.305 4.167c17.4 8.069 29.668 23.422 34.367 41.138.21.033.42.076.626.118a24.893 24.893 0 0 1 14.418 8.77C147.042 29.867 128.298 9.58 102.679 4.25c-.076-.016-.143-.033-.219-.046l-.155-.038Z"
      fill="#36C186"
    />
    <path
      opacity={0.4}
      d="M102.614 4.23c-19.155.591-36.417 9.91-47.635 24.367-.205-.05-.415-.097-.625-.14a24.8 24.8 0 0 0-16.682 2.41c13.763-20.556 38.907-31.87 64.56-26.713.076.013.147.026.219.042l.163.034Z"
      fill="#36C186"
    />
    <path
      d="m86.388 77.533 1.399.287a.226.226 0 0 1 .176.266l-.802 3.963c-.298 1.464-.235 3.287 1.73 3.692 1.466.3 2.504-.675 2.92-2.739l.432-2.135a.225.225 0 0 1 .265-.177l1.394.287a.226.226 0 0 1 .176.266l-.424 2.105c-.848 4.187-3.515 4.292-5.031 3.984-2.713-.553-3.906-2.65-3.28-5.748l.78-3.865a.224.224 0 0 1 .265-.186Z"
      fill="#4E4B66"
    />
    <path
      d="M88.675 73.424c-.278-.739-2.046-.823-2.046-.823-.47.249-.071.746-.398 1.135-.328.388-.198.975-.568 1.439-.37.464.19 1.253.328 1.392.134.14 1.567.705 1.676.389.168-.477-1.193-1.165-1.193-1.165s2.276 1.047 2.066.215c-.109-.434-.94-.886-1.398-1.127.68.199 1.772.346 1.642-.016-.134-.385-1.088-.937-1.596-1.203.668.266 1.7.342 1.487-.236Z"
      fill="#E5B1A1"
    />
    <path
      opacity={0.25}
      d="m87.3 76.504-.112.371s.201.135.377.034c.177-.097-.264-.405-.264-.405ZM88.129 75.719l-.113.371s.201.135.378.034c.176-.101-.265-.405-.265-.405ZM88.379 74.512l-.113.371s.201.135.378.034c.176-.097-.265-.405-.265-.405ZM88.278 73.2l-.114.372s.202.135.378.033c.176-.097-.264-.405-.264-.405Z"
      fill="#FCFAF7"
    />
    <path
      d="M98.804 192.194c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.97 8.891 3.566 19.865 3.566 10.975 0 19.866-1.596 19.866-3.566v-3.339l-14.427-.092Z"
      fill="url(#paint0_radial_301_44952)"
    />
    <path
      d="M113.231 192.287c0 1.97-8.896 3.566-19.866 3.566-10.97 0-19.865-1.596-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M112.63 192.287c0 1.845-8.622 3.338-19.256 3.338s-19.257-1.493-19.257-3.338c0-1.844 8.623-3.338 19.257-3.338 10.634-.004 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M112.105 192.286c0 1.663-8.391 3.009-18.744 3.009s-18.744-1.346-18.744-3.009c0-1.663 8.391-3.005 18.744-3.005 10.353-.004 18.744 1.342 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M96.733 191.852c-.525 0-.937.021-1.23.068a2.86 2.86 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.318 7.318 0 0 1-1.008.257c-.399.076-.869.135-1.42.182-.554.042-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.32-.768-.531H83.57v-.401l2.495-.139c.374-.182 1.033-.034 1.882-.127.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.36.025-1.78.076-.43.051-.681.122-.753.211-.075.093.026.165.307.215.285.055.722.08 1.298.08.537 0 .974-.025 1.314-.08.336-.05.685-.143 1.033-.274a9.45 9.45 0 0 1 .916-.3c.269-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.025.873-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.155-.071 1.508.119l2.541.173v.4h-2.045c.142.245-.152.448-.912.6-.793.16-2.003.24-3.603.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.299.39-.067.533-.16.432-.278-.08-.097-.298-.169-.642-.224a8.693 8.693 0 0 0-1.26-.076Z"
      fill="url(#paint1_radial_301_44952)"
    />
    <path
      d="M96.733 191.453c-.525 0-.937.021-1.23.067a2.926 2.926 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.309 7.309 0 0 1-1.008.258c-.399.075-.869.135-1.42.181a26.16 26.16 0 0 1-1.96.068c-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.321-.768-.532H83.57l.404-.262h2.137c.374-.181.987-.312 1.84-.405.823-.088 1.81-.135 2.973-.135l-.176.405c-.769 0-1.36.026-1.78.076-.43.051-.681.123-.753.211-.075.093.026.165.307.216.286.054.722.08 1.298.08a8.68 8.68 0 0 0 1.314-.08 5.155 5.155 0 0 0 1.034-.275c.335-.126.642-.228.915-.304.269-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.881.046 2.62.139.76.097 1.323.241 1.676.431h1.97l.407.261h-2.045c.143.245-.151.448-.911.6-.794.16-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.39-.067.534-.16.433-.283-.08-.097-.298-.168-.643-.219-.34-.038-.76-.063-1.268-.063Z"
      fill="#FA9351"
    />
    <path
      d="M101.476 187.606c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.971 8.891 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint2_radial_301_44952)"
    />
    <path
      d="M115.902 187.695c0 1.971-8.895 3.566-19.865 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.89-3.566 19.865-3.566 10.974 0 19.865 1.595 19.865 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M115.302 187.696c0 1.844-8.622 3.338-19.257 3.338-10.634 0-19.256-1.494-19.256-3.338 0-1.845 8.622-3.339 19.257-3.339 10.634 0 19.256 1.494 19.256 3.339Z"
      fill="#FFC148"
    />
    <path
      d="M114.785 187.694c0 1.663-8.391 3.009-18.744 3.009s-18.744-1.346-18.744-3.009c0-1.662 8.391-3.008 18.744-3.008 10.353.004 18.744 1.35 18.744 3.008Z"
      fill="#FBE034"
    />
    <path
      d="M99.404 187.264c-.525 0-.936.021-1.23.068a2.86 2.86 0 0 0-.819.245 8.417 8.417 0 0 1-.798.329 7.321 7.321 0 0 1-1.008.257c-.399.076-.87.135-1.42.182-.554.042-1.21.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.646-.14-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.374-.182 1.033-.034 1.881-.127.824-.089 1.81-.536 2.974-.536l.017.485-.193.321c-.769 0-1.361.025-1.781.076-.429.051-.68.122-.752.211-.075.093.025.165.307.215.285.055.722.081 1.297.081.538 0 .975-.026 1.32-.081.335-.05.68-.143 1.032-.274.336-.127.643-.228.92-.304a7.73 7.73 0 0 1 .89-.186c.32-.046.685-.084 1.088-.109.404-.026.874-.038 1.42-.038 1.016 0 1.886.046 2.621.143.76.097 1.154-.071 1.507.118l2.541.173v.401h-2.045c.143.245-.151.448-.912.6-.793.16-2.003.24-3.603.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.299.39-.067.533-.16.433-.278-.08-.097-.299-.169-.643-.224a9.62 9.62 0 0 0-1.269-.072Z"
      fill="url(#paint3_radial_301_44952)"
    />
    <path
      d="M99.404 186.859c-.525 0-.936.021-1.23.067a2.926 2.926 0 0 0-.819.245 8.162 8.162 0 0 1-.798.329 7.309 7.309 0 0 1-1.008.258c-.399.076-.87.135-1.42.181a26.17 26.17 0 0 1-1.96.068c-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.321-.768-.532h-2.197l.403-.261h2.138c.374-.182.987-.313 1.84-.405.823-.089 1.81-.135 2.973-.135l-.176.405c-.769 0-1.361.025-1.78.076-.43.05-.681.122-.753.211-.075.092.025.164.307.215.285.055.722.08 1.297.08a8.65 8.65 0 0 0 1.32-.08c.335-.051.68-.144 1.032-.274.337-.127.643-.228.92-.304a7.73 7.73 0 0 1 .89-.186c.32-.046.685-.084 1.088-.11a23.21 23.21 0 0 1 1.42-.038c1.016 0 1.886.047 2.621.14.76.097 1.319.24 1.676.43h1.969l.408.262h-2.046c.143.244-.151.447-.911.599-.794.16-2.003.24-3.603.24l-.265-.586c.852 0 1.482-.034 1.881-.106.391-.067.534-.16.433-.282-.08-.097-.298-.169-.643-.22a11.781 11.781 0 0 0-1.276-.063Z"
      fill="#FA9351"
    />
    <path
      d="M98.804 182.368c-1.73-.088-3.553-.135-5.439-.135-1.407 0-2.776.026-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.891 3.566 19.865 3.566 10.975 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint4_radial_301_44952)"
    />
    <path
      d="M113.231 182.462c0 1.97-8.896 3.566-19.866 3.566-10.97 0-19.865-1.596-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M112.63 182.462c0 1.844-8.622 3.338-19.256 3.338s-19.257-1.494-19.257-3.338 8.623-3.338 19.257-3.338c10.634-.004 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M112.105 182.461c0 1.663-8.391 3.009-18.744 3.009s-18.744-1.346-18.744-3.009c0-1.663 8.391-3.005 18.744-3.005 10.353-.004 18.744 1.342 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M96.733 182.026c-.525 0-.937.021-1.23.068a2.892 2.892 0 0 0-.82.244 7.98 7.98 0 0 1-.798.33 7.318 7.318 0 0 1-1.008.257c-.399.076-.869.135-1.42.181-.554.043-1.209.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.32-.768-.531H83.57v-.401l2.495-.14c.374-.181 1.033-.033 1.882-.126.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.36.025-1.78.076-.43.05-.681.122-.753.211-.075.093.026.164.307.215.285.055.722.08 1.298.08.537 0 .974-.025 1.314-.08a5.15 5.15 0 0 0 1.033-.274c.336-.127.643-.228.916-.304.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.109.403-.026.873-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.155-.072 1.508.118l2.541.173v.401h-2.045c.142.245-.152.447-.912.599-.793.157-2.003.241-3.603.241l-.265-.587c.013-.198-.042-.19 0-.401.647-.008 1.55.355 1.882.3.39-.067.533-.16.432-.279-.08-.097-.298-.168-.642-.223a9.229 9.229 0 0 0-1.26-.072Z"
      fill="url(#paint5_radial_301_44952)"
    />
    <path
      d="M96.733 181.626c-.525 0-.937.021-1.23.067a2.893 2.893 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.177 7.177 0 0 1-1.008.257c-.399.076-.869.135-1.42.182-.554.046-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.646-.14-.89-.321-.768-.532H83.57l.404-.262h2.137c.374-.177.987-.312 1.84-.405.823-.088 1.81-.135 2.973-.135l-.176.405c-.769 0-1.36.026-1.78.076-.43.051-.681.123-.753.211-.075.093.026.165.307.215.286.055.722.081 1.298.081.538 0 .974-.026 1.314-.081.336-.05.685-.143 1.034-.274.335-.126.642-.228.915-.304.269-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.323.241 1.676.431h1.97l.407.262h-2.045c.143.244-.151.447-.911.599-.794.156-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.39-.067.534-.16.433-.283-.08-.097-.298-.168-.643-.219-.34-.042-.76-.067-1.268-.067Z"
      fill="#FA9351"
    />
    <path
      d="M101.476 177.967c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.891 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint6_radial_301_44952)"
    />
    <path
      d="M115.902 178.056c0 1.971-8.895 3.566-19.865 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.89-3.566 19.865-3.566 10.974 0 19.865 1.595 19.865 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M115.302 178.057c0 1.844-8.622 3.338-19.257 3.338-10.634 0-19.256-1.494-19.256-3.338s8.622-3.338 19.257-3.338c10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M114.785 178.056c0 1.658-8.391 3.009-18.744 3.009s-18.744-1.346-18.744-3.009c0-1.663 8.391-3.009 18.744-3.009 10.353.004 18.744 1.35 18.744 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M99.404 177.626c-.525 0-.936.021-1.23.067a2.926 2.926 0 0 0-.819.245 8.417 8.417 0 0 1-.798.329 7.312 7.312 0 0 1-1.008.258c-.399.076-.87.135-1.42.181a26.17 26.17 0 0 1-1.96.068c-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.374-.181 1.033-.034 1.881-.127.824-.088 1.81-.536 2.974-.536l.017.486-.193.32c-.769 0-1.361.026-1.781.076-.429.051-.68.123-.752.211-.075.093.025.165.307.216.285.055.722.08 1.297.08a8.65 8.65 0 0 0 1.32-.08c.335-.051.68-.144 1.032-.275.336-.126.643-.228.92-.303.269-.076.567-.136.89-.186.32-.047.685-.085 1.088-.11.404-.025.874-.038 1.42-.038 1.016 0 1.886.047 2.621.144.76.097 1.154-.072 1.507.118l2.541.173v.401h-2.045c.143.244-.151.447-.912.599-.793.156-2.003.24-3.603.24l-.265-.586c.013-.198-.042-.19 0-.401.647-.008 1.55.354 1.882.3.39-.068.533-.161.433-.283-.08-.097-.299-.169-.643-.22a9.643 9.643 0 0 0-1.269-.071Z"
      fill="url(#paint7_radial_301_44952)"
    />
    <path
      d="M99.404 177.219c-.525 0-.936.021-1.23.068a2.86 2.86 0 0 0-.819.245 8.417 8.417 0 0 1-.798.329 7.318 7.318 0 0 1-1.008.257c-.399.076-.87.135-1.42.182-.554.046-1.21.067-1.96.067-1.294 0-2.29-.076-2.962-.224-.646-.143-.89-.32-.768-.531h-2.197l.403-.262h2.138c.374-.177.987-.312 1.84-.405.823-.089 1.81-.135 2.973-.135l-.176.405c-.769 0-1.361.025-1.78.076-.43.051-.681.122-.753.211-.075.093.025.165.307.215.285.055.722.08 1.297.08a8.65 8.65 0 0 0 1.32-.08c.335-.05.68-.143 1.032-.274.337-.127.643-.228.92-.304a7.73 7.73 0 0 1 .89-.186c.32-.046.685-.084 1.088-.109.404-.026.874-.038 1.42-.038 1.016 0 1.886.046 2.621.143.76.097 1.319.241 1.676.431h1.969l.408.261h-2.046c.143.245-.151.448-.911.6-.794.156-2.003.24-3.603.24l-.265-.587c.852 0 1.482-.033 1.881-.105.391-.068.534-.16.433-.283-.08-.097-.298-.169-.643-.219a10.711 10.711 0 0 0-1.276-.068Z"
      fill="#FA9351"
    />
    <path
      d="M98.804 172.73c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.971 8.891 3.566 19.865 3.566 10.975 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint8_radial_301_44952)"
    />
    <path
      d="M113.231 172.819c0 1.971-8.896 3.566-19.866 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.599 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M93.366 176.158c10.635 0 19.256-1.495 19.256-3.338 0-1.844-8.621-3.339-19.256-3.339s-19.257 1.495-19.257 3.339c0 1.843 8.622 3.338 19.257 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M93.361 175.827c10.352 0 18.744-1.347 18.744-3.009 0-1.661-8.392-3.008-18.744-3.008s-18.744 1.347-18.744 3.008c0 1.662 8.392 3.009 18.744 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M96.733 172.388c-.525 0-.937.021-1.23.068a2.86 2.86 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.177 7.177 0 0 1-1.008.257c-.399.076-.869.135-1.42.182-.554.042-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.646-.14-.89-.321-.768-.532H83.57v-.401l2.495-.139c.374-.182 1.033-.034 1.882-.127.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.36.025-1.78.076-.43.051-.681.122-.753.211-.075.093.026.165.307.215.285.055.722.081 1.298.081.537 0 .974-.026 1.314-.081.336-.05.685-.143 1.033-.274.336-.127.643-.228.916-.304.269-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.026.873-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.155-.071 1.508.118l2.541.173v.401h-2.045c.142.245-.152.448-.912.6-.793.16-2.003.24-3.603.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.299.39-.067.533-.16.432-.282-.08-.097-.298-.169-.642-.22a9.393 9.393 0 0 0-1.26-.072Z"
      fill="url(#paint9_radial_301_44952)"
    />
    <path
      d="M96.733 171.989c-.525 0-.937.021-1.23.067a2.926 2.926 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.309 7.309 0 0 1-1.008.258c-.399.076-.869.135-1.42.181a26.16 26.16 0 0 1-1.96.068c-1.294 0-2.29-.076-2.962-.224-.646-.144-.89-.321-.768-.532H83.57l.404-.261h2.137c.374-.178.987-.313 1.84-.406.823-.088 1.81-.135 2.973-.135l-.176.406c-.769 0-1.36.025-1.78.075-.43.051-.681.123-.753.211-.075.093.026.165.307.216.286.055.722.08 1.298.08a8.55 8.55 0 0 0 1.314-.08 5.155 5.155 0 0 0 1.034-.275c.335-.126.642-.227.915-.303a7.73 7.73 0 0 1 .89-.186c.32-.047.685-.085 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.881.047 2.62.144.76.097 1.323.24 1.676.43h1.97l.407.262h-2.045c.143.245-.151.447-.911.599-.794.156-2.004.241-3.604.241l-.264-.587c.852 0 1.482-.034 1.881-.106.39-.067.534-.16.433-.282-.08-.097-.298-.169-.643-.22-.34-.042-.76-.067-1.268-.067Z"
      fill="#FA9351"
    />
    <path
      d="M98.804 166.822c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.075l-12.129-.075c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.97 8.891 3.566 19.865 3.566 10.975 0 19.866-1.596 19.866-3.566v-3.339l-14.427-.092Z"
      fill="url(#paint10_radial_301_44952)"
    />
    <path
      d="M113.231 166.911c0 1.97-8.896 3.566-19.866 3.566-10.97 0-19.865-1.596-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M112.63 166.911c0 1.845-8.622 3.338-19.256 3.338s-19.257-1.493-19.257-3.338c0-1.844 8.623-3.338 19.257-3.338 10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M112.105 166.91c0 1.663-8.391 3.009-18.744 3.009s-18.744-1.346-18.744-3.009c0-1.659 8.391-3.005 18.744-3.005s18.744 1.346 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M96.733 166.48c-.525 0-.937.021-1.23.068a2.892 2.892 0 0 0-.82.244 7.75 7.75 0 0 1-.798.33 7.318 7.318 0 0 1-1.008.257c-.399.076-.869.135-1.42.182-.554.042-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.32-.768-.531H83.57v-.401l2.495-.14c.374-.181 1.033-.033 1.882-.126.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.36.025-1.78.076-.43.051-.681.122-.753.211-.075.093.026.165.307.215.285.055.722.08 1.298.08.537 0 .974-.025 1.314-.08.336-.05.685-.143 1.033-.274a9.45 9.45 0 0 1 .916-.3c.269-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.026.873-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.155-.071 1.508.118l2.541.173v.401h-2.045c.142.245-.152.448-.912.6-.793.16-2.003.24-3.603.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.299.39-.067.533-.16.432-.278-.08-.097-.298-.169-.642-.224a8.553 8.553 0 0 0-1.26-.076Z"
      fill="url(#paint11_radial_301_44952)"
    />
    <path
      d="M96.733 166.075c-.525 0-.937.021-1.23.067a2.926 2.926 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.309 7.309 0 0 1-1.008.258c-.399.076-.869.135-1.42.181-.554.046-1.209.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.321-.768-.532H83.57l.404-.261h2.137c.374-.182.987-.313 1.84-.406.823-.088 1.81-.135 2.973-.135l-.176.405c-.769 0-1.36.026-1.78.076-.43.051-.681.123-.753.211-.075.093.026.165.307.216.286.055.722.08 1.298.08a8.55 8.55 0 0 0 1.314-.08 5.155 5.155 0 0 0 1.034-.275 10.8 10.8 0 0 1 .915-.303c.269-.076.567-.136.89-.186.32-.047.685-.085 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.881.047 2.62.139.76.097 1.323.241 1.676.431h1.97l.407.261h-2.045c.143.245-.151.448-.911.6-.794.16-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.39-.067.534-.16.433-.283-.08-.097-.298-.168-.643-.219-.34-.038-.76-.063-1.268-.063Z"
      fill="#FA9351"
    />
    <path
      d="M101.476 162.231c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.971 8.891 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint12_radial_301_44952)"
    />
    <path
      d="M115.902 162.324c0 1.971-8.895 3.566-19.865 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.89-3.566 19.865-3.566 10.974 0 19.865 1.595 19.865 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M115.302 162.324c0 1.845-8.622 3.339-19.257 3.339-10.634 0-19.256-1.494-19.256-3.339 0-1.844 8.622-3.338 19.257-3.338 10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M114.785 162.323c0 1.663-8.391 3.009-18.744 3.009s-18.744-1.346-18.744-3.009c0-1.658 8.391-3.005 18.744-3.005 10.353-.004 18.744 1.342 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M99.404 161.89c-.525 0-.936.021-1.23.068a2.86 2.86 0 0 0-.819.245 8.417 8.417 0 0 1-.798.329 7.321 7.321 0 0 1-1.008.257c-.399.076-.87.135-1.42.182-.554.042-1.21.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.646-.14-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.374-.182 1.033-.034 1.881-.127.824-.089 1.81-.536 2.974-.536l.017.485-.193.321c-.769 0-1.361.025-1.781.076-.429.051-.68.122-.752.211-.075.093.025.165.307.215.285.055.722.08 1.297.08a8.65 8.65 0 0 0 1.32-.08c.335-.05.68-.143 1.032-.274.336-.127.643-.228.92-.3a7.53 7.53 0 0 1 .89-.185c.32-.047.685-.085 1.088-.11.404-.025.874-.038 1.42-.038 1.016 0 1.886.046 2.621.143.76.098 1.154-.071 1.507.119l2.541.173v.401h-2.045c.143.244-.151.447-.912.599-.793.16-2.003.24-3.603.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.299.39-.067.533-.16.433-.278-.08-.097-.299-.169-.643-.224-.34-.05-.76-.076-1.269-.076Z"
      fill="url(#paint13_radial_301_44952)"
    />
    <path
      d="M99.404 161.488c-.525 0-.936.021-1.23.067a2.926 2.926 0 0 0-.819.245 8.417 8.417 0 0 1-.798.329 7.309 7.309 0 0 1-1.008.258c-.399.076-.87.135-1.42.181a26.17 26.17 0 0 1-1.96.068c-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.321-.768-.532h-2.197l.403-.261h2.138c.374-.182.987-.313 1.84-.406.823-.088 1.81-.135 2.973-.135l-.176.406c-.769 0-1.361.025-1.78.076-.43.05-.681.122-.753.211-.075.092.025.164.307.215.285.055.722.08 1.297.08a8.65 8.65 0 0 0 1.32-.08c.335-.051.68-.144 1.032-.275.337-.126.643-.227.92-.303a7.73 7.73 0 0 1 .89-.186c.32-.047.685-.084 1.088-.11a23.21 23.21 0 0 1 1.42-.038c1.016 0 1.886.047 2.621.139.76.098 1.319.241 1.676.431h1.969l.408.262h-2.046c.143.244-.151.447-.911.599-.794.16-2.003.24-3.603.24l-.265-.586c.852 0 1.482-.034 1.881-.106.391-.067.534-.16.433-.282-.08-.098-.298-.169-.643-.22a11.781 11.781 0 0 0-1.276-.063Z"
      fill="#FA9351"
    />
    <path
      d="M98.804 156.997c-1.73-.088-3.553-.135-5.439-.135-1.407 0-2.776.026-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.891 3.566 19.865 3.566 10.975 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint14_radial_301_44952)"
    />
    <path
      d="M113.231 157.087c0 1.97-8.896 3.566-19.866 3.566-10.97 0-19.865-1.596-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M112.63 157.087c0 1.844-8.622 3.338-19.256 3.338s-19.257-1.494-19.257-3.338 8.623-3.338 19.257-3.338c10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M112.105 157.086c0 1.662-8.391 3.009-18.744 3.009s-18.744-1.347-18.744-3.009c0-1.663 8.391-3.005 18.744-3.005s18.744 1.346 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M96.733 156.656c-.525 0-.937.021-1.23.067a2.926 2.926 0 0 0-.82.245c-.256.123-.52.232-.798.329a7.309 7.309 0 0 1-1.008.258c-.399.076-.869.135-1.42.181-.554.043-1.209.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.321-.768-.532H83.57v-.4l2.495-.14c.374-.181 1.033-.033 1.882-.126.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.36.025-1.78.076-.43.05-.681.122-.753.211-.075.093.026.164.307.215.285.055.722.08 1.298.08.537 0 .974-.025 1.314-.08a5.218 5.218 0 0 0 1.033-.274 9.45 9.45 0 0 1 .916-.3c.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.109.403-.026.873-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.155-.072 1.508.118l2.541.173v.401h-2.045c.142.245-.152.448-.912.599-.793.157-2.003.241-3.603.241l-.265-.587c.013-.198-.042-.189 0-.401.647-.008 1.55.355 1.882.3.39-.067.533-.16.432-.278-.08-.097-.298-.169-.642-.224a8.553 8.553 0 0 0-1.26-.076Z"
      fill="url(#paint15_radial_301_44952)"
    />
    <path
      d="M96.733 156.251c-.525 0-.937.022-1.23.068a2.893 2.893 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.177 7.177 0 0 1-1.008.257c-.399.076-.869.135-1.42.182-.554.042-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.646-.14-.89-.321-.768-.532H83.57l.404-.262h2.137c.374-.177.987-.312 1.84-.405.823-.088 1.81-.135 2.973-.135l-.176.405c-.769 0-1.36.026-1.78.076-.43.051-.681.123-.753.211-.075.093.026.165.307.215.286.055.722.081 1.298.081.538 0 .974-.026 1.314-.081.336-.05.685-.143 1.034-.274.335-.126.642-.228.915-.304.269-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.323.241 1.676.431h1.97l.407.261h-2.045c.143.245-.151.448-.911.6-.794.16-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.39-.067.534-.16.433-.283-.08-.097-.298-.168-.643-.219-.34-.042-.76-.068-1.268-.068Z"
      fill="#FA9351"
    />
    <path
      d="M101.476 152.593c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.891 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint16_radial_301_44952)"
    />
    <path
      d="M115.902 152.686c0 1.971-8.895 3.566-19.865 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.89-3.566 19.865-3.566 10.974 0 19.865 1.595 19.865 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M115.302 152.687c0 1.844-8.622 3.338-19.257 3.338-10.634 0-19.256-1.494-19.256-3.338s8.622-3.338 19.257-3.338c10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M114.785 152.686c0 1.658-8.391 3.009-18.744 3.009s-18.744-1.347-18.744-3.009c0-1.663 8.391-3.009 18.744-3.009s18.744 1.346 18.744 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M99.404 152.252c-.525 0-.936.021-1.23.067a2.926 2.926 0 0 0-.819.245 8.417 8.417 0 0 1-.798.329 7.312 7.312 0 0 1-1.008.258c-.399.076-.87.135-1.42.181a26.17 26.17 0 0 1-1.96.068c-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.374-.181 1.033-.034 1.881-.127.824-.088 1.81-.536 2.974-.536l.017.486-.193.32c-.769 0-1.361.026-1.781.076-.429.051-.68.123-.752.211-.075.093.025.165.307.216.285.054.722.08 1.297.08.538 0 .975-.026 1.32-.08.335-.051.68-.144 1.032-.275.336-.126.643-.228.92-.304.269-.075.567-.135.89-.185.32-.047.685-.085 1.088-.11.404-.025.874-.038 1.42-.038 1.016 0 1.886.047 2.621.144.76.097 1.154-.072 1.507.118l2.541.173v.401h-2.045c.143.244-.151.447-.912.599-.793.156-2.003.24-3.603.24l-.265-.586c.013-.198-.042-.19 0-.401.647-.008 1.55.354 1.882.3.39-.068.533-.161.433-.283-.08-.097-.299-.169-.643-.22a9.643 9.643 0 0 0-1.269-.071Z"
      fill="url(#paint17_radial_301_44952)"
    />
    <path
      d="M99.404 151.85c-.525 0-.936.021-1.23.068a2.892 2.892 0 0 0-.819.244 8.423 8.423 0 0 1-.798.33 7.318 7.318 0 0 1-1.008.257c-.399.076-.87.135-1.42.181-.554.047-1.21.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.646-.143-.89-.32-.768-.531h-2.197l.403-.262h2.138c.374-.177.987-.312 1.84-.405.823-.089 1.81-.135 2.973-.135l-.176.405c-.769 0-1.361.025-1.78.076-.43.05-.681.122-.753.211-.075.093.025.164.307.215.285.055.722.08 1.297.08a8.65 8.65 0 0 0 1.32-.08c.335-.051.68-.143 1.032-.274.337-.127.643-.228.92-.304a7.73 7.73 0 0 1 .89-.186c.32-.046.685-.084 1.088-.109.404-.026.874-.038 1.42-.038 1.016 0 1.886.046 2.621.143.76.097 1.319.241 1.676.43h1.969l.408.262h-2.046c.143.245-.151.447-.911.599-.794.161-2.003.241-3.603.241l-.265-.587c.852 0 1.482-.033 1.881-.105.391-.068.534-.161.433-.283-.08-.097-.298-.169-.643-.224a11.781 11.781 0 0 0-1.276-.063Z"
      fill="#FA9351"
    />
    <path
      d="M98.804 147.36c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.97 8.891 3.566 19.865 3.566 10.975 0 19.866-1.596 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint18_radial_301_44952)"
    />
    <path
      d="M113.231 147.449c0 1.971-8.896 3.566-19.866 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M93.366 150.788c10.635 0 19.256-1.495 19.256-3.339 0-1.843-8.621-3.338-19.256-3.338s-19.257 1.495-19.257 3.338c0 1.844 8.622 3.339 19.257 3.339Z"
      fill="#FFC148"
    />
    <path
      d="M93.361 150.457c10.352 0 18.744-1.347 18.744-3.009 0-1.661-8.392-3.009-18.744-3.009s-18.744 1.348-18.744 3.009c0 1.662 8.392 3.009 18.744 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M96.733 147.017c-.525 0-.937.021-1.23.068a2.86 2.86 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.318 7.318 0 0 1-1.008.257c-.399.076-.869.135-1.42.182-.554.046-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.646-.14-.89-.321-.768-.532H83.57v-.401l2.495-.139c.374-.182 1.033-.034 1.882-.127.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.36.025-1.78.076-.43.051-.681.122-.753.211-.075.093.026.165.307.215.285.055.722.08 1.298.08.537 0 .974-.025 1.314-.08.336-.05.685-.143 1.033-.274.336-.127.643-.228.916-.304.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.109.403-.026.873-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.155-.072 1.508.118l2.541.173v.401h-2.045c.142.245-.152.448-.912.6-.793.16-2.003.24-3.603.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.299.39-.067.533-.16.432-.283-.08-.097-.298-.168-.642-.219a9.393 9.393 0 0 0-1.26-.072Z"
      fill="url(#paint19_radial_301_44952)"
    />
    <path
      d="M96.733 146.613c-.525 0-.937.021-1.23.067a2.926 2.926 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.309 7.309 0 0 1-1.008.258c-.399.076-.869.135-1.42.181-.554.047-1.209.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.646-.143-.89-.321-.768-.532H83.57l.404-.261h2.137c.374-.178.987-.313 1.84-.406.823-.088 1.81-.135 2.973-.135l-.176.406c-.769 0-1.36.025-1.78.076-.43.05-.681.122-.753.211-.075.092.026.164.307.215.286.055.722.08 1.298.08a8.55 8.55 0 0 0 1.314-.08 5.155 5.155 0 0 0 1.034-.275c.335-.126.642-.227.915-.303a7.73 7.73 0 0 1 .89-.186c.32-.047.685-.084 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.881.047 2.62.144.76.097 1.323.24 1.676.43h1.97l.407.262h-2.045c.143.245-.151.447-.911.599-.794.156-2.004.241-3.604.241l-.264-.587c.852 0 1.482-.034 1.881-.106.39-.067.534-.16.433-.282-.08-.097-.298-.169-.643-.22-.34-.042-.76-.067-1.268-.067Z"
      fill="#FA9351"
    />
    <path
      d="M102.593 143.087c-1.73-.088-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint20_radial_301_44952)"
    />
    <path
      d="M117.02 143.176c0 1.971-8.896 3.566-19.866 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.97 8.895-3.566 19.865-3.566 10.97 0 19.866 1.596 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M116.419 143.177c0 1.844-8.622 3.338-19.256 3.338s-19.257-1.494-19.257-3.338 8.623-3.338 19.257-3.338c10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M115.902 143.176c0 1.662-8.391 3.009-18.744 3.009s-18.744-1.347-18.744-3.009c0-1.659 8.391-3.005 18.744-3.005s18.744 1.346 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M100.522 142.746c-.525 0-.937.021-1.23.067a2.968 2.968 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.31 7.31 0 0 1-1.008.258c-.399.076-.869.135-1.42.181-.553.047-1.209.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.642-.139-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.374-.181 1.033-.034 1.882-.126.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.36.025-1.78.076-.43.05-.681.122-.753.211-.075.092.025.164.307.215.285.055.722.08 1.298.08a8.55 8.55 0 0 0 1.314-.08 5.23 5.23 0 0 0 1.033-.275c.336-.126.643-.227.916-.303.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.885.047 2.62.14.761.097 1.155-.072 1.508.118l2.541.173v.401h-2.045c.143.244-.151.447-.912.599-.793.16-2.003.24-3.603.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.3.39-.068.533-.161.432-.279-.079-.097-.298-.169-.642-.224a10.247 10.247 0 0 0-1.26-.067Z"
      fill="url(#paint21_radial_301_44952)"
    />
    <path
      d="M100.522 142.341c-.525 0-.937.021-1.23.068a2.902 2.902 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.321 7.321 0 0 1-1.008.257c-.399.076-.869.135-1.42.182-.553.042-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.642-.14-.89-.321-.768-.532h-2.197l.404-.262H89.9c.374-.181.987-.312 1.84-.405.823-.089 1.81-.135 2.974-.135l-.177.405c-.769 0-1.36.025-1.78.076-.43.051-.681.122-.753.211-.075.093.026.165.307.215.286.055.723.08 1.298.08a8.55 8.55 0 0 0 1.314-.08c.336-.05.68-.143 1.034-.274.336-.127.642-.228.915-.304.269-.072.567-.135.89-.186.32-.046.685-.084 1.088-.109.404-.026.874-.038 1.42-.038 1.016 0 1.886.046 2.62.143.761.097 1.324.241 1.676.431h1.97l.408.261h-2.046c.143.245-.151.448-.911.6-.794.16-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.391-.068.534-.16.433-.283-.08-.097-.298-.169-.643-.219-.34-.042-.76-.068-1.268-.068Z"
      fill="#FA9351"
    />
    <path
      d="M105.265 138.496a107.4 107.4 0 0 0-5.439-.135c-1.407 0-2.776.026-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint22_radial_301_44952)"
    />
    <path
      d="M119.692 138.589c0 1.971-8.892 3.566-19.866 3.566s-19.865-1.595-19.865-3.566c0-1.97 8.895-3.566 19.865-3.566 10.97 0 19.866 1.596 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M99.834 141.928c10.635 0 19.257-1.494 19.257-3.338s-8.622-3.338-19.257-3.338c-10.635 0-19.256 1.494-19.256 3.338s8.621 3.338 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M118.574 138.589c0 1.662-8.391 3.009-18.744 3.009s-18.744-1.347-18.744-3.009c0-1.659 8.391-3.005 18.744-3.005 10.353-.004 18.744 1.342 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M103.201 138.154c-.525 0-.936.021-1.23.067a2.92 2.92 0 0 0-.819.245 7.986 7.986 0 0 1-.798.329 7.91 7.91 0 0 1-1.008.262c-.4.076-.87.135-1.42.181-.554.047-1.21.068-1.961.068-1.294 0-2.289-.076-2.961-.224-.643-.139-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.373-.181 1.033-.034 1.881-.126.823-.089 1.81-.536 2.974-.536l.017.485-.194.321c-.768 0-1.36.025-1.78.076-.429.05-.68.122-.752.211-.076.093.025.164.306.215.286.055.723.08 1.298.08.533 0 .974-.025 1.319-.08.336-.051.68-.144 1.033-.274.336-.127.643-.228.92-.304.269-.076.567-.135.89-.186.319-.046.685-.084 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.885.047 2.62.14.76.097 1.155-.072 1.508.118l2.541.173v.401h-2.045c.142.244-.152.447-.912.599-.793.16-2.003.241-3.603.241l-.265-.587c.013-.198-.042-.19 0-.401.647-.008 1.55.355 1.882.3.39-.068.533-.161.432-.279-.08-.097-.298-.169-.642-.224a9.697 9.697 0 0 0-1.269-.071Z"
      fill="url(#paint23_radial_301_44952)"
    />
    <path
      d="M103.201 137.755c-.525 0-.936.021-1.23.068a2.856 2.856 0 0 0-.819.245 7.986 7.986 0 0 1-.798.329 7.196 7.196 0 0 1-1.008.257c-.4.076-.87.135-1.42.182-.554.042-1.21.067-1.961.067-1.294 0-2.289-.076-2.961-.223-.643-.14-.89-.321-.768-.532h-2.197l.403-.262h2.138c.374-.181.987-.312 1.84-.405.823-.089 1.81-.135 2.973-.135l-.176.405c-.769 0-1.361.025-1.781.076-.429.051-.68.122-.752.211-.076.093.025.165.307.215.285.055.722.081 1.297.081.534 0 .975-.026 1.32-.081.335-.05.68-.143 1.032-.274.336-.127.643-.228.92-.304.269-.072.567-.135.891-.186.319-.046.684-.084 1.087-.109.403-.026.874-.038 1.42-.038 1.016 0 1.886.046 2.621.143.76.097 1.322.241 1.675.431h1.97l.407.261h-2.045c.143.245-.151.448-.911.6-.794.156-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.391-.067.533-.16.433-.283-.08-.097-.298-.168-.643-.219a10.773 10.773 0 0 0-1.277-.068Z"
      fill="#FA9351"
    />
    <path
      d="M102.593 133.263c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint24_radial_301_44952)"
    />
    <path
      d="M117.02 133.352c0 1.971-8.896 3.566-19.866 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M116.419 133.353c0 1.844-8.622 3.338-19.256 3.338s-19.257-1.494-19.257-3.338 8.623-3.338 19.257-3.338c10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M115.902 133.352c0 1.662-8.391 3.009-18.744 3.009s-18.744-1.347-18.744-3.009c0-1.663 8.391-3.009 18.744-3.009 10.353.004 18.744 1.35 18.744 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M100.522 132.923c-.525 0-.937.021-1.23.067a2.968 2.968 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.178 7.178 0 0 1-1.008.257c-.399.076-.869.135-1.42.182-.553.042-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.642-.139-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.374-.182 1.033-.034 1.882-.127.823-.088 1.81-.536 2.973-.536l.017.486-.193.32c-.769 0-1.36.026-1.78.076-.43.051-.681.123-.753.211-.075.093.025.165.307.216.285.054.722.08 1.298.08a8.68 8.68 0 0 0 1.314-.08 5.23 5.23 0 0 0 1.033-.275c.336-.126.643-.228.916-.304.269-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.885.046 2.62.143.761.098 1.155-.071 1.508.119l2.541.173v.401h-2.045c.143.244-.151.447-.912.599-.793.16-2.003.24-3.603.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.299.39-.067.533-.16.432-.278-.079-.097-.298-.169-.642-.224a9.42 9.42 0 0 0-1.26-.071Z"
      fill="url(#paint25_radial_301_44952)"
    />
    <path
      d="M100.522 132.517c-.525 0-.937.021-1.23.068a2.934 2.934 0 0 0-.82.244c-.256.123-.52.232-.798.33a7.321 7.321 0 0 1-1.008.257c-.399.076-.869.135-1.42.181-.553.043-1.209.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.642-.139-.89-.32-.768-.531h-2.197l.404-.262H89.9c.374-.177.987-.312 1.84-.405.823-.089 1.81-.135 2.974-.135l-.177.405c-.769 0-1.36.025-1.78.076-.43.05-.681.122-.753.211-.075.093.026.164.307.215.286.055.723.08 1.298.08a8.55 8.55 0 0 0 1.314-.08c.336-.051.68-.143 1.034-.274.336-.127.642-.228.915-.304.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.109.404-.026.874-.038 1.42-.038 1.016 0 1.886.046 2.62.139.761.097 1.324.24 1.676.43h1.97l.408.262h-2.046c.143.245-.151.447-.911.599-.794.161-2.004.241-3.604.241l-.264-.587c.852 0 1.482-.034 1.881-.105.391-.068.534-.161.433-.283-.08-.097-.298-.169-.643-.22-.34-.038-.76-.063-1.268-.063Z"
      fill="#FA9351"
    />
    <path
      d="M76.015 190.181c-1.73-.089-3.553-.135-5.439-.135-1.402 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint26_radial_301_44952)"
    />
    <path
      d="M90.442 190.274c0 1.971-8.896 3.566-19.866 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M89.833 190.275c0 1.844-8.622 3.338-19.256 3.338s-19.257-1.494-19.257-3.338c0-1.845 8.623-3.338 19.257-3.338 10.634-.005 19.256 1.493 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M89.316 190.274c0 1.662-8.391 3.009-18.744 3.009s-18.744-1.347-18.744-3.009c0-1.663 8.391-3.009 18.744-3.009s18.744 1.346 18.744 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M73.952 189.84c-.525 0-.937.021-1.231.067a2.968 2.968 0 0 0-.82.245c-.255.122-.52.232-.797.329a7.178 7.178 0 0 1-1.008.257c-.399.076-.87.135-1.42.182-.554.042-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.647-.14-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.373-.182 1.033-.034 1.881-.127.824-.088 1.81-.536 2.974-.536l.017.486-.194.32c-.768 0-1.36.026-1.78.076-.429.051-.68.123-.752.211-.076.093.025.165.307.216.285.054.722.08 1.297.08a8.68 8.68 0 0 0 1.315-.08 5.23 5.23 0 0 0 1.033-.275c.336-.126.643-.228.916-.299.268-.076.567-.135.89-.186a13.1 13.1 0 0 1 1.088-.11c.403-.025.873-.038 1.42-.038 1.016 0 1.885.047 2.62.139.76.098 1.155-.071 1.508.119l2.54.173v.401H78.32c.143.244-.15.447-.91.599-.795.156-2.004.24-3.604.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.881.299.391-.067.534-.16.433-.278-.08-.097-.298-.169-.642-.224a9.457 9.457 0 0 0-1.26-.071Z"
      fill="url(#paint27_radial_301_44952)"
    />
    <path
      d="M73.952 189.439c-.525 0-.937.021-1.231.067a2.968 2.968 0 0 0-.82.245c-.255.123-.52.232-.797.329a7.31 7.31 0 0 1-1.008.258c-.399.076-.87.135-1.42.181-.554.047-1.209.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.647-.139-.89-.321-.768-.532h-2.197l.403-.261h2.138c.374-.177.987-.312 1.84-.405.823-.089 1.81-.135 2.973-.135l-.176.405c-.769 0-1.361.025-1.781.076-.429.05-.68.122-.752.211-.075.093.025.164.307.215.285.055.722.08 1.298.08a8.55 8.55 0 0 0 1.314-.08c.336-.051.68-.144 1.033-.274.336-.127.643-.228.916-.304.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.11a23.2 23.2 0 0 1 1.42-.038c1.016 0 1.885.047 2.62.144.76.097 1.323.24 1.676.43h1.97l.407.262h-2.045c.143.245-.151.447-.912.599-.793.156-2.003.241-3.603.241l-.265-.587c.853 0 1.483-.034 1.882-.105.39-.068.533-.161.432-.283-.08-.097-.298-.169-.642-.22-.34-.042-.764-.067-1.268-.067Z"
      fill="#FA9351"
    />
    <path
      d="M74.89 184.943c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.13-.076c-.21-.106-3.594 0-3.594 0l-.042 3.566c0 1.97 8.895 3.566 19.865 3.566 10.97 0 19.866-1.596 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint28_radial_301_44952)"
    />
    <path
      d="M89.317 185.036c0 1.971-8.896 3.566-19.866 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.975 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M88.716 185.036c0 1.845-8.622 3.338-19.256 3.338s-19.257-1.493-19.257-3.338c0-1.844 8.622-3.338 19.257-3.338 10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M88.2 185.035c0 1.663-8.392 3.009-18.745 3.009-10.353 0-18.744-1.346-18.744-3.009 0-1.662 8.391-3.009 18.744-3.009s18.744 1.347 18.744 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M72.823 184.601c-.525 0-.937.021-1.23.067a2.969 2.969 0 0 0-.82.245 8.172 8.172 0 0 1-.798.329 7.786 7.786 0 0 1-1.012.262c-.399.076-.87.135-1.42.182-.554.042-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.32-.768-.531h-2.197v-.401l2.495-.14c.374-.181 1.033-.033 1.881-.126.824-.089 1.81-.536 2.974-.536l.017.485-.193.321c-.769 0-1.361.025-1.781.076-.429.051-.68.122-.752.211-.076.093.025.165.307.215.285.055.722.08 1.297.08.538 0 .975-.025 1.315-.08.336-.05.685-.143 1.033-.274.336-.127.643-.224.916-.3.268-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.025.873-.038 1.42-.038 1.016 0 1.88.046 2.62.143.76.097 1.155-.071 1.508.118l2.54.174v.4h-2.045c.143.245-.15.448-.91.6-.795.156-2.004.24-3.605.24l-.264-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.299.39-.067.533-.16.432-.278-.08-.097-.298-.169-.642-.224a7.874 7.874 0 0 0-1.256-.08Z"
      fill="url(#paint29_radial_301_44952)"
    />
    <path
      d="M72.823 184.201c-.525 0-.937.021-1.23.067a2.969 2.969 0 0 0-.82.245 8.428 8.428 0 0 1-.798.329 7.228 7.228 0 0 1-1.012.258c-.399.076-.87.135-1.42.181a26.16 26.16 0 0 1-1.96.068c-1.294 0-2.29-.076-2.962-.224-.646-.139-.89-.321-.768-.532h-2.197l.403-.262h2.138c.374-.177.987-.312 1.84-.405.823-.088 1.81-.135 2.973-.135l-.176.405c-.769 0-1.36.026-1.78.076-.43.051-.681.123-.753.211-.075.093.025.165.307.216.285.055.722.08 1.298.08a8.55 8.55 0 0 0 1.314-.08 5.156 5.156 0 0 0 1.033-.275c.336-.126.643-.228.916-.304.269-.075.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.881.047 2.62.144.76.097 1.319.24 1.676.43h1.97l.407.262h-2.045c.143.244-.151.447-.911.599-.794.156-2.004.24-3.604.24l-.265-.586c.853 0 1.483-.034 1.882-.106.39-.067.533-.16.432-.282-.08-.097-.298-.169-.642-.22a10.363 10.363 0 0 0-1.264-.067Z"
      fill="#FA9351"
    />
    <path
      d="M74.289 180.039c-1.73-.088-3.554-.135-5.44-.135-1.406 0-2.776.026-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.896 3.566 19.866 3.566 10.97 0 19.865-1.595 19.865-3.566v-3.338l-14.426-.093Z"
      fill="url(#paint30_radial_301_44952)"
    />
    <path
      d="M88.715 180.132c0 1.971-8.895 3.566-19.865 3.566-10.97 0-19.866-1.595-19.866-3.566 0-1.97 8.891-3.566 19.866-3.566 10.97 0 19.865 1.596 19.865 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M88.114 180.133c0 1.844-8.622 3.338-19.256 3.338s-19.256-1.494-19.256-3.338 8.622-3.338 19.256-3.338c10.634-.004 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M87.59 180.132c0 1.662-8.392 3.009-18.745 3.009-10.352 0-18.743-1.347-18.743-3.009 0-1.663 8.39-3.005 18.744-3.005 10.352-.004 18.743 1.342 18.743 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M72.225 179.698c-.525 0-.937.021-1.23.067a2.968 2.968 0 0 0-.82.245c-.256.123-.52.232-.798.329a7.309 7.309 0 0 1-1.007.258c-.4.076-.87.135-1.42.181-.554.043-1.21.068-1.962.068-1.293 0-2.288-.076-2.96-.224-.647-.139-.89-.321-.769-.532h-2.197v-.4l2.495-.14c.374-.181 1.033-.033 1.882-.126.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.36.025-1.785.076-.428.05-.68.122-.752.211-.075.093.025.164.307.215.285.055.722.08 1.297.08.534 0 .975-.025 1.32-.08.335-.051.68-.144 1.032-.274.336-.127.643-.228.92-.3.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.109.404-.026.874-.038 1.42-.038 1.016 0 1.886.046 2.62.143.76.097 1.156-.072 1.508.118l2.541.173v.401h-2.045c.143.245-.151.448-.911.599-.794.161-2.004.241-3.604.241l-.264-.587c.012-.198-.042-.19 0-.401.646-.008 1.55.355 1.881.3.39-.067.533-.16.433-.283-.08-.097-.299-.168-.643-.223a9.341 9.341 0 0 0-1.264-.072Z"
      fill="url(#paint31_radial_301_44952)"
    />
    <path
      d="M72.225 179.297c-.525 0-.937.021-1.23.068a2.902 2.902 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.318 7.318 0 0 1-1.007.257c-.4.076-.87.135-1.42.182-.554.046-1.21.067-1.962.067-1.293 0-2.288-.076-2.96-.223-.647-.14-.89-.321-.769-.532h-2.197l.404-.262h2.137c.374-.181.987-.312 1.84-.405.823-.089 1.81-.135 2.974-.135l-.177.405c-.769 0-1.36.025-1.785.076-.428.051-.68.122-.752.211-.075.093.026.165.307.215.286.055.722.081 1.298.081a8.67 8.67 0 0 0 1.319-.081c.336-.05.68-.143 1.033-.274.336-.127.642-.228.92-.304.268-.076.567-.135.89-.186.32-.046.684-.084 1.088-.109.403-.026.873-.038 1.42-.038 1.016 0 1.885.046 2.62.143.76.097 1.323.241 1.676.431h1.97l.402.261h-2.045c.143.245-.151.448-.911.6-.794.16-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.39-.067.534-.16.433-.283-.08-.097-.298-.168-.643-.219-.34-.042-.76-.068-1.268-.068Z"
      fill="#FA9351"
    />
    <path
      d="M76.968 175.452c-1.73-.088-3.553-.135-5.439-.135-1.407 0-2.776.026-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint32_radial_301_44952)"
    />
    <path
      d="M91.395 175.542c0 1.97-8.896 3.566-19.866 3.566-10.97 0-19.865-1.596-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.599 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M90.786 175.542c0 1.844-8.622 3.338-19.256 3.338s-19.257-1.494-19.257-3.338 8.623-3.338 19.257-3.338c10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M90.27 175.541c0 1.663-8.392 3.009-18.745 3.009-10.353 0-18.744-1.346-18.744-3.009 0-1.663 8.392-3.005 18.744-3.005 10.353 0 18.744 1.346 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M74.897 175.111c-.525 0-.937.021-1.23.068a2.934 2.934 0 0 0-.82.244c-.256.123-.52.232-.798.33a7.32 7.32 0 0 1-1.008.257c-.399.076-.87.135-1.42.181-.554.043-1.209.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.642-.139-.89-.32-.768-.531h-2.197v-.401l2.495-.14c.374-.181 1.033-.033 1.882-.126.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.361.025-1.781.076-.428.05-.68.122-.752.211-.075.093.025.164.307.215.285.055.722.08 1.298.08.537 0 .974-.025 1.318-.08.336-.051.68-.143 1.033-.274a9.45 9.45 0 0 1 .916-.3c.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.109.403-.026.874-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.155-.072 1.508.118l2.541.173v.401H79.27c.143.245-.151.448-.912.6-.793.16-2.003.24-3.603.24l-.265-.587c.013-.198-.042-.189 0-.4.647-.009 1.55.354 1.882.299.39-.067.533-.16.433-.283-.08-.097-.299-.168-.643-.223a9.298 9.298 0 0 0-1.264-.072Z"
      fill="url(#paint33_radial_301_44952)"
    />
    <path
      d="M74.897 174.707c-.525 0-.937.021-1.23.067a2.935 2.935 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.178 7.178 0 0 1-1.008.257c-.399.076-.87.135-1.42.182-.554.042-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.642-.14-.89-.321-.768-.532h-2.197l.404-.262h2.137c.374-.181.987-.312 1.84-.405.823-.088 1.81-.135 2.973-.135l-.176.405c-.769 0-1.36.026-1.78.076-.43.051-.681.123-.753.211-.075.093.026.165.307.216.286.054.722.08 1.298.08a8.78 8.78 0 0 0 1.319-.08 5.23 5.23 0 0 0 1.033-.275c.336-.126.642-.228.915-.304.27-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.404-.025.874-.038 1.42-.038 1.016 0 1.881.046 2.62.139.76.097 1.324.241 1.676.431h1.97l.408.261h-2.046c.143.245-.15.448-.911.6-.794.16-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.391-.067.534-.16.433-.283-.08-.097-.298-.169-.643-.219-.34-.034-.764-.063-1.272-.063Z"
      fill="#FA9351"
    />
    <path
      d="M74.289 170.216c-1.73-.089-3.554-.135-5.44-.135-1.406 0-2.776.025-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.896 3.566 19.866 3.566 10.97 0 19.865-1.595 19.865-3.566v-3.338l-14.426-.093Z"
      fill="url(#paint34_radial_301_44952)"
    />
    <path
      d="M88.715 170.309c0 1.971-8.895 3.566-19.865 3.566-10.97 0-19.866-1.595-19.866-3.566 0-1.971 8.891-3.566 19.866-3.566 10.97 0 19.865 1.595 19.865 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M88.114 170.31c0 1.844-8.622 3.338-19.256 3.338s-19.256-1.494-19.256-3.338 8.622-3.338 19.256-3.338c10.634-.005 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M87.59 170.308c0 1.663-8.392 3.009-18.745 3.009-10.352 0-18.743-1.346-18.743-3.009 0-1.662 8.39-3.004 18.744-3.004 10.352-.004 18.743 1.342 18.743 3.004Z"
      fill="#FBE034"
    />
    <path
      d="M72.225 169.874c-.525 0-.937.021-1.23.067a2.968 2.968 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.309 7.309 0 0 1-1.007.258c-.4.076-.87.135-1.42.181-.554.042-1.21.068-1.962.068-1.293 0-2.288-.076-2.96-.224-.647-.139-.89-.321-.769-.532h-2.197v-.401l2.495-.139c.374-.181 1.033-.034 1.882-.127.823-.088 1.81-.536 2.973-.536l.017.486-.193.321c-.769 0-1.36.025-1.785.075-.428.051-.68.123-.752.211-.075.093.025.165.307.216.285.055.722.08 1.297.08.534 0 .975-.025 1.32-.08a5.23 5.23 0 0 0 1.032-.275c.336-.126.643-.227.92-.303.269-.076.567-.135.89-.186.32-.047.685-.085 1.088-.11a23.21 23.21 0 0 1 1.42-.038c1.016 0 1.886.047 2.62.144.76.097 1.156-.072 1.508.118l2.541.173v.401h-2.045c.143.245-.151.447-.911.599-.794.156-2.004.241-3.604.241l-.264-.587c.012-.198-.042-.19 0-.401.646-.008 1.55.355 1.881.3.39-.068.533-.161.433-.283-.08-.097-.299-.169-.643-.224a10.37 10.37 0 0 0-1.264-.067Z"
      fill="url(#paint35_radial_301_44952)"
    />
    <path
      d="M72.225 169.473c-.525 0-.937.021-1.23.068a2.934 2.934 0 0 0-.82.244c-.256.123-.52.233-.798.33a7.318 7.318 0 0 1-1.007.257c-.4.076-.87.135-1.42.181a23.74 23.74 0 0 1-1.962.068c-1.293 0-2.288-.076-2.96-.224-.647-.139-.89-.32-.769-.531h-2.197l.404-.262h2.137c.374-.182.987-.312 1.84-.405.823-.089 1.81-.135 2.974-.135l-.177.405c-.769 0-1.36.025-1.785.076-.428.051-.68.122-.752.211-.075.093.026.164.307.215.286.055.722.08 1.298.08.533 0 .974-.025 1.319-.08.336-.051.68-.143 1.033-.274.336-.127.642-.228.92-.304.268-.076.567-.135.89-.186.32-.046.684-.084 1.088-.109.403-.026.873-.038 1.42-.038 1.016 0 1.885.046 2.62.143.76.097 1.323.241 1.676.43h1.97l.402.262h-2.045c.143.245-.151.447-.911.599-.794.161-2.004.241-3.604.241l-.264-.587c.852 0 1.482-.033 1.881-.105.39-.068.534-.161.433-.283-.08-.097-.298-.169-.643-.219-.34-.043-.76-.068-1.268-.068Z"
      fill="#FA9351"
    />
    <path
      d="M76.968 165.814c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint36_radial_301_44952)"
    />
    <path
      d="M91.395 165.903c0 1.971-8.896 3.566-19.866 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.599 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M71.53 169.242c10.635 0 19.256-1.495 19.256-3.338 0-1.844-8.621-3.339-19.256-3.339s-19.257 1.495-19.257 3.339c0 1.843 8.622 3.338 19.257 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M90.27 165.902c0 1.663-8.392 3.009-18.745 3.009-10.353 0-18.744-1.346-18.744-3.009 0-1.662 8.392-3.008 18.744-3.008 10.353.004 18.744 1.35 18.744 3.008Z"
      fill="#FBE034"
    />
    <path
      d="M74.897 165.472c-.525 0-.937.021-1.23.068a2.934 2.934 0 0 0-.82.244c-.256.123-.52.232-.798.33a7.9 7.9 0 0 1-1.008.261c-.399.076-.87.135-1.42.182-.554.046-1.209.067-1.96.067-1.294 0-2.29-.076-2.962-.224-.642-.139-.89-.32-.768-.531h-2.197v-.401l2.495-.139c.374-.182 1.033-.034 1.882-.127.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.361.025-1.781.076-.428.051-.68.122-.752.211-.075.093.025.165.307.215.285.055.722.08 1.298.08.537 0 .974-.025 1.318-.08.336-.05.68-.143 1.033-.274.336-.127.643-.228.916-.304.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.109.403-.026.874-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.155-.072 1.508.118l2.541.173v.401H79.27c.143.245-.151.448-.912.6-.793.156-2.003.24-3.603.24l-.265-.587c.013-.198-.042-.189 0-.4.647-.009 1.55.354 1.882.299.39-.067.533-.16.433-.283-.08-.097-.299-.168-.643-.219a8.617 8.617 0 0 0-1.264-.076Z"
      fill="url(#paint37_radial_301_44952)"
    />
    <path
      d="M74.897 165.067c-.525 0-.937.021-1.23.067a2.968 2.968 0 0 0-.82.245c-.256.123-.52.232-.798.329a7.31 7.31 0 0 1-1.008.258c-.399.076-.87.135-1.42.181-.554.047-1.209.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.642-.139-.89-.321-.768-.532h-2.197l.404-.261h2.137c.374-.178.987-.313 1.84-.405a28.12 28.12 0 0 1 2.973-.135l-.176.405c-.769 0-1.36.025-1.78.076-.43.05-.681.122-.753.211-.075.092.026.164.307.215.286.055.722.08 1.298.08.537 0 .974-.025 1.319-.08.335-.051.68-.144 1.033-.274.336-.127.642-.228.915-.304.27-.076.567-.135.89-.186.32-.046.685-.084 1.088-.11.404-.025.874-.038 1.42-.038 1.016 0 1.881.047 2.62.144.76.097 1.324.24 1.676.43h1.97l.408.262h-2.046c.143.245-.15.447-.911.599-.794.156-2.004.241-3.604.241l-.264-.587c.852 0 1.482-.034 1.881-.105.391-.068.534-.161.433-.283-.08-.097-.298-.169-.643-.22-.34-.038-.764-.067-1.272-.067Z"
      fill="#FA9351"
    />
    <path
      d="M74.289 160.576c-1.73-.088-3.554-.135-5.44-.135-1.406 0-2.776.026-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.896 3.566 19.866 3.566 10.97 0 19.865-1.595 19.865-3.566v-3.338l-14.426-.093Z"
      fill="url(#paint38_radial_301_44952)"
    />
    <path
      d="M88.715 160.67c0 1.97-8.895 3.566-19.865 3.566-10.97 0-19.866-1.596-19.866-3.566 0-1.971 8.891-3.566 19.866-3.566 10.97 0 19.865 1.595 19.865 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M88.114 160.67c0 1.844-8.622 3.338-19.256 3.338s-19.256-1.494-19.256-3.338 8.622-3.338 19.256-3.338c10.634-.004 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M87.59 160.669c0 1.663-8.392 3.009-18.745 3.009-10.352 0-18.743-1.346-18.743-3.009 0-1.658 8.39-3.009 18.744-3.009 10.352 0 18.743 1.346 18.743 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M72.225 160.236c-.525 0-.937.021-1.23.067a2.968 2.968 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.887 7.887 0 0 1-1.007.262c-.4.076-.87.135-1.42.181a23.74 23.74 0 0 1-1.962.068c-1.293 0-2.288-.076-2.96-.224-.647-.139-.89-.321-.769-.532h-2.197v-.4l2.495-.14c.374-.181 1.033-.034 1.882-.126.823-.089 1.81-.536 2.973-.536l.017.485-.193.321c-.769 0-1.36.025-1.785.076-.428.05-.68.122-.752.211-.075.093.025.164.307.215.285.055.722.08 1.297.08.534 0 .975-.025 1.32-.08.335-.051.68-.144 1.032-.274.336-.127.643-.228.92-.304.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.11a23.21 23.21 0 0 1 1.42-.038c1.016 0 1.886.047 2.62.144.76.097 1.156-.072 1.508.118l2.541.173v.401h-2.045c.143.245-.151.447-.911.599-.794.161-2.004.241-3.604.241l-.264-.587c.012-.198-.042-.19 0-.401.646-.008 1.55.355 1.881.3.39-.068.533-.16.433-.283-.08-.097-.299-.169-.643-.219a8.656 8.656 0 0 0-1.264-.076Z"
      fill="url(#paint39_radial_301_44952)"
    />
    <path
      d="M72.225 159.834c-.525 0-.937.022-1.23.068a2.935 2.935 0 0 0-.82.245c-.256.122-.52.232-.798.329a7.177 7.177 0 0 1-1.007.257c-.4.076-.87.135-1.42.182-.554.042-1.21.067-1.962.067-1.293 0-2.288-.076-2.96-.223-.647-.14-.89-.321-.769-.532h-2.197l.404-.262h2.137c.374-.177.987-.312 1.84-.405.823-.088 1.81-.135 2.974-.135l-.177.405c-.769 0-1.36.026-1.785.076-.428.051-.68.123-.752.211-.075.093.026.165.307.215.286.055.722.081 1.298.081a8.67 8.67 0 0 0 1.319-.081c.336-.05.68-.143 1.033-.274.336-.126.642-.228.92-.304.268-.076.567-.135.89-.185.32-.047.684-.085 1.088-.11.403-.025.873-.038 1.42-.038 1.016 0 1.885.046 2.62.143.76.097 1.323.241 1.676.431h1.97l.402.261h-2.045c.143.245-.151.448-.911.6-.794.156-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.39-.067.534-.16.433-.283-.08-.097-.298-.168-.643-.219-.34-.042-.76-.068-1.268-.068Z"
      fill="#FA9351"
    />
    <path
      opacity={0.2}
      d="M91.394 175.54v3.338c0 .65-.979 1.258-2.671 1.785v2.802c0 .186-.08.367-.227.545.269.16.479.329.622.498.142.173.214.346.214.527v3.338c0 .199-.097.397-.273.587.45.202.794.418 1.029.637.24.215.361.443.361.675v3.338c0 1.971-8.895 3.566-19.865 3.566-4.746 0-9.097-.299-12.512-.797l.021-1.676a.688.688 0 0 1-.038-.206.68.68 0 0 1 .042-.22v-.008h.005c.197-.566 1.13-1.098 2.629-1.562l.03-2.595a.695.695 0 0 1-.039-.207c0-.038.009-.072.017-.105-1.705-.528-2.688-1.14-2.688-1.79l.038-3.131a.72.72 0 0 1-.038-.211.68.68 0 0 1 .042-.22v-.008h.004c.198-.565 1.134-1.093 2.634-1.561l.025-2.406a.718.718 0 0 1-.038-.207c0-.038.009-.071.017-.109-1.705-.528-2.688-1.136-2.688-1.79l.038-3.131a.745.745 0 0 1-.038-.211.67.67 0 0 1 .042-.219v-.009h.004c.651-1.865 9.273-3.338 19.82-3.338 3.985 0 7.693.211 10.801.574v1.604c0 .038-.008.071-.016.109.852.262 1.524.545 1.986.844.466.308.71.625.71.95Z"
      fill="#14142A"
    />
    <path
      d="M87.68 197.003c-1.731-.088-3.554-.135-5.44-.135-1.406 0-2.776.025-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint40_radial_301_44952)"
    />
    <path
      d="M102.106 197.096c0 1.971-8.891 3.566-19.865 3.566-10.97 0-19.866-1.595-19.866-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M82.24 200.435c10.636 0 19.257-1.495 19.257-3.338 0-1.844-8.621-3.338-19.256-3.338s-19.257 1.494-19.257 3.338c0 1.843 8.622 3.338 19.257 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M100.988 197.096c0 1.662-8.391 3.008-18.744 3.008S63.5 198.758 63.5 197.096c0-1.659 8.391-3.005 18.744-3.005s18.744 1.342 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M85.615 196.661c-.524 0-.936.022-1.23.068a2.892 2.892 0 0 0-.819.245c-.256.122-.52.232-.798.329a7.887 7.887 0 0 1-1.008.262c-.399.076-.87.135-1.42.181-.554.046-1.21.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.647-.139-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.374-.181 1.033-.034 1.881-.127.824-.088 1.81-.536 2.974-.536l.017.486-.194.32c-.768 0-1.36.026-1.78.076-.429.051-.68.123-.752.211-.076.093.025.165.306.216.286.055.723.08 1.298.08a8.55 8.55 0 0 0 1.315-.08 5.153 5.153 0 0 0 1.033-.275c.336-.126.643-.227.916-.303.269-.076.566-.136.89-.186.32-.047.685-.085 1.088-.11.403-.025.873-.038 1.42-.038 1.016 0 1.88.047 2.62.144.76.097 1.155-.072 1.508.118l2.54.173v.401h-2.045c.143.245-.15.447-.911.599-.794.16-2.003.241-3.604.241l-.264-.587c.013-.198-.042-.19 0-.401.647-.008 1.55.355 1.881.3.391-.068.534-.161.433-.279-.08-.097-.298-.169-.643-.224a8.775 8.775 0 0 0-1.26-.076Z"
      fill="url(#paint41_radial_301_44952)"
    />
    <path
      d="M85.615 196.26c-.524 0-.936.021-1.23.068a2.86 2.86 0 0 0-.819.245c-.256.122-.52.232-.798.329a7.318 7.318 0 0 1-1.008.257c-.399.076-.87.135-1.42.182-.554.042-1.21.067-1.96.067-1.294 0-2.29-.076-2.962-.224-.647-.139-.89-.32-.768-.531h-2.197l.403-.262h2.138c.374-.181.987-.312 1.84-.405.823-.089 1.81-.135 2.973-.135l-.176.405c-.769 0-1.361.025-1.781.076-.428.051-.68.122-.752.211-.075.093.025.165.307.215.285.055.722.08 1.297.08a8.55 8.55 0 0 0 1.315-.08c.336-.05.685-.143 1.033-.274.336-.127.643-.228.916-.304.269-.072.567-.135.89-.186.32-.046.685-.084 1.088-.109.403-.026.873-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.323.241 1.676.431h1.97l.407.261h-2.045c.142.245-.151.448-.912.6-.793.16-2.003.24-3.603.24l-.265-.587c.853 0 1.483-.033 1.882-.105.39-.068.533-.16.433-.283-.08-.097-.299-.169-.643-.219a10.571 10.571 0 0 0-1.269-.068Z"
      fill="#FA9351"
    />
    <path
      d="M90.351 192.417a107.41 107.41 0 0 0-5.439-.135c-1.407 0-2.776.026-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint42_radial_301_44952)"
    />
    <path
      d="M104.778 192.506c0 1.971-8.896 3.566-19.866 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.97 8.895-3.566 19.865-3.566 10.975 0 19.866 1.6 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M104.177 192.507c0 1.844-8.622 3.338-19.257 3.338-10.634 0-19.256-1.494-19.256-3.338s8.622-3.338 19.257-3.338c10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M103.66 192.506c0 1.662-8.391 3.009-18.744 3.009s-18.744-1.347-18.744-3.009c0-1.663 8.391-3.005 18.744-3.005 10.348 0 18.744 1.346 18.744 3.005Z"
      fill="#FBE034"
    />
    <path
      d="M88.287 192.077c-.525 0-.936.021-1.23.067a2.933 2.933 0 0 0-.82.245 8.417 8.417 0 0 1-.797.329 7.89 7.89 0 0 1-1.008.262c-.4.076-.87.135-1.42.181-.554.042-1.21.068-1.961.068-1.294 0-2.289-.076-2.96-.224-.648-.139-.891-.321-.77-.532h-2.196v-.401l2.495-.139c.373-.181 1.033-.034 1.881-.127.823-.088 1.81-.535 2.974-.535l.017.485-.194.321c-.768 0-1.36.025-1.78.076-.429.05-.68.122-.752.211-.076.092.025.164.306.215.286.055.723.08 1.298.08a8.65 8.65 0 0 0 1.319-.08 5.23 5.23 0 0 0 1.033-.275c.336-.126.643-.227.92-.303.269-.076.567-.135.89-.186.32-.047.685-.085 1.088-.11a23.21 23.21 0 0 1 1.42-.038c1.016 0 1.885.047 2.62.144.76.097 1.155-.072 1.508.118l2.54.173v.401h-2.044c.142.245-.152.447-.912.599-.794.156-2.003.241-3.603.241l-.265-.587c.013-.198-.042-.19 0-.401.647-.008 1.55.355 1.882.3.39-.068.533-.161.432-.279-.08-.097-.298-.169-.642-.223a8.758 8.758 0 0 0-1.269-.076Z"
      fill="url(#paint43_radial_301_44952)"
    />
    <path
      d="M88.287 191.671c-.525 0-.936.021-1.23.068a2.933 2.933 0 0 0-.82.245 8.417 8.417 0 0 1-.797.329 7.18 7.18 0 0 1-1.008.257c-.4.076-.87.135-1.42.182-.554.042-1.21.067-1.961.067-1.294 0-2.289-.076-2.96-.223-.648-.14-.891-.321-.77-.532h-2.196l.403-.262h2.138c.374-.181.987-.312 1.84-.405.823-.089 1.81-.135 2.973-.135l-.177.405c-.768 0-1.36.025-1.78.076-.429.051-.68.123-.752.211-.075.093.025.165.307.215.285.055.722.081 1.297.081.538 0 .975-.026 1.32-.081.335-.05.68-.143 1.032-.274.336-.127.643-.228.92-.304.269-.072.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.026.874-.038 1.42-.038 1.016 0 1.886.046 2.62.143.76.097 1.323.241 1.676.431h1.97l.407.261h-2.045c.143.245-.151.448-.911.6-.794.16-2.004.24-3.604.24l-.264-.586c.852 0 1.482-.034 1.881-.106.39-.067.533-.16.433-.283-.08-.097-.298-.168-.643-.219a10.724 10.724 0 0 0-1.277-.068Z"
      fill="#FA9351"
    />
    <path
      d="M87.68 187.179a106.25 106.25 0 0 0-5.44-.135c-1.406 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint44_radial_301_44952)"
    />
    <path
      d="M102.106 187.272c0 1.971-8.891 3.566-19.865 3.566-10.97 0-19.866-1.595-19.866-3.566 0-1.971 8.895-3.566 19.865-3.566 10.97 0 19.866 1.595 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M101.497 187.273c0 1.844-8.622 3.338-19.256 3.338s-19.257-1.494-19.257-3.338c0-1.845 8.623-3.338 19.257-3.338 10.634-.005 19.256 1.493 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M100.988 187.271c0 1.663-8.391 3.009-18.744 3.009S63.5 188.934 63.5 187.271c0-1.658 8.391-3.004 18.744-3.004s18.744 1.342 18.744 3.004Z"
      fill="#FBE034"
    />
    <path
      d="M85.615 186.837c-.524 0-.936.021-1.23.067a2.925 2.925 0 0 0-.819.245c-.256.122-.52.232-.798.329a7.309 7.309 0 0 1-1.008.258c-.399.075-.87.135-1.42.181-.554.046-1.21.067-1.96.067-1.294 0-2.29-.075-2.962-.223-.647-.139-.89-.321-.768-.532h-2.197v-.401l2.495-.139c.374-.182 1.033-.034 1.881-.127.824-.088 1.81-.536 2.974-.536l.017.486-.194.32c-.768 0-1.36.026-1.78.076-.429.051-.68.123-.752.211-.076.093.025.165.306.216.286.054.723.08 1.298.08a8.68 8.68 0 0 0 1.315-.08 5.153 5.153 0 0 0 1.033-.275c.336-.126.643-.228.916-.299a7.73 7.73 0 0 1 .89-.186c.32-.046.685-.084 1.088-.11.403-.025.873-.038 1.42-.038 1.016 0 1.88.047 2.62.14.76.097 1.155-.072 1.508.118l2.54.173v.401h-2.045c.143.244-.15.447-.911.599-.794.16-2.003.24-3.604.24l-.264-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.881.3.391-.068.534-.161.433-.279-.08-.097-.298-.169-.643-.224a9.505 9.505 0 0 0-1.26-.071Z"
      fill="url(#paint45_radial_301_44952)"
    />
    <path
      d="M85.615 186.436c-.524 0-.936.021-1.23.068a2.891 2.891 0 0 0-.819.244c-.256.123-.52.232-.798.329a7.168 7.168 0 0 1-1.008.258c-.399.076-.87.135-1.42.181-.554.043-1.21.068-1.96.068-1.294 0-2.29-.076-2.962-.224-.647-.139-.89-.321-.768-.532h-2.197l.403-.261h2.138c.374-.177.987-.312 1.84-.405.823-.089 1.81-.135 2.973-.135l-.176.405c-.769 0-1.361.025-1.781.076-.428.05-.68.122-.752.211-.075.093.025.164.307.215.285.055.722.08 1.297.08a8.55 8.55 0 0 0 1.315-.08 5.149 5.149 0 0 0 1.033-.274c.336-.127.643-.228.916-.304.269-.076.567-.135.89-.186.32-.046.685-.084 1.088-.11.403-.025.873-.038 1.42-.038 1.016 0 1.881.047 2.62.144.76.097 1.323.24 1.676.43h1.97l.407.262h-2.045c.142.245-.151.447-.912.599-.793.161-2.003.241-3.603.241l-.265-.587c.853 0 1.483-.034 1.882-.105.39-.068.533-.161.433-.283-.08-.097-.299-.169-.643-.219a10.367 10.367 0 0 0-1.269-.068Z"
      fill="#FA9351"
    />
    <path
      d="M90.351 182.777c-1.73-.089-3.553-.135-5.439-.135-1.407 0-2.776.025-4.099.076l-12.129-.076c-.21-.106-3.595 0-3.595 0l-.042 3.566c0 1.97 8.895 3.566 19.865 3.566 10.97 0 19.866-1.596 19.866-3.566v-3.339l-14.427-.092Z"
      fill="url(#paint46_radial_301_44952)"
    />
    <path
      d="M104.778 182.866c0 1.971-8.896 3.566-19.866 3.566-10.97 0-19.865-1.595-19.865-3.566 0-1.971 8.895-3.566 19.865-3.566 10.975 0 19.866 1.599 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M104.177 182.866c0 1.845-8.622 3.339-19.257 3.339-10.634 0-19.256-1.494-19.256-3.339 0-1.844 8.622-3.338 19.257-3.338 10.634 0 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M103.66 182.865c0 1.663-8.391 3.009-18.744 3.009s-18.744-1.346-18.744-3.009c0-1.658 8.391-3.009 18.744-3.009 10.348.005 18.744 1.351 18.744 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M88.287 182.436c-.525 0-.936.021-1.23.068a2.9 2.9 0 0 0-.82.245 8.417 8.417 0 0 1-.797.329 7.321 7.321 0 0 1-1.008.257c-.4.076-.87.135-1.42.182-.554.042-1.21.067-1.961.067-1.294 0-2.289-.076-2.96-.224-.648-.139-.891-.32-.77-.531h-2.196v-.401l2.495-.139c.373-.182 1.033-.034 1.881-.127.823-.089 1.81-.536 2.974-.536l.017.485-.194.321c-.768 0-1.36.025-1.78.076-.429.051-.68.122-.752.211-.076.093.025.165.306.215.286.055.723.08 1.298.08a8.65 8.65 0 0 0 1.319-.08c.336-.05.68-.143 1.033-.274.336-.127.643-.228.92-.3.269-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11a23.21 23.21 0 0 1 1.42-.038c1.016 0 1.885.046 2.62.143.76.097 1.155-.071 1.508.119l2.54.173v.4h-2.044c.142.245-.152.448-.912.6-.794.16-2.003.24-3.603.24l-.265-.586c.013-.199-.042-.19 0-.401.647-.009 1.55.354 1.882.299.39-.067.533-.16.432-.282-.08-.097-.298-.169-.642-.22-.34-.05-.76-.076-1.269-.076Z"
      fill="url(#paint47_radial_301_44952)"
    />
    <path
      d="M88.287 182.03c-.525 0-.936.021-1.23.067a2.967 2.967 0 0 0-.82.245 8.417 8.417 0 0 1-.797.329 7.312 7.312 0 0 1-1.008.258c-.4.076-.87.135-1.42.181-.554.042-1.21.068-1.961.068-1.294 0-2.289-.076-2.96-.224-.648-.144-.891-.321-.77-.532h-2.196l.403-.261h2.138c.374-.178.987-.313 1.84-.406.823-.088 1.81-.135 2.973-.135l-.177.406c-.768 0-1.36.025-1.78.076-.429.05-.68.122-.752.211-.075.092.025.164.307.215.285.055.722.08 1.297.08a8.65 8.65 0 0 0 1.32-.08 5.23 5.23 0 0 0 1.032-.275c.336-.126.643-.227.92-.303.269-.076.567-.135.89-.186.32-.047.685-.085 1.088-.11.403-.025.874-.038 1.42-.038 1.016 0 1.886.047 2.62.144.76.097 1.323.24 1.676.43h1.97l.407.262h-2.045c.143.245-.151.447-.911.599-.794.16-2.004.241-3.604.241l-.264-.587c.852 0 1.482-.034 1.881-.105.39-.068.533-.161.433-.283-.08-.097-.298-.169-.643-.22a10.75 10.75 0 0 0-1.277-.067Z"
      fill="#FA9351"
    />
    <path
      d="M87.68 177.54c-1.731-.088-3.554-.135-5.44-.135-1.406 0-2.776.026-4.099.076l-12.129-.076c-.21-.105-3.595 0-3.595 0l-.042 3.566c0 1.971 8.895 3.566 19.865 3.566 10.97 0 19.866-1.595 19.866-3.566v-3.338l-14.427-.093Z"
      fill="url(#paint48_radial_301_44952)"
    />
    <path
      d="M102.106 177.633c0 1.971-8.891 3.566-19.865 3.566-10.97 0-19.866-1.595-19.866-3.566 0-1.97 8.895-3.566 19.865-3.566 10.97-.004 19.866 1.596 19.866 3.566Z"
      fill="#FBE034"
    />
    <path
      d="M101.497 177.634c0 1.844-8.622 3.338-19.256 3.338s-19.257-1.494-19.257-3.338 8.623-3.338 19.257-3.338c10.634-.004 19.256 1.494 19.256 3.338Z"
      fill="#FFC148"
    />
    <path
      d="M100.988 177.633c0 1.658-8.391 3.009-18.744 3.009S63.5 179.296 63.5 177.633c0-1.663 8.391-3.009 18.744-3.009s18.744 1.346 18.744 3.009Z"
      fill="#FBE034"
    />
    <path
      d="M85.615 177.199c-.524 0-.936.021-1.23.067a2.925 2.925 0 0 0-.819.245c-.256.123-.52.232-.798.329a7.309 7.309 0 0 1-1.008.258c-.399.076-.87.135-1.42.181a26.16 26.16 0 0 1-1.96.068c-1.294 0-2.29-.076-2.962-.224-.647-.139-.89-.321-.768-.532h-2.197v-.4l2.495-.14c.374-.181 1.033-.034 1.881-.126.824-.089 1.81-.536 2.974-.536l.017.485-.194.321c-.768 0-1.36.025-1.78.076-.429.05-.68.122-.752.211-.076.093.025.164.306.215.286.055.723.08 1.298.08a8.55 8.55 0 0 0 1.315-.08 5.216 5.216 0 0 0 1.033-.274 9.45 9.45 0 0 1 .916-.3 7.73 7.73 0 0 1 .89-.186c.32-.046.685-.084 1.088-.109.403-.026.873-.038 1.42-.038 1.016 0 1.88.046 2.62.143.76.097 1.155-.072 1.508.118l2.54.173v.401h-2.045c.143.245-.15.448-.911.599-.794.161-2.003.241-3.604.241l-.264-.587c.013-.198-.042-.19 0-.401.647-.008 1.55.355 1.881.3.391-.067.534-.16.433-.283-.08-.097-.298-.168-.643-.219a8.632 8.632 0 0 0-1.26-.076Z"
      fill="url(#paint49_radial_301_44952)"
    />
    <path
      d="M85.615 176.797c-.524 0-.936.021-1.23.068a2.86 2.86 0 0 0-.819.245c-.256.122-.52.232-.798.329a7.177 7.177 0 0 1-1.008.257c-.399.076-.87.135-1.42.182-.554.046-1.21.067-1.96.067-1.294 0-2.29-.076-2.962-.223-.647-.144-.89-.321-.768-.532h-2.197l.403-.262h2.138c.374-.177.987-.312 1.84-.405.823-.089 1.81-.135 2.973-.135l-.176.405c-.769 0-1.361.025-1.781.076-.428.051-.68.122-.752.211-.075.093.025.165.307.215.285.055.722.081 1.297.081.538 0 .975-.026 1.315-.081.336-.05.685-.143 1.033-.274.336-.127.643-.228.916-.304.269-.076.567-.135.89-.185.32-.047.685-.085 1.088-.11.403-.026.873-.038 1.42-.038 1.016 0 1.881.046 2.62.143.76.097 1.323.241 1.676.431h1.97l.407.261h-2.045c.142.245-.151.448-.912.6-.793.16-2.003.24-3.603.24l-.265-.586c.853 0 1.483-.034 1.882-.106.39-.067.533-.16.433-.283-.08-.097-.299-.168-.643-.219a10.571 10.571 0 0 0-1.269-.068Z"
      fill="#FA9351"
    />
    <path
      d="M43.481 184.091c.252-1.401.554-2.874.92-4.393.269-1.136.558-2.237.86-3.305l2.26-9.79c-.058-.173.693-2.904.693-2.904l3.327.131c1.83.089 1.608 7.339-.504 16.193-2.109 8.853-5.305 15.956-7.14 15.867l-3.108-.152 2.692-11.647Z"
      fill="url(#paint50_radial_301_44952)"
    />
    <path
      d="M40.794 195.734c1.831.088 5.027-7.014 7.136-15.868 2.108-8.854 2.335-16.104.504-16.192-1.831-.089-5.027 7.014-7.136 15.867-2.112 8.854-2.335 16.104-.504 16.193Z"
      fill="#FBE034"
    />
    <path
      d="M40.913 195.244c1.713.084 4.763-6.803 6.808-15.387 2.045-8.583 2.314-15.61.596-15.694-1.713-.085-4.763 6.803-6.808 15.386-2.045 8.584-2.314 15.611-.596 15.695Z"
      fill="#FFC148"
    />
    <path
      d="M41.006 194.831c1.545.076 4.41-6.634 6.4-14.99 1.991-8.356 2.352-15.188.807-15.264-1.546-.076-4.41 6.634-6.4 14.99-1.992 8.355-2.353 15.188-.807 15.264Z"
      fill="#FBE034"
    />
    <path
      d="M43.392 183.432c-.016.283.009.46.084.528.093.088.206-.021.345-.334.117-.265-.05-1.012.084-1.531.206-.026.185.021.374.016l.495.241c-.306 1.287-.617 2.262-.915 2.895-.286.608-.534.836-.731.709l-.395 1.65-.374-.017.328-2.059c-.11-.296.126-.604.18-1.224.055-.599.177-1.3.374-2.123.105-.439.206-.819.307-1.143.1-.325.206-.617.31-.874.11-.262.223-.498.345-.713.122-.22.277-.46.458-.726.189-.279.344-.553.458-.823a7.34 7.34 0 0 0 .327-1.059c.11-.464.168-.819.177-1.051.004-.228-.042-.317-.143-.258-.097.055-.21.258-.34.6-.127.337-.265.814-.412 1.435l-.336.139-.445-.034c.222-.941.827-1.718 1.07-2.376.248-.684.24-1.219.48-1.515l.609-2.009.373.017-.424 1.773c.219-.089.34.118.345.645.008.549-.114 1.355-.362 2.402-.142.607-.29 1.135-.44 1.578-.148.439-.295.819-.442 1.135-.142.312-.29.578-.437.802-.142.219-.293.43-.457.629a2.57 2.57 0 0 0-.386.65 6.35 6.35 0 0 0-.303.991 5.85 5.85 0 0 0-.18 1.034Z"
      fill="url(#paint51_radial_301_44952)"
    />
    <path
      d="M43.015 183.415c-.017.283.008.46.084.528.092.088.206-.021.344-.338.143-.321.294-.827.458-1.515l.496.241c-.307 1.287-.618 2.262-.916 2.895-.285.607-.533.835-.73.709l-.395 1.65-.169-.338.379-1.591c-.11-.295-.135-.751-.08-1.372.055-.599.176-1.299.373-2.118.105-.439.206-.819.307-1.144.1-.325.206-.616.311-.873.11-.262.223-.498.344-.713.122-.22.277-.46.458-.726.189-.279.344-.553.454-.823.113-.275.222-.625.327-1.059.11-.465.168-.819.177-1.051.004-.228-.042-.313-.143-.258-.097.055-.21.258-.34.6-.126.337-.265.814-.412 1.434l-.407.123c.222-.941.453-1.735.697-2.393.248-.684.491-1.173.73-1.464l.412-1.726.32-.313-.425 1.773c.219-.089.34.118.345.645.008.549-.114 1.355-.362 2.402-.142.607-.29 1.135-.436 1.578-.147.439-.294.819-.441 1.135-.143.312-.29.578-.437.802-.143.219-.294.43-.458.629a2.7 2.7 0 0 0-.386.65 6.35 6.35 0 0 0-.303.991c-.1.41-.16.752-.176 1.03Z"
      fill="#FA9351"
    />
    <path
      d="M115.393 114.183c0 9.145-8.588 16.559-19.184 16.559-6.565 0-12.356-2.844-15.813-7.182-2.13-2.667-3.373-5.896-3.373-9.377 0-9.149 8.59-16.56 19.186-16.56 7.19 0 13.456 3.414 16.74 8.47 1.558 2.393 2.444 5.153 2.444 8.09Z"
      fill="#F69162"
    />
    <path
      d="M79.027 122.905c-1.041.422-1.869-.123-2.507-1.127a8.094 8.094 0 0 1-.731-1.532c-.76-2.068-1.105-4.739-1.168-6.06-.126-2.528 2.663-2.777 2.663-2.777s2.306 4.828 2.734 8.263c.034.291.059.57.063.836.03 1.169-.248 2.072-1.054 2.397ZM84.406 93.69s5.897 1.612 7.077 7.583l-5.64 2.258-1.437-9.842Z"
      fill="#F69162"
    />
    <path
      d="M118.772 113.565a5.18 5.18 0 0 0-.857-1.207c.08-.122.164-.245.248-.358.122-.148.252-.292.411-.401.076-.055.16-.106.248-.144.265-.118.567-.156.857-.067-.311-.144-.685-.123-1.012.012-.059.021-.113.051-.168.08-.185.102-.34.237-.492.38-.071.076-.142.152-.205.232l-.068.089a5.359 5.359 0 0 0-.73-.578 5.707 5.707 0 0 0-2.012-.798h-.008c-.164-.029-.332-.059-.5-.076-.882-.097-.811 1.342-.105 1.363.705.021 1.398.19 2.007.524.248.135.483.299.698.485a6.379 6.379 0 0 0-.647 1.376 3.623 3.623 0 0 0-.193 1.27c.012.224.05.452.138.675a1.493 1.493 0 0 0 1.1.941c.122.026.244.038.362.042.063.005.117-.004.18-.004a1.13 1.13 0 0 0 .445-.114c.177-.088.332-.219.442-.371.008-.008.012-.017.02-.025.16-.224.244-.465.295-.701.096-.473.067-.928-.017-1.367a4.78 4.78 0 0 0-.437-1.258Zm-.353 2.477a1.07 1.07 0 0 1-.13.41.456.456 0 0 1-.244.211c-.025.008-.05.012-.088.021-.038.004-.08.013-.118.013a1.033 1.033 0 0 1-.23-.009.86.86 0 0 1-.4-.156c-.235-.165-.378-.481-.42-.831-.046-.351-.004-.73.085-1.098.105-.43.264-.848.47-1.253a3.964 3.964 0 0 1 .983 1.684c.092.333.134.688.092 1.008Z"
      fill="#E57E55"
    />
    <path
      opacity={0.15}
      d="M82.743 118.547c-.058.055-1.104 1.072-2.654 1.958-1.016.582-2.25 1.11-3.561 1.274-.277-.434-.521-.962-.731-1.531.89-.026 2.558-.135 4.225-.574.953-.254 1.907-.612 2.721-1.127Z"
      fill="#14142A"
    />
    <path
      d="M93.684 128.783v4.423s-1.066.426-3.145.447c-1.117.013-2.524-.093-4.217-.447l-.752-6.259 6.905 1.562 1.21.274ZM109.716 125.181l-.756 8.026c-.714.148-1.382.254-1.995.325-3.536.41-5.363-.325-5.363-.325v-4.422l2.297-1.022 5.817-2.582ZM84.642 102.695c-.298-.608-.945-6.777-.235-9.005 0 0 2.406 6.351 2.12 9.005 0 0-.894 2.038-1.885 0Z"
      fill="#E57E55"
    />
    <path
      opacity={0.15}
      d="M82.927 109.898a.968.968 0 0 0 .966-.97.969.969 0 0 0-.966-.971.969.969 0 0 0-.966.971c0 .536.432.97.966.97ZM76.751 115.823c.143.519-.012 1.013-.348 1.106-.336.093-.723-.249-.866-.768-.142-.519.013-1.013.349-1.106.336-.093.722.249.865.768ZM110.306 103.813a.33.33 0 0 1-.537.257c-.122-.097-.244-.19-.362-.283a22.43 22.43 0 0 0-1.289-.924 25.076 25.076 0 0 0-6.497-3.093c-.551-.169-.962-.27-1.197-.325a.327.327 0 0 1-.257-.283l-.008-.05c-.029-.224.177-.418.395-.368a23.386 23.386 0 0 1 2.133.57c3.121.988 5.246 2.372 6.443 3.317.563.443.92.794 1.075.954a.318.318 0 0 1 .093.207c.008.004.008.008.008.021Z"
      fill="#14142A"
    />
    <path
      d="M96.236 114.283a35.637 35.637 0 0 1-1.218-.477 4.62 4.62 0 0 1-1.046-.595 2.622 2.622 0 0 1-.73-.861c-.185-.342-.27-.759-.26-1.262.008-.481.1-.903.28-1.266.181-.363.425-.666.736-.92.31-.253.667-.447 1.075-.59a4.848 4.848 0 0 1 1.306-.258l.025-1.62.718.012-.025 1.604c.479.025.92.101 1.315.228a3.57 3.57 0 0 1 1.02.494c.282.202.504.434.66.692.155.261.231.531.227.814-.005.19-.038.35-.105.481a.975.975 0 0 1-.61.494 1.401 1.401 0 0 1-.365.051 1.135 1.135 0 0 1-.403-.081.946.946 0 0 1-.517-.519.949.949 0 0 1-.067-.379c.004-.169.046-.347.13-.536.084-.19.21-.363.387-.524a1.773 1.773 0 0 0-.37-.211 2.9 2.9 0 0 0-.433-.143 4.415 4.415 0 0 0-.877-.131l-.059 3.912c.424.148.84.312 1.247.485.412.173.777.384 1.097.629.323.245.579.54.776.882.194.342.29.764.282 1.258a2.822 2.822 0 0 1-.303 1.278 2.83 2.83 0 0 1-.777.937 3.745 3.745 0 0 1-1.125.595 5.413 5.413 0 0 1-1.344.266l-.025 1.714-.718-.013.025-1.688a5.344 5.344 0 0 1-1.453-.245 4.134 4.134 0 0 1-1.139-.553 2.568 2.568 0 0 1-.734-.764 1.601 1.601 0 0 1-.252-.89c.004-.333.113-.612.323-.84.21-.224.491-.337.84-.329.143 0 .277.025.403.072a.98.98 0 0 1 .584.553c.059.139.088.303.084.489-.004.215-.05.422-.143.612-.092.19-.24.355-.437.494.282.24.592.409.945.506.349.097.68.156.996.173l.054-4.03Zm-1.768-3.693c-.004.254.03.473.1.655.072.185.181.346.337.481.151.139.344.265.575.379.231.114.508.233.836.355l.059-3.693c-.26.026-.5.081-.727.156a1.89 1.89 0 0 0-.6.334c-.177.143-.311.325-.416.544-.11.228-.16.486-.164.789Zm4.292 5.866a1.7 1.7 0 0 0-.105-.662 1.495 1.495 0 0 0-.331-.507 2.497 2.497 0 0 0-.567-.409 6.268 6.268 0 0 0-.803-.363l-.058 3.773c.251-.025.491-.08.718-.165.227-.084.424-.198.592-.35.168-.148.302-.333.399-.549.1-.215.151-.472.155-.768Z"
      fill="#E57E55"
    />
    <path
      d="M95.85 113.945a37.762 37.762 0 0 1-1.218-.476 4.785 4.785 0 0 1-1.05-.595 2.695 2.695 0 0 1-.73-.861c-.186-.342-.27-.76-.261-1.262.008-.481.1-.903.281-1.266.18-.363.424-.667.735-.92a3.486 3.486 0 0 1 1.075-.591 4.81 4.81 0 0 1 1.306-.257l.026-1.625.718.012-.025 1.604c.478.025.92.101 1.314.228.4.127.74.291 1.02.494.282.202.505.43.66.692.156.262.231.532.227.814-.004.19-.038.351-.105.481a.95.95 0 0 1-.26.317c-.11.084-.223.139-.35.177a1.399 1.399 0 0 1-.364.051 1.134 1.134 0 0 1-.404-.08.978.978 0 0 1-.314-.211.895.895 0 0 1-.202-.308.957.957 0 0 1-.067-.38c.004-.169.046-.346.13-.536.084-.19.21-.363.386-.523a1.774 1.774 0 0 0-.37-.212 2.902 2.902 0 0 0-.432-.143 3.538 3.538 0 0 0-.45-.089 5.99 5.99 0 0 0-.428-.042l-.059 3.912c.425.148.84.313 1.248.481.407.173.777.384 1.096.629.323.245.58.54.777.882.193.342.29.764.281 1.258a2.832 2.832 0 0 1-.302 1.278 2.836 2.836 0 0 1-.777.937 3.768 3.768 0 0 1-1.126.595 5.413 5.413 0 0 1-1.344.266l-.025 1.714-.718-.013.025-1.688a5.317 5.317 0 0 1-1.453-.245 4.134 4.134 0 0 1-1.138-.553 2.568 2.568 0 0 1-.735-.764 1.577 1.577 0 0 1-.252-.89c.004-.333.113-.612.323-.84.21-.224.492-.337.84-.329.143.004.277.025.403.072a.98.98 0 0 1 .584.553c.06.139.088.303.084.489-.004.215-.05.422-.143.612-.092.19-.239.354-.436.494.281.24.592.409.945.506.348.097.68.156.995.173l.059-4.022Zm-1.773-3.692c-.004.253.03.472.101.654.072.186.18.346.336.481.151.139.345.266.576.38.23.114.508.232.835.354l.06-3.692c-.261.025-.5.08-.727.156-.227.076-.429.19-.6.333-.173.144-.312.329-.417.545-.105.228-.16.489-.164.789Zm4.297 5.866a1.705 1.705 0 0 0-.105-.663 1.49 1.49 0 0 0-.332-.506 2.468 2.468 0 0 0-.567-.41 6.423 6.423 0 0 0-.802-.362l-.059 3.772c.252-.025.492-.08.718-.164a1.82 1.82 0 0 0 .593-.351c.168-.147.302-.333.399-.548.096-.211.15-.473.155-.768Z"
      fill="#F7C06B"
    />
    <path
      d="M115.388 114.18c0 9.145-8.589 16.56-19.185 16.56-6.564 0-12.356-2.845-15.812-7.183 2.59 1.085 8.941 3.034 19.584 2.03 14.401-1.359 12.969-19.501 12.969-19.501 1.558 2.397 2.444 5.157 2.444 8.094Z"
      fill="#E57E55"
    />
    <path
      opacity={0.15}
      d="M93.686 128.783v4.423s-1.067.426-3.146.447c-.189-3.595-3.985-5.157-3.985-5.157s3.343.013 5.926.013l1.205.274ZM109.723 125.178l-.756 8.026c-.714.148-1.382.254-1.995.325.214-2.338-1.81-4.604-3.066-5.769l5.817-2.582Z"
      fill="#14142A"
    />
    <path
      d="M119.235 116.185a1.933 1.933 0 0 1-.294.701c-.009.008-.013.016-.021.025-.235.211-.408.342-.441.371.071-.067.735-.721.487-2.312-.147-.946-.723-1.659-1.331-2.161-.08-.063-.16-.127-.24-.182a5.184 5.184 0 0 0-1.05-.599c-.731-.308-1.361-1.232-1.361-1.232h.009a5.614 5.614 0 0 1 2.012.798c.26.168.508.362.73.578 0 0 .042.033.185.181.34.359.63.768.857 1.207.202.397.353.815.441 1.253.084.444.109.904.017 1.372Z"
      fill="#F69162"
    />
    <path
      d="M77.29 111.404s2.09 1.831 1.977 4.232c0 0 1.059-3.886-1.6-5.735l-.378 1.503Z"
      fill="#E57E55"
    />
    <defs>
      <radialGradient
        id="paint0_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2533 0 0 54.925 75.74 186.151)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4656 0 0 21.3432 89.165 188.181)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2535 0 0 54.9252 78.41 181.562)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4653 0 0 21.3431 91.839 183.592)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2534 0 0 54.925 75.74 176.326)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4656 0 0 21.3434 89.165 178.354)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2537 0 0 54.9254 78.41 171.923)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4653 0 0 21.3431 91.839 173.953)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2534 0 0 54.9252 75.74 166.687)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4656 0 0 21.3431 89.165 168.717)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2533 0 0 54.925 75.74 160.778)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4656 0 0 21.3432 89.165 162.807)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2535 0 0 54.9252 78.41 156.19)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4653 0 0 21.3432 91.839 158.22)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2534 0 0 54.9252 75.74 150.953)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4656 0 0 21.3432 89.165 152.983)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2537 0 0 54.9254 78.41 146.552)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4653 0 0 21.3431 91.839 148.581)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2534 0 0 54.925 75.74 141.315)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4656 0 0 21.3434 89.165 143.344)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2537 0 0 54.9252 79.53 137.043)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint21_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.466 0 0 21.3436 92.954 139.072)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2534 0 0 54.925 82.2 132.455)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint23_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4658 0 0 21.3437 95.632 134.483)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint24_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2535 0 0 54.9252 79.53 127.219)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint25_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4656 0 0 21.3431 92.954 129.249)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2537 0 0 54.9254 52.95 184.139)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint27_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4654 0 0 21.343 66.383 186.169)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint28_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2538 0 0 54.9257 51.828 178.902)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint29_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4657 0 0 21.3438 65.256 180.931)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2534 0 0 54.9253 51.225 173.997)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint31_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4655 0 0 21.3435 64.657 176.027)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2538 0 0 54.9252 53.903 169.409)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint33_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4657 0 0 21.3436 67.331 171.439)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint34_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2533 0 0 54.9254 51.225 164.174)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint35_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4655 0 0 21.3431 64.657 166.203)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint36_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2539 0 0 54.9255 53.903 159.77)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint37_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4657 0 0 21.3433 67.331 161.8)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint38_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2533 0 0 54.9252 51.225 154.534)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint39_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4659 0 0 21.3437 64.657 156.564)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint40_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2534 0 0 54.925 64.614 190.961)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint41_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4654 0 0 21.3432 78.046 192.99)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint42_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2537 0 0 54.9252 67.288 186.374)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint43_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4653 0 0 21.3431 80.72 188.404)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint44_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2534 0 0 54.9252 64.614 181.136)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint45_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4658 0 0 21.3435 78.046 183.165)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint46_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2535 0 0 54.9252 67.288 176.733)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint47_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4658 0 0 21.3435 80.72 178.763)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint48_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(73.2536 0 0 54.9251 64.614 171.498)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint49_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4654 0 0 21.3432 78.046 173.528)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint50_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(103.403 -44.082 99.3) scale(60.7698 50.2925)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
      <radialGradient
        id="paint51_radial_301_44952"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-5.47388 22.97162 -19.0114 -4.5302 41.605 176.13)"
      >
        <stop stopColor="#FBE034" />
        <stop offset={0.149} stopColor="#FAC63E" />
        <stop offset={0.319} stopColor="#F8AF46" />
        <stop offset={0.504} stopColor="#F89F4C" />
        <stop offset={0.714} stopColor="#F79650" />
        <stop offset={1} stopColor="#F79351" />
      </radialGradient>
    </defs>
  </svg>
);

export default SubmissionDocuments;
