import { COLORS, PADDINGS, SECTION_IDS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import { SectionTitle } from '../SectionTitle';
import { Tag } from '../Tag';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useSwiperRef } from '@/hooks/useSwiperRef';
import { PaginationOptions } from 'swiper/types';
import { Arrow } from '@/ui/iconComponents';
import { reviewsData, mainData } from './data';
import { Review } from './components';

import 'swiper/css';
import 'swiper/css/pagination';

const Reviews: FC = () => {
  const [prevEl, prevBtnRef] = useSwiperRef<HTMLButtonElement>();
  const [nextEl, nextBtnRef] = useSwiperRef<HTMLButtonElement>();

  const customSliderPagination: PaginationOptions = {
    type: 'custom',
    el: '.fraction-pagination',
    renderCustom: function (swiper, current, total) {
      return `<span>${current}</span>/ ${total}`;
    },
  };
  return (
    <Root id={SECTION_IDS.cases}>
      <StyledContainer>
        <Head>
          <StyledTag text={mainData.tag} />
          <StyledSectionTitle>{mainData.title}</StyledSectionTitle>
          {reviewsData.length > 0 && (
            <PaginationWrapper>
              <PrevArrowButton ref={prevBtnRef}>
                <Arrow color={COLORS.primaryDefault} />
              </PrevArrowButton>

              <FractionPagination className="fraction-pagination" />

              <SliderNavButton ref={nextBtnRef}>
                <Arrow color={COLORS.primaryDefault} />
              </SliderNavButton>
            </PaginationWrapper>
          )}
        </Head>
        <SliderContainer>
          <Swiper
            slidesPerView={2}
            spaceBetween={24}
            pagination={customSliderPagination}
            modules={[Pagination, Navigation]}
            navigation={{
              prevEl,
              nextEl,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              900: {
                slidesPerView: 2,
              },
            }}
          >
            {reviewsData.map((review, index) => {
              return (
                <SwiperSlide key={index}>
                  <Review data={review} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SliderContainer>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  padding-top: ${PADDINGS.paddingBetweenSectionsLarge};
  padding-bottom: ${PADDINGS.paddingBetweenSectionsLarge};
  @media (max-width: 1024px) {
    padding-top: ${PADDINGS.paddingBetweenSectionsMedium};
    padding-bottom: ${PADDINGS.paddingBetweenSectionsMedium};
  }
`;

const Head = styled.div`
  position: relative;
  margin-bottom: 64px;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

const StyledTag = styled(Tag)`
  margin-bottom: 12px;
`;

const StyledSectionTitle = styled(SectionTitle)`
  letter-spacing: 0.01em;
  max-width: 475px;
  @media (max-width: 768px) {
    letter-spacing: normal;
    ${TYPOGRAPHY.title4Medium28};
    margin-bottom: 48px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 768px) {
    position: static;
    display: inline-flex;
  }
`;

const FractionPagination = styled.p`
  display: flex;
  align-items: baseline;
  ${TYPOGRAPHY.headline1Medium24};
  color: ${COLORS.primaryDefault};
  margin-right: 12px;
  margin-left: 12px;
  span {
    ${TYPOGRAPHY.title2SemiBold36};
    margin-right: 4px;
  }
`;

const SliderNavButton = styled.button`
  width: 24px;
  height: 24px;
`;

const PrevArrowButton = styled(SliderNavButton)`
  transform: rotate(180deg);
`;

const SliderContainer = styled.div`
  .swiper {
    overflow: initial;
  }

  .swiper-slide {
    display: flex;
    height: auto;
  }
  .swiper-wrapper {
    align-items: flex-start;
  }
`;

export default Reviews;
