import { PADDINGS } from '@/constants';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ContainerProps {
  className?: string;
}

const Container: FC<ContainerProps> = ({ children, className }) => (
  <Root className={className}>{children}</Root>
);

const Root = styled.div`
  position: relative;
  padding-left: ${PADDINGS.commonInPercent};
  padding-right: ${PADDINGS.commonInPercent};
  max-width: 1440px;
  margin: 0 auto;
  @media (min-width: 1440px) {
    padding-left: ${PADDINGS.commonInPixels};
    padding-right: ${PADDINGS.commonInPixels};
  }
  @media (max-width: 1300px) {
    padding-left: ${PADDINGS.commonInPixelsLarge};
    padding-right: ${PADDINGS.commonInPixelsLarge};
  }
  @media (max-width: 1024px) {
    padding-left: ${PADDINGS.commonInPixelsMedium};
    padding-right: ${PADDINGS.commonInPixelsMedium};
  }
  @media (max-width: 768px) {
    padding-left: ${PADDINGS.commonInPixelsSmall};
    padding-right: ${PADDINGS.commonInPixelsSmall};
  }
`;

export default Container;
