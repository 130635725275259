import * as React from 'react';
import { SVGProps } from 'react';

const Whatsapp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.36 4.599C24.32 1.66 20.32 0 16.06 0 3.833 0-3.844 13.247 2.261 23.784L0 32l8.447-2.203c3.68 1.988 6.694 1.812 7.621 1.93 14.177 0 21.235-17.152 11.272-27.076l.02-.052Z"
      fill="#F7F9FA"
    />
    <path
      d="M16.09 29.001 16.08 29h-.021c-4.243 0-6.953-2.01-7.22-2.125l-5 1.3 1.34-4.86-.319-.5A13.158 13.158 0 0 1 2.84 15.8c0-11.724 14.326-17.586 22.617-9.3 8.27 8.2 2.464 22.5-9.368 22.5Z"
      fill="#36C186"
    />
    <path
      d="m23.343 19.076-.012.1c-.401-.2-2.356-1.156-2.72-1.288-.817-.303-.587-.048-2.156 1.75-.233.26-.465.28-.861.1-.4-.2-1.685-.62-3.205-1.98-1.183-1.06-1.978-2.36-2.213-2.76-.39-.675.427-.771 1.17-2.18.134-.28.066-.5-.033-.698-.1-.2-.896-2.16-1.229-2.941-.32-.78-.65-.68-.896-.68-.768-.067-1.33-.056-1.824.458-2.152 2.366-1.61 4.806.232 7.4 3.619 4.736 5.547 5.608 9.072 6.819.952.303 1.82.26 2.507.161.765-.121 2.356-.961 2.688-1.901.34-.94.34-1.72.24-1.9-.099-.18-.36-.28-.76-.46Z"
      fill="#F9F9FA"
    />
  </svg>
);

export default Whatsapp;
