import { COLORS } from '@/constants';
import { selectors } from '@/store/ducks';
import { updateUserDataThunk } from '@/store/ducks/auth/thunks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Plus } from '@/ui/iconComponents';
import { uploadFile } from '@/utils/uploadFile';
import React, { FC, useState } from 'react';
import ReactImageUploading, { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import styled from 'styled-components';

interface ChangeAvatarProps {
  className?: string;
}

const ChangeAvatar: FC<ChangeAvatarProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { avatarUrl } = useAppSelector(selectors.auth.selectUserData);
  const [images, setImages] = useState<ImageListType>([]);
  const stylesForRoot = () => {
    if (images.length > 0) {
      return {
        backgroundImage: `url(${images[0].dataURL})`,
      };
    }
    if (avatarUrl) {
      return {
        backgroundImage: `url(${avatarUrl})`,
      };
    }
  };

  const onChange = (imageList: ImageListType) => {
    const file = imageList[0].file;
    setImages(imageList as never[]);
    uploadFile(file, true).then((data) => {
      const addAvatar = dispatch(
        updateUserDataThunk({
          avatarUrl: data,
        })
      );

      toast.promise(addAvatar, {
        pending: 'Файл загружается',
        success: 'Файл загружен',
        error: 'Произошла ошибка при загрузке файла',
      });
    });
  };

  return (
    <Root className={className} style={stylesForRoot()}>
      <ReactImageUploading
        acceptType={['jpg', 'png', 'jpeg', 'webp']}
        value={images}
        onChange={onChange}
      >
        {({ imageList, onImageUpload }) => {
          return (
            <>
              <UploadButton onClick={onImageUpload}>
                <Plus color={COLORS.white} width={32} height={32} />
              </UploadButton>
            </>
          );
        }}
      </ReactImageUploading>
    </Root>
  );
};

const Root = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: ${COLORS.grayDarker};
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

const UploadButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.3s ease;
  background-color: rgba(71, 155, 223, 0.6);
  opacity: 0;
  :hover {
    opacity: 1;
  }
`;

export default ChangeAvatar;
