import { Footer, Header } from '@/components';
import { COLORS } from '@/constants';
import React, { FC } from 'react';
import styled from 'styled-components';
import '@/libs/dayjs';

interface ProfileLayoutProps {
  backgroundColor?: string;
  fullHeight?: boolean;
}

const ProfileLayout: FC<ProfileLayoutProps> = ({
  backgroundColor = COLORS.white,
  fullHeight = false,
  children,
}) => {
  return (
    <PageContent style={{ backgroundColor }} $isFullHeight={fullHeight}>
      <Header />
      <main>{children}</main>
      <StyledFooter />
    </PageContent>
  );
};

const PageContent = styled.div<{ $isFullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $isFullHeight }) => $isFullHeight && 'height: 100vh;'};
`;

const StyledFooter = styled(Footer)`
  margin-top: auto;
`;

export default ProfileLayout;
