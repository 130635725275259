import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export const handleAsyncError = (error: AxiosError) => {
  if (Array.isArray(error.response.data.message)) {
    error.response.data.message.forEach((item) => {
      toast.error(item);
    });
  } else {
    toast.error(error.response.data.message);
  }
  console.error(`${error.response.data.message}`);
};
