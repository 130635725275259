import { COLORS } from '@/constants';
import * as React from 'react';
import { SVGProps } from 'react';

interface ArrowProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Arrow = ({ color = COLORS.black2, ...props }: ArrowProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.91 19.92 6.52-6.52c.77-.77.77-2.03 0-2.8L8.91 4.08"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Arrow;
