import { COLORS } from '@/constants';
import { Arrow } from '@/ui/iconComponents';
import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';

interface ArrowButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

const ArrowButton: FC<ArrowButtonProps> = ({ className, ...props }) => {
  return (
    <Root className={className} {...props}>
      <Arrow color={COLORS.primaryDefault} />
    </Root>
  );
};

const Root = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: 1px solid ${COLORS.grayLighter};
  border-radius: 16px;
  background-color: ${COLORS.white};
  transition: all 0.3s ease-in;
  :hover,
  :focus {
    border-color: ${COLORS.gray};
  }
`;

export default ArrowButton;
