import React, { FC } from 'react';
import { SeoData } from '@/types';
import { Helmet } from 'react-helmet';

interface SeoProps {
  data: SeoData;
}

const Seo: FC<SeoProps> = ({ data }) => {
  const { keywords, metaDescription, metaTitle, sharedImage } = data;
  const hasWindow = typeof window !== 'undefined';
  const url = hasWindow ? window.location.href : '';
  const origin = hasWindow ? window.location.origin : '';
  const imageUrl = origin + sharedImage.url;

  return (
    <Helmet>
      <html lang="ru" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      {sharedImage && <meta name="image" content={imageUrl} />}
      <meta name="keywords" content={keywords} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      {sharedImage && <meta property="og:image" content={imageUrl} />}

      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default Seo;
