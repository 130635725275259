import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC, forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import ReactInputMask, { Props } from 'react-input-mask';

interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Omit<Props, 'inputRef' | 'mask'> {
  className?: string;
}

const Input = forwardRef<HTMLInputElement | ReactInputMask, InputProps>(
  ({ className, mask, maskPlaceholder, alwaysShowMask, ...rest }, ref) => {
    return mask ? (
      <InputWithMask
        className={className}
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        alwaysShowMask={alwaysShowMask}
        ref={ref}
        as={ReactInputMask}
        {...rest}
      />
    ) : (
      <Root className={className} ref={ref} {...rest} />
    );
  }
);

const Root = styled.input`
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${COLORS.gray1};
  border-radius: 12px;
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.black};
  outline: none;
  background-color: ${COLORS.white};
  ::placeholder {
    color: ${COLORS.grayDarker};
  }
  :focus {
    border-color: ${COLORS.primaryDefault};
    caret-color: ${COLORS.primaryDefault};
  }
  :disabled {
    opacity: 0.5;
  }
`;

const InputWithMask = styled(Root)``;

export default Input;
