import { COLORS, SECTION_IDS, TYPOGRAPHY } from '@/constants';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import { SectionTitle } from '../SectionTitle';
import { Tag } from '../Tag';
import { ServiceCard } from '../ServiceCard';
import {
  mainData,
  auditServices,
  investmentsServices,
  ServicesTabs,
  tabsData,
} from './data';
import { Tabs } from '@/ui/components';

const Services: FC = () => {
  const [activeTab, setActiveTab] = useState<ServicesTabs>(ServicesTabs.audit);

  return (
    <Root id={SECTION_IDS.services}>
      <StyledContainer>
        <Head>
          <StyledTag text={mainData.tag} />
          <StyledSectionTitle>{mainData.title}</StyledSectionTitle>
          <TabsWrapper>
            <StyledTabs
              data={tabsData}
              onToggle={setActiveTab}
              activeValue={activeTab}
            />
          </TabsWrapper>
        </Head>
        <div>
          <ServicesList $isActive={activeTab === ServicesTabs.audit}>
            {auditServices.map((service) => (
              <StyledServiceCard key={service.id} data={service} />
            ))}
          </ServicesList>
          <ServicesList $isActive={activeTab === ServicesTabs.investment}>
            {investmentsServices.map((service) => (
              <StyledServiceCard key={service.id} data={service} />
            ))}
          </ServicesList>
        </div>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.grayLight};
`;

const StyledContainer = styled(Container)`
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
`;

const Head = styled.div`
  margin-bottom: 48px;
  @media (max-width: 768px) {
    margin-bottom: 38px;
  }
`;

const StyledTag = styled(Tag)`
  margin-bottom: 12px;
`;

const StyledSectionTitle = styled(SectionTitle)`
  letter-spacing: 0.01em;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    letter-spacing: normal;
    ${TYPOGRAPHY.title4Medium28}
  }
`;

const TabsWrapper = styled.div`
  overflow: auto;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const StyledTabs = styled(Tabs)`
  @media (max-width: 1024px) {
    min-width: 452px;
  }
`;

const ServicesList = styled.div<{ $isActive: boolean }>`
  display: ${({ $isActive }) => ($isActive ? 'grid' : 'none')};
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  @media (max-width: 700px) {
    display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
  }
`;

const StyledServiceCard = styled(ServiceCard)`
  @media (max-width: 700px) {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default Services;
