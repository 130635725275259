import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ReviewData } from '../../data';

interface ReviewProps {
  data: ReviewData;
  className?: string;
}

const Review: FC<ReviewProps> = ({ className, data }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const btnText = isShowMore ? 'Скрыть' : 'Показать больше';

  return (
    <Root className={className}>
      <SlideHead>
        <p>{data.years}</p>
        <p>{data.service}</p>
      </SlideHead>
      <SlideName>{data.name}</SlideName>
      <SlideMoney>{data.money} ₽</SlideMoney>
      <TextWrapper>
        <TextReview $isMore={isShowMore}>{data.text}</TextReview>
      </TextWrapper>
      {data.text.length > 361 && (
        <TextMoreBtn onClick={() => setIsShowMore(!isShowMore)}>
          {btnText}
        </TextMoreBtn>
      )}
    </Root>
  );
};

const Root = styled.div`
  background-color: ${COLORS.white};
  box-shadow: 7px 8px 32px rgba(241, 241, 241, 0.64);
  border-radius: 28px;
  border: 1px solid ${COLORS.grayLight};
  padding: 24px 24px 32px;
  width: 100%;
`;

const SlideHead = styled.div`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.grayDarker};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const SlideName = styled.p`
  ${TYPOGRAPHY.headline1Medium24};
  color: ${COLORS.black};
  margin-bottom: 8px;
`;

const SlideMoney = styled.p`
  ${TYPOGRAPHY.body1SemiBold18};
  color: ${COLORS.black};
  margin-bottom: 8px;
`;

const TextReview = styled.p<{ $isMore: boolean }>`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.grayDarker};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ $isMore }) => !$isMore && 5};
  overflow: hidden;
`;

const TextWrapper = styled.div`
  margin-bottom: 5px;
`;

const TextMoreBtn = styled.button`
  ${TYPOGRAPHY.caption1Regular14};
  color: ${COLORS.primaryDefault};
`;

export default Review;
