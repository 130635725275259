import { AccordionItemData } from '@/types';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { AccordionItem } from '../AccordionItem';

interface AccordionProps {
  data: AccordionItemData[];
  className?: string;
}

const Accordion: FC<AccordionProps> = ({ data, className }) => {
  const [clicked, setClicked] = useState<number | '0'>('0');
  const handleToggle = (index: number) => {
    if (clicked === index) {
      return setClicked('0');
    }
    setClicked(index);
  };

  return (
    <Root className={className}>
      {data.map((item, index) => (
        <StyledAccordionItem
          question={item.question}
          answer={item.answer}
          key={item.id}
          onToggle={() => handleToggle(index)}
          isActive={clicked === index}
        />
      ))}
    </Root>
  );
};

const Root = styled.ul`
  width: 100%;
`;

const StyledAccordionItem = styled(AccordionItem)`
  margin-bottom: 30px;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

export default Accordion;
