import React, { FC } from 'react';
import { CustomLink } from '@/components';
import { BUTTON_THEME } from '@/constants';
import { ButtonSizes, ButtonThemes } from '@/types';
import styled from 'styled-components';

interface UiLinkProps {
  text?: string;
  link: string;
  className?: string;
  theme?: ButtonThemes;
  size?: ButtonSizes;
}

const UiLink: FC<UiLinkProps> = ({
  text,
  link,
  theme = 'primary',
  className,
  size = 'small',
  children,
}) => {
  return (
    <Root className={className} to={link} $theme={theme} $size={size}>
      {text || children}
    </Root>
  );
};

const Root = styled(CustomLink)<{ $theme: ButtonThemes; $size: ButtonSizes }>`
  ${({ $theme, $size }) => BUTTON_THEME[$theme][$size]};
  text-align: center;
`;

export default UiLink;
