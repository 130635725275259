import * as React from 'react';
import { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={109}
    height={19}
    viewBox="0 0 109 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.517 18V.545h6.989c1.284 0 2.355.19 3.213.571.858.381 1.503.91 1.934 1.586.432.67.648 1.443.648 2.318 0 .682-.136 1.281-.409 1.798a3.53 3.53 0 0 1-1.125 1.262 4.61 4.61 0 0 1-1.62.69v.17a3.909 3.909 0 0 1 1.867.563c.585.346 1.06.832 1.424 1.457.363.62.545 1.358.545 2.216 0 .926-.23 1.753-.69 2.48-.455.722-1.128 1.293-2.02 1.713-.892.42-1.992.631-3.299.631H.517Zm3.69-3.017h3.009c1.028 0 1.778-.196 2.25-.588.472-.398.707-.926.707-1.585 0-.483-.116-.91-.35-1.279a2.374 2.374 0 0 0-.996-.87c-.426-.21-.935-.314-1.526-.314H4.207v4.636Zm0-7.134h2.736c.506 0 .955-.088 1.347-.264a2.24 2.24 0 0 0 .937-.767c.233-.33.35-.724.35-1.184 0-.631-.225-1.14-.674-1.526-.443-.386-1.073-.58-1.892-.58H4.207V7.85Zm14.385 1.083h3.912c1.732 0 3.085.415 4.056 1.244.972.824 1.458 1.926 1.458 3.307 0 .898-.219 1.688-.657 2.37-.437.675-1.068 1.204-1.892 1.585-.823.375-1.812.562-2.965.562h-6.188V4.91h3.469v10.235h2.719c.607 0 1.107-.153 1.5-.46.392-.307.59-.7.596-1.177a1.542 1.542 0 0 0-.596-1.235c-.393-.324-.893-.486-1.5-.486h-3.912V8.932ZM29.424 18V4.91h3.63V18h-3.63Z"
      fill="#36C186"
    />
    <path
      d="M35.957 18V4.91h11.446V18h-3.47V7.764h-4.55V18h-3.426Zm13.576 0-.017-2.88h.435c.307 0 .571-.074.793-.222.227-.154.414-.4.562-.742.148-.34.264-.792.35-1.355.085-.568.142-1.267.17-2.097l.213-5.795h9.955V18h-3.46V7.764h-3.23l-.205 3.997c-.051 1.097-.185 2.04-.4 2.83-.211.79-.504 1.437-.879 1.943-.375.5-.826.87-1.355 1.108-.528.239-1.142.358-1.84.358h-1.092Zm19.008.247c-.835 0-1.58-.145-2.233-.434a3.595 3.595 0 0 1-1.55-1.304c-.376-.58-.563-1.302-.563-2.165 0-.727.133-1.338.4-1.833.267-.494.63-.892 1.091-1.193.46-.301.983-.528 1.568-.682.591-.153 1.21-.261 1.858-.323.762-.08 1.375-.154 1.841-.222.466-.074.804-.182 1.014-.324.21-.142.316-.352.316-.63v-.052c0-.54-.17-.957-.512-1.253-.335-.295-.812-.443-1.431-.443-.654 0-1.174.145-1.56.435a2 2 0 0 0-.767 1.074l-3.358-.273a4.563 4.563 0 0 1 1.005-2.063c.5-.585 1.145-1.034 1.935-1.346.796-.318 1.716-.477 2.761-.477.728 0 1.424.085 2.089.255.67.17 1.264.435 1.78.793.524.358.936.818 1.237 1.38.3.557.451 1.225.451 2.003V18H72.47v-1.815h-.102c-.21.409-.492.77-.844 1.082a3.904 3.904 0 0 1-1.27.724c-.494.17-1.065.256-1.713.256Zm1.04-2.505c.534 0 1.006-.106 1.415-.316.409-.216.73-.505.963-.87.233-.363.35-.775.35-1.235v-1.39c-.114.075-.27.143-.47.205-.193.057-.411.111-.656.162-.244.046-.488.088-.733.128l-.665.094a4.49 4.49 0 0 0-1.116.298 1.785 1.785 0 0 0-.741.554c-.177.227-.265.511-.265.852 0 .495.18.873.537 1.134.364.256.824.383 1.381.383Zm7.834-7.978V4.91H89.28v2.855H85.06V18H81.6V7.764h-4.184Zm16.34 1.168h3.913c1.733 0 3.085.415 4.056 1.244.972.824 1.458 1.926 1.458 3.307 0 .898-.219 1.688-.656 2.37-.438.675-1.069 1.204-1.892 1.585-.824.375-1.813.562-2.966.562H91.48V4.91h3.469v10.235h2.719c.608 0 1.108-.153 1.5-.46.392-.307.59-.7.596-1.177a1.542 1.542 0 0 0-.596-1.235c-.392-.324-.892-.486-1.5-.486h-3.912V8.932ZM104.589 18V4.91h3.631V18h-3.631Z"
      fill="#4F98CA"
    />
  </svg>
);

export default Logo;
