import { COLORS } from '@/constants';
import {
  addContractThunk,
  updateContractThunk,
} from '@/store/ducks/auth/thunks';
import { useAppDispatch } from '@/store/hooks';
import { Plus } from '@/ui/iconComponents';
import { uploadFile } from '@/utils/uploadFile';
import React, { FC, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

interface AddFilebuttonProps {
  className?: string;
  fileName?: string;
  idFile?: string;
  isOnlyPdf?: boolean;
}

const AddFilebutton: FC<AddFilebuttonProps> = ({
  className,
  fileName,
  idFile,
  isOnlyPdf = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const handleFileUpload = (event) => {
    const file: File = event.target.files[0];

    uploadFile(file, false, isOnlyPdf).then((data) => {
      if (idFile) {
        const updateContract = dispatch(
          updateContractThunk({
            id: idFile,
            pdfUrl: data,
            title: fileName || file.name,
            sum: '0',
          })
        );

        toast.promise(updateContract, {
          pending: 'Файл загружается',
          success: 'Файл загружен (даннные обновятся в течение 24 часов)',
          error: 'Произошла ошибка при загрузке файла',
        });
      } else {
        const addContract = dispatch(
          addContractThunk({
            pdfUrl: data,
            title: fileName || file.name,
            sum: '0',
          })
        );

        toast.promise(addContract, {
          pending: 'Файл загружается',
          success: 'Файл загружен (даннные обновятся в течение 24 часов)',
          error: 'Произошла ошибка при загрузке файла',
        });
      }
    });
  };

  return (
    <Root className={className}>
      {isOnlyPdf ? (
        <InputUpload
          ref={inputRef}
          onChange={handleFileUpload}
          type="file"
          accept="application/pdf"
        />
      ) : (
        <InputUpload ref={inputRef} onChange={handleFileUpload} type="file" />
      )}

      <Button onClick={() => inputRef.current.click()}>
        <Plus color={COLORS.primaryDark} />
      </Button>
    </Root>
  );
};

const Root = styled.div``;

const Button = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: ${COLORS.grayLighter};
`;

const InputUpload = styled.input.attrs({
  className: 'visually-hidden',
})``;

export default AddFilebutton;
