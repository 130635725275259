import { getUrl } from './getUrl';
import { toast } from 'react-toastify';
import { http } from '@/services/http';

export async function uploadFile(
  file: File,
  isImage: boolean = false,
  isOnlyPdf: boolean = false
) {
  const { size, type, name } = file;

  if (isImage) {
    if (type !== 'image/jpeg' && type !== 'image/png') {
      toast.error('Можно загружать только jpeg или png');
    }
  }
  if (isOnlyPdf) {
    if (file.type !== 'application/pdf') {
      toast.error('Можно загружать только pdf');
    }
  }

  if (size > 15000000) {
    toast.error('Размер файла слишком большой');
  }

  const signRes = await http.post('/storage/sign-url', {
    contentType: type,
    fileKey: name,
  });

  await http.put(signRes.data, file, {
    headers: {
      'Content-Type': type,
      'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
      'Access-Control-Allow-Origin': '*',
    },
  });

  return getUrl(signRes.data);
}
