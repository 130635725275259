import {
  COLORS,
  LINKS,
  SECTION_IDS,
  SOCIAL_LINKS,
  TYPOGRAPHY,
} from '@/constants';
import { Arrow, Logo, Whatsapp } from '@/ui/iconComponents';
import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import { CustomLink } from '../CustomLink';
import { UiLink } from '@/ui/components';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { actions, selectors } from '@/store/ducks';
import { UserLink } from './components';
import Headroom from 'react-headroom';
import { browserDetect } from '@/utils/browserDetect';

export const menuTexts = [
  {
    text: 'O проекте',
    to: `/#${SECTION_IDS.about}`,
  },
  {
    text: 'Для кого',
    to: `/#${SECTION_IDS.forWhom}`,
  },
  {
    text: 'Шаги',
    to: `/#${SECTION_IDS.steps}`,
  },
  {
    text: 'Услуги',
    to: `/#${SECTION_IDS.services}`,
  },
  {
    text: 'Кейсы',
    to: `/#${SECTION_IDS.cases}`,
  },
];

const personalAreaLinks = [
  {
    text: 'Мой профиль',
    to: `${LINKS.profile}?tab=data`,
  },
  {
    text: 'Мои накопления',
    to: `${LINKS.profile}?tab=accumulation`,
  },
  {
    text: 'Пароль',
    to: `${LINKS.profile}?tab=password`,
  },
];

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectors.auth.selectUserToken);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const headerRef = useRef(null);
  const [isOpenPersonalArea, setIsOpenPersonalArea] = useState(false);

  useOnClickOutside(headerRef, () => {
    const body = document.querySelector('body');
    body.style.overflow = '';
    setIsOpenMenu(false);
  });

  const setOverflowForBody = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const isSafari = browserDetect() === 'safari';

    body.style.overflow = !isOpenMenu ? 'hidden' : '';

    if (isSafari) {
      html.classList.toggle('no-scroll');
      body.classList.toggle('no-scroll');
    }
  };

  const hangleClickMenuLink = () => {
    if (window.innerWidth < 1100) {
      setOverflowForBody();
      setIsOpenMenu(false);
    }
  };

  const handleClickBurger = () => {
    setOverflowForBody();
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <Headroom disableInlineStyles={true} style={{ zIndex: 100 }}>
      <Root ref={headerRef}>
        <StyledContainer>
          <StyledLink to={LINKS.index}>
            <StyledLogo />
          </StyledLink>
          <Burger
            $isOpenMenu={isOpenMenu}
            onClick={handleClickBurger}
            className={isOpenMenu && 'active'}
          >
            <span />
          </Burger>
          <ScrollWrapper $isOpenMenu={isOpenMenu}>
            <Wrapper $hasPersonalArea={Boolean(token)}>
              <MenuNav>
                <Menu>
                  {menuTexts.map((item, index) => {
                    return (
                      <MenuItem key={index}>
                        <MenuLink
                          to={item.to}
                          onClick={() => hangleClickMenuLink()}
                        >
                          {item.text}
                        </MenuLink>
                      </MenuItem>
                    );
                  })}
                  {!token && (
                    <PersonalAreaMenuLinkMobile
                      to={LINKS.singIn}
                      onClick={() => hangleClickMenuLink()}
                    >
                      Личный кабинет
                    </PersonalAreaMenuLinkMobile>
                  )}
                </Menu>
                {token && (
                  <PersonalAreaMenuWrap>
                    <PersonalAreaMenuOpenButton
                      onClick={() => setIsOpenPersonalArea(!isOpenPersonalArea)}
                    >
                      Личный кабинет
                      <PersonalAreaMenuOpenButtonArrow
                        $isActive={isOpenPersonalArea}
                      />
                    </PersonalAreaMenuOpenButton>
                    {isOpenPersonalArea && (
                      <PersonalAreaMenu>
                        {personalAreaLinks.map((item, index) => {
                          return (
                            <PersonalAreaMenuLink
                              key={index}
                              to={item.to}
                              onClick={() => hangleClickMenuLink()}
                            >
                              {item.text}
                            </PersonalAreaMenuLink>
                          );
                        })}
                        <LogoutButton
                          onClick={() => {
                            hangleClickMenuLink();
                            dispatch(actions.auth.logOut());
                          }}
                        >
                          Выход
                        </LogoutButton>
                      </PersonalAreaMenu>
                    )}
                  </PersonalAreaMenuWrap>
                )}
              </MenuNav>
              <LinksWrapper>
                {token ? (
                  <StyledUserLink />
                ) : (
                  <>
                    <ButtonLink
                      link={LINKS.calculator}
                      text="Рассчитать накопления"
                    />
                    <PersonalAreaButtonLink
                      link={LINKS.singIn}
                      text="Личный кабинет"
                      theme="secondary"
                    />
                  </>
                )}
              </LinksWrapper>
              <LinkToWhatsapp to={SOCIAL_LINKS.whatsapp}>
                <Whatsapp />
              </LinkToWhatsapp>
            </Wrapper>
          </ScrollWrapper>
        </StyledContainer>
      </Root>
    </Headroom>
  );
};

const Root = styled.header`
  background-color: ${COLORS.white};
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 1100px) {
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

const StyledLink = styled(CustomLink)`
  margin-right: 83px;
  @media (max-width: 1300px) {
    margin-right: 40px;
  }
`;

const StyledLogo = styled(Logo)``;

const ScrollWrapper = styled.div<{ $isOpenMenu: boolean }>`
  flex-grow: 1;
  @media (max-width: 1100px) {
    position: fixed;
    width: 375px;
    top: 79px;
    bottom: 0;
    right: 0;
    background-color: ${COLORS.grayLight};
    transform: translate(${({ $isOpenMenu }) => ($isOpenMenu ? '0' : '100%')});
    transition: transform 0.3s ease-in;
    overflow: scroll;
    z-index: 10;
  }
  @media (max-width: 600px) {
    width: 100%;
    top: 47px;
  }
`;

const Wrapper = styled.div<{ $hasPersonalArea: boolean }>`
  display: flex;
  align-items: center;
  @media (max-width: 1100px) {
    padding: 32px 16px 48px;
    padding-top: ${({ $hasPersonalArea }) => $hasPersonalArea && '92px'};
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

const MenuNav = styled.nav`
  margin-right: 32px;
  @media (max-width: 1100px) {
    margin-right: 0;
    margin-bottom: 32px;
    width: 100%;
  }
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const MenuItem = styled.li`
  margin-right: 32px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 1300px) {
    margin-right: 20px;
  }
  @media (max-width: 1100px) {
    margin-right: 0;
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const MenuLink = styled(CustomLink)`
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.black};
  cursor: pointer;
  transition: color 0.3s ease-in;
  :hover,
  :focus {
    color: ${COLORS.primaryDefault};
  }
`;

const PersonalAreaMenuLinkMobile = styled(MenuLink)`
  display: none;
  @media (max-width: 1100px) {
    display: block;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  @media (max-width: 1100px) {
    flex-grow: 0;
    flex-direction: column;
    width: 100%;
  }
`;

const ButtonLink = styled(UiLink)`
  margin-right: 12px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 1100px) {
    margin-right: 0;
    margin-bottom: 12px;
    max-width: 100%;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const PersonalAreaButtonLink = styled(ButtonLink)`
  @media (max-width: 1100px) {
    display: none;
  }
`;

const Burger = styled.button<{ $isOpenMenu: boolean }>`
  display: none;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 17px;
  cursor: pointer;
  position: relative;
  z-index: 1;

  span,
  & span::before,
  & span::after {
    position: absolute;
    width: 25px;
    height: 1.5px;
    background-color: ${COLORS.black};
    transition: all 0.3s ease;
  }
  & span::before {
    content: '';
    left: 0;
    top: -8px;
  }
  & span::after {
    content: '';
    left: 0;
    top: 8px;
  }

  &.active {
    span,
    span::before,
    span::after {
    }

    span {
      transform: rotate(45deg);
      &::before {
        top: 0;
        transform: rotate(0);
      }
      &::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
  }

  @media (max-width: 1100px) {
    display: flex;
    padding: 11px;
    box-sizing: content-box;
  }
`;

const PersonalAreaMenuWrap = styled.div`
  margin-top: 24px;
  width: 100%;
  display: none;
  @media (max-width: 1100px) {
    display: block;
  }
`;

const PersonalAreaMenuOpenButton = styled.p`
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.black};
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PersonalAreaMenuOpenButtonArrow = styled(Arrow)<{ $isActive: boolean }>`
  transform: ${({ $isActive }) =>
    $isActive ? 'rotate(-90deg)' : 'rotate(90deg)'};
`;

const PersonalAreaMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  width: calc(100% + 32px);
  padding: 16px;
  margin-left: -16px;
  margin-right: -16px;
`;

const PersonalAreaMenuLink = styled(MenuLink)`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0;
  }
`;

const LogoutButton = styled.button`
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.black};
  text-align: left;
`;

const StyledUserLink = styled(UserLink)`
  @media (max-width: 1100px) {
    position: absolute;
    top: 24px;
    left: 16px;
  }
`;

const LinkToWhatsapp = styled(CustomLink)`
  display: none;
  @media (max-width: 600px) {
    display: block;
    position: absolute;
    top: 32px;
    right: 16px;
  }
`;

export default Header;
