import React, { FC } from 'react';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { selectors } from '@/store/ducks';
import { LINKS } from '@/constants';
import { useAppSelector } from '@/store/hooks';

interface PrivateRouteProps extends RouteComponentProps {
  component: React.ComponentType;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const token = useAppSelector(selectors.auth.selectUserToken);
  if (!token) {
    navigate(LINKS.singIn);
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
