import * as React from 'react';
import { SVGProps } from 'react';

const MirPayment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44 26c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h40c1.1 0 2 .9 2 2v24Z"
      fill="#fff"
    />
    <path
      d="M13.856 9v.004c-.005 0-1.38-.004-1.747 1.312-.335 1.204-1.284 4.532-1.31 4.624h-.263s-.97-3.401-1.31-4.629C8.86 8.996 7.48 9 7.48 9H4.336v10H7.48v-5.938h.262L9.577 19h2.184l1.833-5.934h.263V19H17V9h-3.144ZM25.339 9s-.92.082-1.354 1.049l-2.226 4.891h-.263V9h-3.144v10h2.969s.965-.088 1.398-1.047l2.183-4.892h.262V19h3.145V9h-2.97ZM29.703 13.541V19h3.145v-3.188h3.406a3.403 3.403 0 0 0 3.21-2.271h-9.76Z"
      fill="#4DB45E"
    />
    <path
      d="M36.258 9h-6.992a5.02 5.02 0 0 0 4.939 4.106h5.389a3.3 3.3 0 0 0 .07-.698A3.406 3.406 0 0 0 36.258 9Z"
      fill="url(#MirPayment-a-linear)"
    />
    <defs>
      <linearGradient
        id="MirPayment-a-linear"
        x1={29.265}
        y1={11.053}
        x2={39.664}
        y2={11.053}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.3} stopColor="#00B4E6" />
        <stop offset={1} stopColor="#088CCB" />
      </linearGradient>
    </defs>
  </svg>
);

export default MirPayment;
