import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC, forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import checkIcon from '@/assets/images/svg/check.svg';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, label, type = 'checkbox', ...rest }, ref) => {
    const id = uuidv4();
    return (
      <Root className={className}>
        <Input id={id} type={type} {...rest} ref={ref} />
        <Label htmlFor={id}>{label}</Label>
      </Root>
    );
  }
);

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input.attrs({
  className: 'visually-hidden',
})`
  :focus {
    & + label::before {
      box-shadow: 0 0 0 8px ${COLORS.grayLighter};
    }
  }
  :disabled {
    & + label {
      opacity: 0.4;
      ::before {
        border-color: ${COLORS.gray1};
        background-color: ${COLORS.grayLight};
      }
    }
  }

  :checked {
    & + label {
      ::before {
        border-color: ${COLORS.primaryDefault};
        background-color: ${COLORS.primaryDefault};
      }
      ::after {
        opacity: 1;
      }
    }
    &:focus + label::before {
      box-shadow: 0 0 0 8px rgb(232, 241, 255, 0.8);
    }
    &:disabled + label {
      opacity: 0.4;
    }
  }
`;

const Label = styled.label`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.black};
  padding-left: 36px;
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    border: 1px solid ${COLORS.gray1};
    background-color: ${COLORS.grayLight};
    border-radius: 50%;
    transition: all 0.3s ease-in;
  }

  ::after {
    content: '';
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 10px;
    background: url(${checkIcon}) no-repeat center;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  :hover {
    ::before {
      background-color: ${COLORS.gray};
      border-color: ${COLORS.gray};
    }
  }
`;

export default Checkbox;
