import React, { FC } from 'react';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { selectors } from '@/store/ducks';

import { useAppSelector } from '@/store/hooks';

interface PrivateAdminRouteProps extends RouteComponentProps {
  component: React.ComponentType;
}

const PrivateAdminRoute: FC<PrivateAdminRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const token = useAppSelector(selectors.admin.selectAdminToken);

  if (!token) {
    navigate('/admin/login');
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateAdminRoute;
