import { COLORS, TYPOGRAPHY } from '@/constants';
import { Arrow } from '@/ui/iconComponents/Arrow';
import React, { FC, useRef } from 'react';
import styled from 'styled-components';

interface AccordionItemProps {
  question: string;
  answer: string;
  className?: string;
  onToggle: () => void;
  isActive: boolean;
}

const AccordionItem: FC<AccordionItemProps> = ({
  question,
  answer,
  className,
  onToggle,
  isActive,
}) => {
  const contentRef = useRef<HTMLDivElement>();
  const answerWrapperStyle = isActive
    ? { maxHeight: contentRef.current.scrollHeight }
    : { maxHeight: '0px' };

  return (
    <Root className={`${className} ${isActive ? 'active' : ''}`}>
      <Wrapper>
        <div>
          <Button onClick={onToggle}>
            {question}
            <ArrowWrapper>
              <StyledArrow color={COLORS.white} />
            </ArrowWrapper>
          </Button>
        </div>
        <AnswerWrapper ref={contentRef} style={answerWrapperStyle}>
          <Answer>{answer}</Answer>
        </AnswerWrapper>
      </Wrapper>
    </Root>
  );
};

const Root = styled.li`
  background-color: ${COLORS.grayLighter};
  border-radius: 20px;
`;

const Wrapper = styled.div`
  padding: 20px;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const ArrowWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 40px;
  height: 40px;
  background-color: ${COLORS.primaryDefault};
  transition: background-color 0.3s ease;
  border-radius: 8px;
  margin-left: 12px;
`;

const StyledArrow = styled(Arrow)`
  transform: rotate(90deg);
  transition: transform 0.3s ease;
  .active & {
    transform: rotate(270deg);
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${TYPOGRAPHY.headline2SemiBold22};
  color: ${COLORS.black};
  margin-right: 20px;
  width: 100%;
  cursor: pointer;
  text-align: left;
`;

const AnswerWrapper = styled.div`
  transition: max-height 0.3s;
  padding-right: 45px;
  max-height: 0;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding-right: 0;
  }
`;

const Answer = styled.div`
  padding-top: 20px;
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black};

  a {
    text-decoration: underline;
  }
`;

export default AccordionItem;
