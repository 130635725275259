import * as React from 'react';
import { SVGProps } from 'react';

const VisaPayment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44 26c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h40c1.1 0 2 .9 2 2v24Z"
      fill="#fff"
    />
    <path
      d="m17.58 9.174-1.573 9.66h2.515l1.572-9.66H17.58Zm-3.69 0-2.397 6.645-.283-1.43-.063-.321a8 8 0 0 0-2.231-2.829 9.651 9.651 0 0 0-1.12-.774l2.18 8.37h2.62l4.003-9.66H13.89v-.001Zm9.816 2.681c0-1.094 2.449-.952 3.527-.357l.357-2.076S26.483 9 25.328 9c-1.248 0-4.212.546-4.212 3.199 0 2.496 3.48 2.525 3.48 3.836 0 1.313-3.121 1.078-4.151.25l-.374 2.17S21.194 19 22.91 19s4.305-.889 4.305-3.307c0-2.511-3.51-2.746-3.51-3.838ZM33.97 9.174h-2.023c-.934 0-1.161.721-1.161.721l-3.75 8.939h2.62l.526-1.436h3.195l.297 1.436h2.309l-2.013-9.66Zm-3.064 6.242 1.32-3.614.745 3.614h-2.065Z"
      fill="#005BAC"
    />
    <path
      d="M10.395 10.084s-.104-.873-1.216-.873H5.14l-.047.164s1.94.396 3.803 1.879c1.779 1.416 2.359 3.183 2.359 3.183l-.861-4.353Z"
      fill="#F6AC1D"
    />
  </svg>
);

export default VisaPayment;
