import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import styled from 'styled-components';

interface SectionTitleProps {
  className?: string;
}

const SectionTitle: FC<SectionTitleProps> = ({ className, children }) => {
  return <Root className={className}>{children}</Root>;
};

const Root = styled.h2`
  ${TYPOGRAPHY.title2SemiBold36};
  color: ${COLORS.black};
  letter-spacing: 0.01em;
  @media (max-width: 1024px) {
    letter-spacing: normal;
  }
`;

export default SectionTitle;
