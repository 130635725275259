import React, { FC, forwardRef, InputHTMLAttributes } from 'react';
import { Input } from '../Input';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@/constants';
import ReactInputMask, { Props } from 'react-input-mask';
import { Check, CloseSquare } from '@/ui/iconComponents';

interface InputWithLabelProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Omit<Props, 'inputRef' | 'mask'> {
  label?: string;
  name?: string;
  className?: string;
  errorMessage?: string;
  isSuccess?: boolean;
  isWithoutIcons?: boolean;
}

const InputWithLabel: FC<InputWithLabelProps> = forwardRef<
  HTMLInputElement | ReactInputMask,
  InputWithLabelProps
>(
  (
    {
      className,
      name,
      label,
      errorMessage = '',
      isWithoutIcons = false,
      isSuccess,
      ...rest
    },
    ref
  ) => {
    const id = uuidv4();
    const hasError = errorMessage.length > 0 ? true : false;
    const errorClass = hasError ? 'error' : '';
    const successClass = isSuccess ? 'success' : '';

    return (
      <Root className={className} $hasError={hasError}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <InputWrapper $hasError={hasError} $isSuccess={isSuccess}>
          <StyledInput
            className={`${errorClass} ${successClass}`}
            id={id}
            name={name}
            {...rest}
            ref={ref}
          />
          {!isWithoutIcons && (
            <IconWrap>
              {hasError && <CloseSquare color={COLORS.additionalRed} />}
              {isSuccess && <Check color={COLORS.additionalGreen} />}
            </IconWrap>
          )}
        </InputWrapper>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Root>
    );
  }
);

const Root = styled.div<{ $hasError: boolean }>`
  position: relative;
`;

const Label = styled.label`
  display: inline-block;
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.black};
  margin-bottom: 6px;
`;

const InputWrapper = styled.div<{ $hasError: boolean; $isSuccess: boolean }>`
  position: relative;
`;

const StyledInput = styled(Input)`
  &.success {
    border-color: ${COLORS.additionalGreen};
  }
  &.error {
    border-color: ${COLORS.additionalRed};
  }
`;

const ErrorMessage = styled.p`
  ${TYPOGRAPHY.caption2Regular10};
  color: ${COLORS.additionalRed};
  position: absolute;
  bottom: -24px;
  left: 0;
`;

const IconWrap = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

export default InputWithLabel;
